import { Col, Row } from 'antd';
import Ceren from 'assets/images/auth/ceren.png';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { NiceButton } from 'components/commons/nice-button/nice-button';

interface ISuccessModalProps {
  gotoDashboard: () => void;
}

export const SuccessModal = NiceModal.create<ISuccessModalProps>(({ gotoDashboard }) => {
  const modal = useModal();
  return (
    <NiceAntModal
      modal={modal}
      width={800}
      afterClose={gotoDashboard}
      closable={false}
      maskClosable={false}
      bodyStyle={{ padding: '75px 36px' }}
    >
      <Row gutter={[36, 36]}>
        <Col span={24} md={8}>
          <img src={Ceren} className="w-full" alt="" />
        </Col>
        <Col span={24} md={16}>
          <div className="text-3xl font-bold mb-6 text-darkBlueColor">Thank you and congrats!</div>
          <p className="text-sm mb-6" style={{ color: '#333' }}>
            <b>You can now access your account and get to grips with the platform</b>
            <br />
            <br />
            We’ve sent you an email so please check your inbox and spam folders to <b>verify your email address</b>
            .
            <br />
            <br />
            We’re verifying your Dental License number but you can sample our educational content and have a look
            around. To personalise your experience my colleague Prof. Ama has a couple of quick questions.
          </p>
        </Col>
      </Row>
      <div className="text-right mt-4">
        <NiceButton onClick={modal.hide} niceType="orange">
          Get Started
        </NiceButton>
      </div>
    </NiceAntModal>
  );
});

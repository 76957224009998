import { useEffect } from 'react';
import { useUpdateLastActiveHubspotMutation } from 'services/dashboard-api/endpoints';

export const useLastActive = () => {
  const [updateLastActiveHubspot] = useUpdateLastActiveHubspotMutation();

  useEffect(() => {
    updateLastActiveHubspot({}).unwrap();
    const intervalId = setInterval(() => {
      updateLastActiveHubspot({}).unwrap();
    }, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);
};

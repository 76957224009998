import { FC, MutableRefObject, ReactNode, useRef, useState } from 'react';
import { ITreatmentProposalForm } from '../../types/ITreatmentProposalForm';
import { Button, Form, Input, Radio, Space, Typography, notification, Tooltip } from 'antd';
import { DentistInfoComponent } from '../dentist-info/dentist-info.component';
import cn from 'classnames';
import { AssessmentComponent } from '../assessment/assessment.component';
import { SmilePreviewComponent } from '../smile-preview/smile-preview.component';
import { TreatmentQuoteTableComponent } from '../treatment-quote-table/treatment-quote-table.component';
import styles from './preview-proposal.module.less';
import { BigTypographyTitle, PreviewProposalMoreInfoComponent } from './preview-proposal-more-info.component';
import reportPng from 'assets/images/patient-information/report.png';
import { isMobile } from 'react-device-detect';

interface IPreviewProposalComponentProps {
  proposalData: ITreatmentProposalForm;
  onSubmit?: (data: { message: string; quote: string }) => void;
  hideBookButton?: boolean;
  showSubmitButtonLoader?: boolean;
  disableInput?: boolean;
  disableQuoteSelection?: boolean;
  isFullSizePage?: boolean;
}

export const PreviewProposalComponent: FC<IPreviewProposalComponentProps> = ({
  proposalData,
  onSubmit = () => {},
  hideBookButton = false,
  showSubmitButtonLoader = false,
  disableInput = false,
  disableQuoteSelection = false,
  isFullSizePage = false,
}) => {
  const [form] = Form.useForm();
  const [isQuoteErr, setIsQuoteError] = useState(false);
  const replyFormRef = useRef<HTMLDivElement>(null);
  const scrollToRef = useRef<HTMLDivElement>(null);

  const onFinishFailed = () => {
    notification.warn({
      message: 'Alert!',
      description: 'Please select a Treatment Quote.',
      placement: 'topRight',
    });
    window.scrollTo(0, 0);
    setIsQuoteError(true);
  };

  const onTopReplyBtnClick = () => {
    replyFormRef.current!.focus({
      preventScroll: true,
    });

    // add settimeout to prevent browser from blocking the scroll
    setTimeout(() => {
      scrollToRef.current!.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }, 200);
  };

  const renderReplyForm = (
    formRef?: MutableRefObject<HTMLDivElement | null>,
    targetScrollToRef?: MutableRefObject<HTMLDivElement | null>,
  ): ReactNode => (
    <>
      <div ref={targetScrollToRef} />
      <Typography.Title
        level={4}
        className={cn('pb-2', styles.imInterestedText)}
        style={{ fontSize: 16, fontWeight: 600, color: '#24165D' }}
      >
        Reply to your dentist
      </Typography.Title>
      <Typography.Text className={cn(styles.addNoteText)}>Add a note for your dentist</Typography.Text>

      <div className="mt-2" />

      <div className="grid grid-cols-12 gap-4">
        <div className={cn('col-span-12', { 'md:col-span-5': isFullSizePage })}>
          <Form.Item name={['message']}>
            <Input.TextArea
              ref={formRef}
              autoSize={{
                minRows: !isMobile || isFullSizePage ? 4 : 1,
                maxRows: 6,
              }}
              className={styles.messageInput}
              size="large"
              disabled={disableInput}
            />
          </Form.Item>
          <div className="mt-2" />
          <div className="text-xs" style={{ color: '#9B9B9B' }}>
            Your message will be sent directly to your dentist and they will contact you to discuss next steps
          </div>
          <Form.Item shouldUpdate>
            {() => (
              <Tooltip
                placement="topLeft"
                overlay={!form.getFieldValue('message') ? 'Please add a note for your dentist' : ''}
              >
                <div>
                  <Button
                    shape="round"
                    size="large"
                    className="gradient-button mt-3"
                    onClick={() => form.submit()}
                    loading={showSubmitButtonLoader}
                    disabled={showSubmitButtonLoader || !form.getFieldValue('message')}
                    style={{ width: 150 }}
                  >
                    Reply
                  </Button>
                </div>
              </Tooltip>
            )}
          </Form.Item>
        </div>
      </div>
    </>
  );

  return (
    <Form form={form} component={false} onFinish={onSubmit} scrollToFirstError onFinishFailed={onFinishFailed}>
      <div className={styles.previewProposal}>
        {isFullSizePage && (
          <div className="flex items-center mt-4 mb-4 md:mt-7 md:mb-6">
            <img
              src={reportPng}
              alt="reportPng"
              style={{
                height: '60px',
                filter: 'drop-shadow(0px 7px 4px #dddddd)',
              }}
            />
            <div className="pl-3 text-base font-semibold md:text-lg">Patient Report</div>
          </div>
        )}

        <DentistInfoComponent
          dentist={proposalData.dentist}
          replyToDentistBtnSlot={
            !hideBookButton && (
              <Button className="gradient-button" size="large" style={{ width: 150 }} onClick={onTopReplyBtnClick}>
                Reply
              </Button>
            )
          }
        />

        <div className="mt-4" />

        <BigTypographyTitle isFullSizePage={isFullSizePage}>Patient Info</BigTypographyTitle>

        <Typography.Title level={5}>Name</Typography.Title>
        <Typography.Text>{proposalData.patientName}</Typography.Text>

        <Typography.Title level={5}>Email</Typography.Title>
        <Typography.Text>{proposalData.email}</Typography.Text>

        <Typography.Title level={5}>Mobile</Typography.Title>
        <Typography.Text>
          {proposalData.mobile?.ext} {proposalData.mobile?.number}
        </Typography.Text>

        <Typography.Title level={5}>Number of aligner stages</Typography.Title>
        <Typography.Text>{proposalData.numberofAligner}</Typography.Text>

        <Typography.Title level={5}>Estimated duration</Typography.Title>
        <Typography.Text>{proposalData.duration ? proposalData.duration : '-'}</Typography.Text>

        <div className="mt-4" />

        <BigTypographyTitle isFullSizePage={isFullSizePage}>Your Assessment</BigTypographyTitle>

        <div className="grid grid-cols-12 gap-4">
          {proposalData.assessmentImages.map((assessment) => (
            <div className={cn('col-span-6', { 'md:col-span-4 lg:col-span-3': isFullSizePage })} key={assessment.url}>
              <AssessmentComponent assessment={assessment} isFullSizePage={isFullSizePage} />
            </div>
          ))}
        </div>

        <div className="mt-4" />

        <BigTypographyTitle isFullSizePage={isFullSizePage}>Your Treatment Goals</BigTypographyTitle>

        <Typography.Text>{proposalData.treatmentGoals ? proposalData.treatmentGoals : '-'}</Typography.Text>

        <div className="mt-4" />

        <BigTypographyTitle isFullSizePage={isFullSizePage}>Your Smile Preview</BigTypographyTitle>

        <SmilePreviewComponent url={proposalData.toothMovementURL} isFullSizePage={isFullSizePage} />

        {proposalData.toothMovementURL && (
          <Typography.Text type="secondary" className="text-center block mt-2">
            *This tooth movement simulation is a guide for the programmed tooth movements. It is not a guarantee of the
            final position of the teeth.
          </Typography.Text>
        )}

        <div className="mt-4" />

        <Form.Item name="quote" label="" rules={[{ required: proposalData.quotes.length > 1, message: '' }]}>
          <Radio.Group
            style={{ width: '100%' }}
            onChange={() => setIsQuoteError(false)}
            defaultValue={proposalData.selectedQuotation}
            disabled={hideBookButton}
          >
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              {proposalData.quotes.map((treatmentQuote, index) => (
                <div key={treatmentQuote.id}>
                  <BigTypographyTitle isFullSizePage={isFullSizePage}>{treatmentQuote.title}</BigTypographyTitle>

                  {!disableQuoteSelection &&
                    (proposalData.quotes.length > 1 ? (
                      <Radio name="quote" value={treatmentQuote.id}>
                        <span
                          style={{
                            fontSize: 15,
                            paddingBottom: 10,
                            fontWeight: 600,
                          }}
                        >
                          Select this quote
                        </span>
                      </Radio>
                    ) : (
                      <Radio defaultChecked>
                        <span
                          style={{
                            fontSize: 15,
                            paddingBottom: 10,
                            fontWeight: 600,
                          }}
                        >
                          Select this quote
                        </span>
                      </Radio>
                    ))}
                  <div className="mt-2" />
                  <TreatmentQuoteTableComponent data={treatmentQuote.info} />
                  {isQuoteErr && index === 0 && (
                    <div className="text-sm mt-3 ml-3 ant-form-item-explain-error">Please pick an item!</div>
                  )}
                  <div className="mt-4" />
                </div>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>

        {!hideBookButton && (
          <>
            <div className="mt-4" />
            {renderReplyForm(replyFormRef, scrollToRef)}
          </>
        )}

        <div className="mt-4" />

        <PreviewProposalMoreInfoComponent isFullSizePage={isFullSizePage} />

        <div className="mt-8" />
        {!hideBookButton && renderReplyForm()}
        <div className="mt-6" />
      </div>
    </Form>
  );
};

import { ISubmission } from 'services/patients-api/types';
import moment from 'moment';
import { INiceStepType } from 'components/commons/nice-steps/nice-steps';
import { FormInstance } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { SUBMISSION_SERVICE_TYPE, SUBMISSION_STATUS_TYPE } from 'config/constants';
import { ISubmissionForm } from './types';
import { archesInTreatmentType } from 'modules/designer/submit-design/constants';

export const INITIAL_CASE_SUBMISSION_STEPS: INiceStepType[] = [
  {
    title: 'Patient Summary',
    percent: 0,
  },
  {
    title: 'Treatment Aims',
    percent: 0,
  },
  {
    title: 'Clinical Examination',
    percent: 0,
  },
  {
    title: 'Dentist’s Instructions',
    percent: 0,
  },
];

export const INITIAL_REFINEMENT_CASE_SUBMISSION_STEPS: INiceStepType[] = [
  {
    title: 'Refinement Info',
    percent: 0,
  },
];

export const VERTICAL_OPTIONS = [
  {
    value: 'Increased',
    label: 'Increased',
  },
  {
    value: 'Average',
    label: 'Average',
  },
  {
    value: 'Class III',
    label: 'Class III',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const PHASING_PREFERENCES = [
  {
    value: 'Fitting, IPR, attachments and auxiliaries at step 0 (or fitting) when possible',
    label: 'Fitting, IPR, attachments and auxiliaries at step 0 (or fitting) when possible',
  },
  {
    value: 'Staggered approach: Fitting to precede attachments, IPR and auxiliaries',
    label: 'Staggered approach: Fitting to precede attachments, IPR and auxiliaries',
  },
  {
    value: 'No preference',
    label: 'No preference',
  },
];

export enum SUBMISSION_STATUS {
  SUBMITTED = 'SUBMITTED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export enum SUBMISSION_PLAN_TYPE {
  INITIAL = 'INITIAL',
  REFINEMENT = 'REFINEMENT',
}

export const INITIAL_CASE_FORM = {
  extraOralImages: [],
  intraOralImages: [],
  radioGraphImgs: [],
  impressionType: undefined,
  impressionScannerType: undefined,
  imprImages: [],
  anonymisedInfo: false,
};

export const ONBOARDING_INITIAL_DATA = {
  patientDetail: {
    dob: moment('1988-06-08T19:09:54.068Z'),
    firstName: 'Guy',
    lastName: 'Allen',
    email: 'AllenG06@gmail.com',
  },
  clinic: '',
  serviceType: SUBMISSION_SERVICE_TYPE.DUO,
  planType: SUBMISSION_PLAN_TYPE.INITIAL,
  extraOralImages: [],
  intraOralImages: [],
  radioGraphImgs: [],
  impressionType: 'Silicone Impressions (via Post)',
  impressionScannerType: '',
  postageDate: null,
  imprImages: [],
  dentalAndMedicalFit: 'Yes',
  patientMotivationAndConcern: `  1. Doesn’t like the look of upper 3-3 especially the gaps he has between the top front teeth
  2. Midline slightly off when biting together.
  3. Lower crowding of teeth`,
  status: SUBMISSION_STATUS_TYPE.SUBMITTED,
  diagnosis: {
    arches: archesInTreatmentType.archDual,
    upperDentalCenterLineOfFace: 'coincident',
  },
  molarRelations: 'Class II',
  bruxer: false,
  widerTreatmentPlan: [],
  patientAcceptableInterventions: ['IPR', 'Attachments on any teeth', 'Elastics'],
  statedTimings: null,
  patientOccupation: 'Retail',
  otherModifyingFactors: 'Nil',
  dietaryFactors: 'Nil',
  priorExtensiveGeneralDentalTreatment: false,
  generalDentalTreatmentDetail: '',
  priorOrthodontics: false,
  priorOrthoDetails: '',
  priorFacialAesthetics: false,
  priorFacialAestheticsDetails: '',
  midlinesCoincident: '',
  midlineComments: '',
  relevantMedicalSocialAndDrugHistory: 'Nil',
  toothSurfaceLoss: 'Low',
  oralHealthPathology: 'Nil',
  oralHygiene: 'Fair',
  BPE: '111/121',
  gingivalBiotype: 'Thin',
  midlinesUpperDentalVSFacial: '',
  midlinesChinpoint: '',
  lipline: '',
  additionalOrthoComments: '',
  dentistPerspectiveOne: 'Ideally want to move the teeth into perfect alignment then carry out some bonding',
  dentistPerspectiveTwo: '',
  dentistPerspectiveThree: '',
  dentistPerspectiveFour: '',
  widerTreatmentPlanDetails: '',
  patientMotivationConcerns: '',
  comprehensiveCompromisedOutcomes: 'Will accept compromised',
  iprAttachmentsElastics: '',
  patientSuitability: true,
  crowdingInUpperArch: '5mm',
  crowdingInLowerArch: '5mm',
  upperDentalCenterLineOfFace: 'coincident',
  lowerDentalCenterLineOfFace: 'lower_to_left',
  overjetIncisorRelationship: 'Class II Division I',
  anonymisedInfo: false,
};

export const YESNO = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const LOWER_DENTAL_CENTER_LINE_OF_CHIN = [
  {
    value: 'coincident',
    label: 'Coincident',
  },
  {
    value: 'shifted_to_left',
    label: 'Shifted to left',
  },
  {
    value: 'shifted_to_right',
    label: 'Shifted to right',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const LOWER_DENTAL_CENTER_LINE_OF_FACE = [
  {
    value: 'coincident',
    label: 'Coincident',
  },
  {
    value: 'lower_to_left',
    label: 'Lower to the left of upper',
  },
  {
    value: 'lower_to_right',
    label: 'Lower to the right of upper',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const MOLAR_RELATIONS = [
  {
    value: 'Class I',
    label: 'Class I',
  },
  {
    value: 'Class II',
    label: 'Class II',
  },
  {
    value: 'Class III',
    label: 'Class III',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const OVERJET_INCISOR_RELATIONSHIP = [
  {
    value: 'Class I',
    label: 'Class I',
  },
  {
    value: 'Class II Division I',
    label: 'Class II Division I',
  },
  {
    value: 'Class II Division II',
    label: 'Class II Division II',
  },
  {
    value: 'Class III',
    label: 'Class III',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const OVERJET_OVERBITE_OR_ANTERIOR = [
  {
    value: 'overbite',
    label: 'Overbite',
  },
  {
    value: 'AOB',
    label: 'AOB',
  },
  {
    value: 'edge_to_edge',
    label: 'Edge to Edge',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const UPPER_DENTAL_CENTER_LINE_OF_FACE = [
  {
    value: 'coincident',
    label: 'Coincident',
  },
  {
    value: 'shifted_to_left',
    label: 'Shifted to left',
  },
  {
    value: 'shifted_to_right',
    label: 'Shifted to right',
  },
  {
    value: 'not_sure',
    label: 'Not Sure',
  },
];

export const SCANNER_TYPE_LABEL = {
  iTero: 'iTero',
  Trios: 'Trios',
  Primescan: 'Primescan',
  Medit: 'Medit',
  Carestream: 'Carestream',
  Other: 'Other',
};

export const IMPRESSION_TYPE_LABEL = {
  stlUpload: 'Digital Intra-Oral Scans (.STLs)',
  viaPost: 'Silicone Impressions (via Post)',
};

export const STL_UPLOAD_TYPE_LABEL = {
  sendViaScanner: 'Send via scanner',
  uploadFile: 'Upload file',
};

export const checkFieldsError = (form: FormInstance, fields?: NamePath[]) =>
  form.getFieldsError(fields).some((error) => error.errors.length);

export const getDuoInitialFormData = (submissionDetail: ISubmission): ISubmissionForm => ({
  ...submissionDetail,
  practice: submissionDetail?.practice?.id,
  patient: submissionDetail?.patient?.id,
  patientDetail: {
    ...submissionDetail?.patient,
    dob: submissionDetail?.patient.dob ? moment(submissionDetail?.patient.dob) : null,
  },
  outboundInvoiceDate: submissionDetail.outboundInvoiceDate ? moment(submissionDetail.outboundInvoiceDate) : null,
  statedTimings: submissionDetail.statedTimings ? moment(submissionDetail.statedTimings) : null,
  postageDate: submissionDetail?.postageDate ? moment(submissionDetail.postageDate) : null,
});

export const getDuoSubmissionDataFromDraft = (formData: ISubmissionForm): ISubmission =>
  ({
    ...formData,
    patient: formData.patientDetail,
  } as any);

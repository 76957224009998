import { Card } from 'antd';
import logoSvg from 'assets/images/dashboard/logo.svg';
import './success.styles.less';

export const CheckinSuccess = () => (
  <div className="success">
    <img src={logoSvg} alt="logo" height={60} />
    <Card style={{ width: 360, marginTop: 50 }} bordered={false}>
      <div className="success-message-container">
        <span className="title">Treatment Check In</span>
        <div className="half-border" />
        <p className="content mt-3">You have successfully submitted your treatment check-in.</p>
      </div>
    </Card>
  </div>
);

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TChatRoom } from 'components/chat/types';
import { TMessage } from 'services/chat/types';

type TFrom = 'loadMore' | 'loadRecent' | 'client' | 'justSent';
export type TRoomMessages = {
  hasMoreMessages: boolean;
  room: TChatRoom;
  messages?: TMessage[];
  lastId?: string;
  currentId?: string;
  from: TFrom;
  total: number;
};

export type TLoadedRooms = Record<string, TRoomMessages>;

type TChatState = {
  totalUnreadCount: number;
  showChat: boolean;
  activeRoomId?: string;
  rooms: TChatRoom[];
  loadedRooms: TLoadedRooms;
};

const INITIAL_STATE: TChatState = {
  totalUnreadCount: 0,
  showChat: false,
  activeRoomId: undefined,
  rooms: [],
  loadedRooms: {},
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: INITIAL_STATE,
  reducers: {
    toggleChat: (state, { payload }: PayloadAction<boolean | undefined>) => {
      // set active room id
      if (payload === undefined) {
        state.showChat = !state.showChat;
      } else {
        state.showChat = payload;
      }
    },
    joinRoom: (state, { payload }: PayloadAction<string | undefined>) => {
      state.activeRoomId = payload;

      if (payload) {
        if (!state.loadedRooms[payload]) {
          state.loadedRooms[payload] = {
            room: state.rooms.find((r) => r.id === payload)!,
            from: 'loadMore',
            total: 0,
            hasMoreMessages: true,
          };
        }
      }
    },
    setRooms: (state, { payload: { rooms } }: PayloadAction<{ rooms: TChatRoom[]; activeRoomId: string }>) => {
      state.rooms = rooms;
    },
    loadJoinedRoomMessages: (
      state,
      { payload: { messages, from, total } }: PayloadAction<{ messages: TMessage[]; from: TFrom; total: number }>,
    ) => {
      if (!state.activeRoomId) return;

      const loadedRoom = { ...state.loadedRooms[state.activeRoomId] };

      const lastMessageId = messages.at(-1)?.id;
      const firstMessageId = messages[0]?.id;

      loadedRoom.from = from;
      if (from === 'loadMore') {
        if (loadedRoom.messages) {
          loadedRoom.messages.unshift(...messages);
        } else {
          loadedRoom.messages = messages;
        }

        if (total > 0) {
          loadedRoom.lastId = firstMessageId;
        }
      } else {
        if (loadedRoom.messages) {
          loadedRoom.messages.push(...messages);
        } else {
          loadedRoom.messages = messages;
        }
        if (total > 0) {
          loadedRoom.currentId = lastMessageId;
        }
      }

      if (loadedRoom.hasMoreMessages === true) {
        loadedRoom.hasMoreMessages = total > loadedRoom.messages.length;
      }

      state.loadedRooms[state.activeRoomId] = {
        ...state.loadedRooms[state.activeRoomId],
        ...loadedRoom,
      };
    },
    handleMessageCome: (
      state,
      { payload: { message, userId } }: PayloadAction<{ message: TMessage; userId: string | undefined }>,
    ) => {
      const { chatRoom, from } = message;
      const roomIndex = state.rooms.findIndex((room) => room.id === chatRoom);

      if (roomIndex !== -1) {
        const room = state.rooms[roomIndex];
        const updatedRoom = {
          ...room,
          unreadMessageCount: from.id === userId ? room.unreadMessageCount : room.unreadMessageCount + 1,
        };
        state.rooms[roomIndex] = updatedRoom;

        if (state.loadedRooms[chatRoom]) {
          state.loadedRooms[chatRoom] = {
            ...state.loadedRooms[chatRoom],
            room: updatedRoom,
            messages: state.loadedRooms[chatRoom].messages
              ? [...(state.loadedRooms[chatRoom].messages || []), message]
              : [],
            from: from.id === userId ? 'justSent' : 'client',
          };
        }

        // Create a copy of the state.rooms array to trigger a re-render.
        const updatedRooms = [...state.rooms];
        state.rooms = updatedRooms;
      }
    },
    setRoomUnreadMessageCount: (state) => {
      if (!state.activeRoomId) return;

      const activeRoomIndex = state.rooms.findIndex((room: TChatRoom) => room.id === state.activeRoomId);
      console.log('activeRoomIndex', activeRoomIndex);
      if (activeRoomIndex === -1) return;
      state.rooms[activeRoomIndex].unreadMessageCount = 0;
      if (state.loadedRooms[state.activeRoomId] && state.loadedRooms[state.activeRoomId].room) {
        state.loadedRooms[state.activeRoomId].room.unreadMessageCount = 0;
      }
    },
    setUserOnline: (state, { payload: { roomId, isOnline } }: PayloadAction<{ roomId: string; isOnline: boolean }>) => {
      const roomIndex = state.rooms.findIndex((room) => room.id === roomId);

      if (roomIndex !== -1) {
        state.rooms[roomIndex].member.isOnline = isOnline;
      }
    },
    resetChatState: () => INITIAL_STATE,
  },
});

export const {
  toggleChat,
  joinRoom,
  setRooms,
  loadJoinedRoomMessages,
  resetChatState,
  setRoomUnreadMessageCount,
  setUserOnline,
  handleMessageCome,
} = chatSlice.actions;

export default chatSlice.reducer;

import { ReactComponent as NewMessageSVG } from 'assets/images/new_message.svg';
import { ReactComponent as CaseSubmittedSVG } from 'assets/images/case_submitted.svg';
import { ReactComponent as NewCaseSVG, ReactComponent as CaseInstructionsReadySVG } from 'assets/images/new_case.svg';
import { ReactComponent as OrderDelayedSVG } from 'assets/images/order_delayed.svg';
import { ReactComponent as ReviewNewDesignSVG } from 'assets/images/review_new_design.svg';
import { ReactComponent as ThankYouSVG } from 'assets/images/thank_you.svg';
import { ReactComponent as OrderShippedSVG } from 'assets/images/order_shipped.svg';
import { ReactComponent as AwaitingApprovalSVG } from 'assets/images/awaiting_approval.svg';
import { ReactComponent as ProposalApprovalSVG } from 'assets/images/proposal_approved.svg';
import { ReactComponent as OrderApprovedSVG } from 'assets/images/order_approved.svg';
import { ReactComponent as ModificationRequestedSVG } from 'assets/images/modification_requested.svg';
import { ReactComponent as ReferralsSVG } from 'assets/images/referrals.svg';
import { ReactComponent as BookMeIn } from 'assets/images/book_me_in.svg';
import { duration } from 'helpers';
import { TNotificationItem } from 'services/notification/types';

export const dateToDuration = (date: string) => {
  const d: any = duration(date);

  if (d.days) return `${d.days} day${d.days > 1 ? 's' : ''} ago`;
  if (d.hours) return `${d.hours} hour${d.hours > 1 ? 's' : ''} ago`;
  if (d.minutes) return `${d.minutes} min${d.minutes > 1 ? 's' : ''} ago`;
  if (d.seconds) return `${d.seconds} secs ago`;

  return '';
};

export const parseNotificationData = (notification: TNotificationItem, userType?: string) => {
  let actionText = '';
  let actionUrl = '';
  let svgIcon = null;
  switch (notification.type) {
    case 'new_case':
      if (userType === 'designer' || userType === 'specialist') {
        actionText = 'View Case Instructions';
        actionUrl = `/${userType}`;
      } else {
        actionText = 'Go to Case Submission';
        actionUrl = `/${userType}/patients/${notification.submission}`;
      }
      svgIcon = <NewCaseSVG width={40} height={40} />;
      break;
    case 'case_submitted':
      actionText = 'Go to Case Submission';
      svgIcon = <CaseSubmittedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case 'new_message':
      actionText = 'View Message';
      svgIcon = <NewMessageSVG width={40} height={40} />;
      if (userType === 'manufacturer') {
        actionUrl = `/${userType}/orders/${notification.submission}?open=message_box&active_channel=${notification.from?.id}`;
      } else {
        actionUrl = `/${userType}/patients/${notification.submission}?open=message_box&active_channel=${notification.from?.id}`;
      }
      break;
    case 'awaiting_design_approval':
      actionText = 'Go to Treatment Proposal';
      svgIcon = <AwaitingApprovalSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}/design/${notification.treatmentDesign}`;
      break;
    case 'order_shipped':
      actionText = 'Go to Order Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary`;
      break;
    case 'order_approved':
      actionText = 'Go to Order Details';
      svgIcon = <OrderApprovedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary`;
      break;
    case 'order_delayed':
      actionText = 'Go to Order Details';
      svgIcon = <OrderDelayedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=order-summary`;
      break;
    case 'referrals':
      actionText = 'Referrals';
      svgIcon = <ReferralsSVG width={40} height={40} />;
      actionUrl = '';
      break;
    case 'thank_you':
      actionText = 'View Message';
      svgIcon = <ThankYouSVG width={40} height={40} />;
      actionUrl = `/${userType}?submissionId=${notification.submission}`;
      break;
    case 'modification_request':
      actionText = 'View modification request';
      svgIcon = <ModificationRequestedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}/design/${notification.treatmentDesign}`;
      break;
    case 'proposal_approved':
      actionText = 'View approved design';
      svgIcon = <ProposalApprovalSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}/design/${notification.treatmentDesign}`;
      break;
    case 'new_order':
      actionText = 'Mark as viewed';
      svgIcon = <OrderApprovedSVG width={40} height={40} />;
      actionUrl = '';
      break;
    case 'newSubmission':
      actionText = 'Go to Treatment check in';
      svgIcon = <NewCaseSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=treatment-checkins`;
      break;
    case 'patient_proposal':
      actionText = 'Go to Patient proposal';
      svgIcon = <BookMeIn width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}?activeTab=patient-proposal`;
      break;
    case 'case_not_suitable':
      actionText = 'Go to Case Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case 'case_delayed':
      actionText = 'Go to Case Details';
      svgIcon = <OrderShippedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    case 'education_content':
      actionText = 'Go to Education content';
      svgIcon = <ReviewNewDesignSVG width={40} height={40} />;
      actionUrl = `/${userType}/education/${notification.educationContent}`;
      break;
    case 'case_instructions_ready':
      actionText = 'Case Instructions Ready';
      actionUrl = `/${userType}/patients/${notification.submission}`;
      svgIcon = <CaseInstructionsReadySVG width={40} height={40} />;
      break;
    case 'new_stl_uploaded':
      actionText = 'View New STL uploaded';
      svgIcon = <ModificationRequestedSVG width={40} height={40} />;
      actionUrl = `/${userType}/patients/${notification.submission}`;
      break;
    default:
  }

  return {
    actionText,
    actionUrl,
    svgIcon,
  };
};

import { FC } from 'react';
import { Input, Button, Select, Tooltip, FormInstance } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ScrollToView } from 'components/ScrollToView';
import { PopoverDelete } from 'components/popovers/popover-delete';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { AddressField, IAddress } from 'components/address-field/address-field';
import { ScannerSelect } from 'components/scanner-select/scanner-select';

interface IClinicItemProps {
  form: FormInstance<any>;
  formListName: string;
  index: number;
  field: FormListFieldData;
  onRemove: () => void;
  onFormSetFieldsValueAddress: (address: IAddress) => void;
}

export const ClinicItem: FC<IClinicItemProps> = ({
  form,
  formListName,
  index,
  field,
  onRemove,
  onFormSetFieldsValueAddress,
}) => {
  const { key, name, ...restField } = field;

  return (
    <>
      <ScrollToView smooth block={index === 0 ? 'center' : 'start'} />
      <div className={`grid grid-cols-2 gap-4 ${index > 0 ? 'mt-4 border-top mb-2' : ''}`}>
        {index > 0 && (
          <div className="col-span-2 mt-4">
            <div className="item-remove-container">
              <div className="practises-text-container">
                <span>Clinic {index + 1}</span>
              </div>
              <PopoverDelete onDelete={onRemove} placement="leftTop">
                <Tooltip title="Delete">
                  <Button shape="circle" icon={<CloseOutlined />} />
                </Tooltip>
              </PopoverDelete>
            </div>
          </div>
        )}
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            formListName={formListName}
            {...restField}
            label="Clinic Name*"
            name={[name, 'name']}
            rules={[{ required: true, message: 'Please tell us where you work' }]}
          >
            <Input className="custom-input" placeholder="Enter clinic name" />
          </FormItemControl>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <AddressField
            form={form}
            formListName={formListName}
            {...restField}
            label="Clinic Address *"
            name={[name, 'address', 'address1']}
            rules={[{ required: true, message: 'Please add the clinic address so we know where to send things' }]}
            onFormSetFieldsValueAddress={onFormSetFieldsValueAddress}
            placeholder="Enter clinic address"
          />
        </div>

        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            formListName={formListName}
            {...restField}
            label="Clinic Email"
            name={[name, 'email']}
            rules={[{ type: 'email', message: 'Please enter your full email address' }]}
            normalize={(fieldValue) => fieldValue?.toLowerCase()}
          >
            <Input className="custom-input" placeholder="Enter clinic email" />
          </FormItemControl>
          <div style={{ fontSize: '10px', color: '#808080' }}>
            Don’t worry if you don’t have this yet, we’ll check when you onboard with us
          </div>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            formListName={formListName}
            {...restField}
            label="Clinic Postcode *"
            name={[name, 'address', 'postCode']}
            rules={[{ required: true, message: 'Please enter your clinic post code or city' }]}
          >
            <Input className="custom-input" placeholder="Enter post code or city" />
          </FormItemControl>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            formListName={formListName}
            {...restField}
            label="Do you have a scanner in the practice? *"
            name={[name, 'isScannerAvailable']}
            rules={[{ required: true, message: 'Please select option' }]}
          >
            <Select
              className="custom-input"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              placeholder="Select Option"
            />
          </FormItemControl>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.clinics?.[index]?.isScannerAvailable !== currentValues.clinics?.[index]?.isScannerAvailable
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('clinics')?.[index]?.isScannerAvailable ? (
                <FormItemControl
                  form={form}
                  formListName={formListName}
                  {...restField}
                  label="Scanner type"
                  name={[name, 'scanners']}
                >
                  <ScannerSelect className="custom-input" mode="multiple" />
                </FormItemControl>
              ) : null
            }
          </FormItemControl>
        </div>
      </div>
    </>
  );
};

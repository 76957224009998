/* eslint-disable react/no-unstable-nested-components */
import React, { Suspense } from 'react';
import { SUBMISSION_SERVICE_TYPE, USER_ROLES } from '../../config/constants';
import { IRoute } from 'interface/IRoute';

const LazyDashboardComponent = React.lazy(() => import('./dashboard/dashboard.component'));
const LazyPatientsComponent = React.lazy(() => import('./patients/patients'));
const LazyEdu = React.lazy(() => import('./edu/edu'));
const LazyPricing = React.lazy(() => import('./pricing/pricing'));
const LazyCaseSubmissionComponent = React.lazy(() => import('./case-submission/case-submission'));
const LazyProfileComponent = React.lazy(() => import('../../components/profile/profile'));

export const gdpRoutes: IRoute[] = [
  {
    path: '/dentist',
    exact: true,
    name: 'Dashboard',
    key: 'dashboard_page',
    component: () => (
      <Suspense fallback={() => <div>Loading...</div>}>
        <LazyDashboardComponent />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/profile',
    exact: true,
    name: 'Profile',
    key: 'profile_page',
    component: () => (
      <Suspense fallback={() => <div>Loading...</div>}>
        <LazyProfileComponent userType={USER_ROLES.DENTIST} />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/patients',
    exact: false,
    name: 'Patients',
    key: 'patients_page',
    component: () => (
      <Suspense fallback={() => <div>Loading...</div>}>
        <LazyPatientsComponent />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/patients/submit/:type',
    exact: true,
    name: 'Submit Case Page',
    key: 'submit_case_page',
    component: (props: any) => {
      const { match, history, location } = props;
      if (match?.params?.type === 'duo') {
        history.push(`/dentist/patients/submit/${SUBMISSION_SERVICE_TYPE.DUO}${location.search}`);
      } else if (match?.params?.type === 'solo') {
        history.push(`/dentist/patients/submit/${SUBMISSION_SERVICE_TYPE.SOLO}${location.search}`);
      }
      return (
        <Suspense fallback={() => <div>Loading...</div>}>
          <LazyCaseSubmissionComponent />
        </Suspense>
      );
    },
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/education',
    exact: false,
    name: 'Education',
    key: 'edu_page',
    component: () => (
      <Suspense fallback={() => <div>Loading...</div>}>
        <LazyEdu />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
  {
    path: '/dentist/pricing',
    exact: false,
    name: 'Pricing',
    key: 'pricing_page',
    component: () => (
      <Suspense fallback={() => <div>Loading...</div>}>
        <LazyPricing />
      </Suspense>
    ),
    role: USER_ROLES.DENTIST,
  },
];

const nestedRoutes = [
  '/dentist/patients/:submissionId',
  '/dentist/patients/:submissionId/design/:designId',
  '/dentist/patients/:submissionId/design/:designId/patient-proposal/create',
  '/dentist/patients/:submissionId/design/:designId/patient-proposal/:proposalId/edit',
  '/dentist/education/:contentId',
  '/dentist/education/assign/:contentId',
];

export const gdpRoutesToCheck = gdpRoutes.map((route) => route.path).concat(...nestedRoutes);

import React, { Suspense } from 'react';
import { USER_ROLES } from '../../config/constants';
import { IRoute } from 'interface/IRoute';

const LazyDashboardComponent = React.lazy(() => import('./designer-dashboard/designer-dashboard.component'));
const LazyPatientsComponent = React.lazy(() => import('./patients/patients.component'));
const LazySubmitDesignComponent = React.lazy(() => import('./submit-design/submit-design.component'));
const LazyProfileComponent = React.lazy(() => import('../../components/profile/profile'));

const LoadingFallback = () => <div>Loading...</div>;

export const designerRoutes: IRoute[] = [
  {
    path: '/designer',
    exact: true,
    name: 'Dashboard',
    key: 'dashboard_page',
    component: () => (
      <Suspense fallback={LoadingFallback}>
        <LazyDashboardComponent />
      </Suspense>
    ),
    role: USER_ROLES.DESIGNER,
  },
  {
    path: '/designer/patients',
    exact: false,
    name: 'Patients',
    key: 'patients_page',
    component: () => (
      <Suspense fallback={LoadingFallback}>
        <LazyPatientsComponent />
      </Suspense>
    ),
    role: USER_ROLES.DESIGNER,
  },
  {
    path: '/designer/profile',
    exact: true,
    name: 'Profile',
    key: 'profile_page',
    component: () => (
      <Suspense fallback={LoadingFallback}>
        <LazyProfileComponent userType={USER_ROLES.DESIGNER} />
      </Suspense>
    ),
    role: USER_ROLES.DESIGNER,
  },
  {
    path: '/designer/patients/:submissionId/submit-design',
    exact: true,
    name: 'Submit Design Page',
    key: 'submit_design_page',
    component: () => (
      <Suspense fallback={LoadingFallback}>
        <LazySubmitDesignComponent />
      </Suspense>
    ),
    role: USER_ROLES.DESIGNER,
  },
];

const nestedRoutes = ['/designer/patients/:submissionId', '/designer/patients/:submissionId/design/:designId'];
export const designerRoutesToCheck = designerRoutes.map((route) => route.path).concat(...nestedRoutes);

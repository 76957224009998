import axios from 'axios';

export const uploadFile = (url: any, file: any, options?: any) => axios.put(url, file, options);

export const s3Upload = (url: string, file: any, percentChange?: (pc: number) => void) =>
  new Promise((resolve, reject) => {
    axios
      .request({
        method: 'PUT',
        url,
        data: file,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (p) => percentChange && percentChange((100 * p.loaded) / p.total),
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

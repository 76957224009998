import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const NotAuthorized = () => {
  const history = useHistory();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button
          type="primary"
          className="btn-primary-redesigned"
          size="large"
          onClick={() => {
            history.push('/');
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};

export default NotAuthorized;

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import { useRegisterMutation } from '../services/authApi';
import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';
import { useAppSelector } from 'hooks/store';
import { selectGooglePlacesLoaded, setGooglePlacesLoaded } from 'redux/appSlice';
import { useDispatch } from 'react-redux';
import { notificationApiError } from 'utils/ui';
import '../dentist-signup/signup.styles.less';
import { FormItemPhoneNumber } from 'components/form/form-item-phone-number/form-item-phone-number';
import { AddressField, IAddress } from 'components/address-field/address-field';
import { USER_ROLES } from 'config/constants';
import { CountryInput } from 'components/country-input/country-input';
import { PasswordField } from 'components/password-field/password-field';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { ProfilePicUpload } from '../components/profile-pic-upload/profile-pic-upload';

const ManufacturerSignupPage = () => {
  const dispatch = useDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [register] = useRegisterMutation();
  const googlePlacesLoaded = useAppSelector(selectGooglePlacesLoaded);

  // TODO: 1270
  setTimeout(() => dispatch(setGooglePlacesLoaded({ loading: true })), 500);

  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = async (values: any) => {
    setFormSubmitting(true);
    const { confirmPassword, ...rest } = values;

    const data = {
      ...rest,
      type: 'manufacturer',
    };

    try {
      const response = await register(data).unwrap();
      if (response) {
        history.push(`/${USER_ROLES.MANUFACTURER}`);
      }
    } catch (error: any) {
      notificationApiError(error);
    } finally {
      setFormSubmitting(false);
    }
  };

  return (
    <AuthLayout background2 showGotoSignin>
      <div className="signup-page !pt-0">
        <div className="signup-right-container">
          <div className="signup-form-container">
            <h2 className="signup-text">Manufacturer Sign Up</h2>
            <Form name="manufacturer-signup" onFinish={onFinish} size="large" autoComplete="off" layout="vertical">
              <div className="steps-content">
                <div className="grid grid-cols-2 gap-4">
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl
                      name="firstName"
                      label="Contact First Name *"
                      rules={[
                        {
                          required: true,
                          message: 'Please input first name',
                        },
                      ]}
                    >
                      <Input className="custom-input" placeholder="John" />
                    </FormItemControl>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl
                      name="lastName"
                      label="Contact Last Name *"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please input last name',
                        },
                      ]}
                    >
                      <Input className="custom-input" placeholder="Doe" />
                    </FormItemControl>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <PasswordField form={form} />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl
                      name="confirmPassword"
                      label="Confirm Password *"
                      dependencies={['password']}
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please input confirm password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password className="custom-input" placeholder="Enter Confirm Password" />
                    </FormItemControl>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl
                      name="email"
                      label="Email Address *"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your email address',
                        },
                        { type: 'email', message: 'Please enter your full email address' },
                      ]}
                      normalize={(value) => value?.toLowerCase()}
                    >
                      <Input className="custom-input" placeholder="Enter your email address" />
                    </FormItemControl>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl name={['business', 'name']} label="Business Name">
                      <Input className="custom-input" placeholder="Enter Business Name" />
                    </FormItemControl>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemPhoneNumber
                      form={form}
                      label="Mobile Number *"
                      name="phone"
                      isRequired
                      inputClassName="custom-input"
                      numberPlaceholder="Enter your mobile number"
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    {googlePlacesLoaded && (
                      <AddressField
                        label="Business Address *"
                        name={['business', 'address', 'address1']}
                        rules={[{ required: true, message: 'Please enter your business address' }]}
                        onFormSetFieldsValueAddress={(val: IAddress) => form.setFieldsValue(val)}
                        placeholder="Enter first line of your address"
                      />
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl name={['business', 'address', 'postcode']} label="Business Postcode">
                      <Input className="custom-input" placeholder="Enter your postcode" />
                    </FormItemControl>
                    <FormItemControl name={['business', 'address', 'country']} hidden />
                    <FormItemControl name={['business', 'address', 'coordinates']} hidden />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl name="country" label="Business Country">
                      <CountryInput className="custom-input" placeholder="Select your country" />
                    </FormItemControl>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormItemControl name="profilePic" label="Would you like to upload a profile picture?">
                      <ProfilePicUpload />
                    </FormItemControl>
                  </div>
                </div>
              </div>
              <div className="steps-action">
                <Form.Item>
                  <AuthButtonComponent isLoading={formSubmitting}>Submit</AuthButtonComponent>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ManufacturerSignupPage;

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PersonalDetailsForm } from './personal-details.component';
import { AccountDetailsForm } from './account-details.component';
import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import { useRegisterMutation } from '../services/authApi';
import { notificationApiError } from 'utils/ui';
import { USER_ROLES } from 'config/constants';
import '../dentist-signup/signup.styles.less';
import { isEmpty, pickBy } from 'lodash';

const SpecialistSignupPage = () => {
  const [step, setStep] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formData, setFormData]: any = useState({ type: 'specialist' });
  const history = useHistory();
  const [register] = useRegisterMutation();

  const handleNextButton = () => {
    setStep(step + 1);
  };

  const handleBackButton = () => {
    setStep(step - 1);
  };

  const handleSubmitButton = async (values: any) => {
    setFormSubmitting(true);
    const { profilePic, bank } = values;
    const { confirmPassword, ...personalDetails } = formData.personalDetails;
    const data = {
      type: 'specialist',
      ...personalDetails,
      ...(isEmpty(pickBy(bank)) ? {} : { bank }),
      profilePic,
    };
    try {
      const response = await register(data).unwrap();
      if (response) {
        history.push(`/${USER_ROLES.SPECIALIST}`);
      }
    } catch (error: any) {
      notificationApiError(error);
    } finally {
      setFormSubmitting(false);
    }
  };

  const getStepValue = (key: string, values: object) => {
    const copyFormData = {
      ...formData,
      [key]: values,
    };
    setFormData(copyFormData);
  };

  return (
    <AuthLayout background2 showGotoSignin>
      <div className="signup-page !pt-0">
        <div className="signup-right-container">
          <div className="signup-form-container">
            <h2 className="signup-text">Specialist Sign Up</h2>

            <div className="steps-content">
              {step === 0 && (
                <PersonalDetailsForm
                  initialValues={formData.personalDetails || {}}
                  submittedValues={getStepValue}
                  handleNextButton={handleNextButton}
                />
              )}
              {step === 1 && (
                <AccountDetailsForm
                  initialValues={formData.bankDetails}
                  submittedValues={getStepValue}
                  handleNextButton={handleSubmitButton}
                  handleBackButton={handleBackButton}
                  formSubmitting={formSubmitting}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SpecialistSignupPage;

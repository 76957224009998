import { FC, ReactNode } from 'react';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue';
import { Popconfirm } from 'antd';

interface IPopconfirmDeleteProps extends Omit<PopconfirmProps, 'title'> {
  title?: ReactNode | RenderFunction;
}

export const PopconfirmDelete: FC<IPopconfirmDeleteProps> = ({ children, ...rest }) => (
  <Popconfirm
    title="Please confirm you want to delete?"
    okText="Delete"
    cancelText="Cancel"
    okButtonProps={{
      danger: true,
      className: 'ml-2 btn-danger-redesigned',
      shape: 'round',
    }}
    cancelButtonProps={{
      className: 'ml-2 btn-default-redesigned',
      shape: 'round',
    }}
    {...rest}
  >
    {children}
  </Popconfirm>
);

import { FC } from 'react';
import Tour, { ReactourStep } from 'reactour';
import { Button } from 'antd';
import { NiceButton } from 'components/commons/nice-button/nice-button';

interface IReactTourProps {
  onboardingSteps: ReactourStep[];
  getCurrentStep?: (index: number) => void;
  handleTourRequestClose: () => void;
  startOnboardingWizard: boolean;
  showNextPrevButtons?: boolean;
  lastStepNextButton?: string;
  className?: string;
  onBack?: (() => void) | undefined;
  spaceBetweenHighlighterAndPopup?: number;
  maskBorderRadius?: number;
}

const renderNextButton = () => (
  <Button type="primary" shape="round" className="uppercase btn-primary-redesigned">
    Next
  </Button>
);

const renderLastStepButton = (lastStepNextButton: string) => (
  <NiceButton size="small" className="!h-8" niceType="orange">
    {lastStepNextButton}
  </NiceButton>
);

export const ReactTourComponent: FC<IReactTourProps> = ({
  onboardingSteps,
  getCurrentStep,
  handleTourRequestClose,
  startOnboardingWizard,
  showNextPrevButtons,
  lastStepNextButton,
  className,
  onBack,
  spaceBetweenHighlighterAndPopup,
  maskBorderRadius,
}) => (
  <Tour
    steps={onboardingSteps}
    isOpen={startOnboardingWizard}
    onRequestClose={handleTourRequestClose}
    closeWithMask={false}
    getCurrentStep={getCurrentStep}
    disableInteraction
    showNumber={false}
    showNavigation={false}
    className={className}
    maskSpace={spaceBetweenHighlighterAndPopup}
    rounded={maskBorderRadius}
    highlightedMaskClassName="bg-white"
    accentColor="#050D43"
    nextButton={showNextPrevButtons ? renderNextButton() : <span />}
    prevButton={showNextPrevButtons ? <Button onClick={onBack}>Back</Button> : <span />}
    lastStepNextButton={lastStepNextButton ? renderLastStepButton(lastStepNextButton) : <span />}
  />
);

import React, { useState } from 'react';
import { Input, Popover } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form/Form';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import cn from 'classnames';
import './password-field.styles.less';

interface IPasswordField {
  form: FormInstance;
  name?: string;
  label?: string;
  isSigninField?: boolean;
  className?: string;
}

interface IValidateResults {
  message: string;
  rule: RegExp;
  isValid?: boolean;
}

const PASSWORD_RULES: IValidateResults[] = [
  {
    message: 'At least 10 character',
    rule: /.{10,}/,
  },
  {
    message: 'At least one upper case',
    rule: /(?=.*?[A-Z])/,
  },
  {
    message: 'At least one lower case',
    rule: /(?=.*?[a-z])/,
  },
  {
    message: 'At least one digit',
    rule: /(?=.*?[0-9])/,
  },
  {
    message: 'At least one special character',
    rule: /(?=.*?[#?!@$%^&*-])/,
  },
];

export const PasswordField: React.FC<IPasswordField> = ({
  form,
  name = 'password',
  label = 'Password*',
  isSigninField,
  className,
}) => {
  const [validateResults, setValidateResults] = useState<IValidateResults[]>();

  const validatePassword = (value: string = '') => {
    const results = PASSWORD_RULES.map((rule) => ({ ...rule, isValid: !!value.match(rule.rule)?.length }));

    setValidateResults(results);
    if (results.some((result) => !result.isValid)) {
      return Promise.reject(Error('Please enter valid password'));
    }
    return Promise.resolve();
  };

  return (
    <Popover
      placement="topLeft"
      content={
        validateResults?.length ? (
          <div>
            {validateResults?.map((result) => (
              <div
                key={result.message}
                className={`flex items-center gap-2 ${result.isValid ? 'text-successColor' : 'text-redColor'}`}
              >
                {result.isValid ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}
                {result.message}
              </div>
            ))}
          </div>
        ) : null
      }
      showArrow={!!validateResults?.length}
    >
      <FormItemControl
        form={form}
        name={name}
        label={label}
        className={cn({ 'custom-form-item': isSigninField }, className)}
        rules={[
          {
            validator(_, value) {
              return validatePassword(value);
            },
          },
        ]}
        validateTrigger="onChange"
      >
        <Input.Password
          className={isSigninField ? 'custom-signin-input' : 'custom-input'}
          placeholder="Enter password"
        />
      </FormItemControl>
    </Popover>
  );
};

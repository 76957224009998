import React, { FC } from 'react';
import { Button, Collapse, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './preview-proposal-more-info.styles.less';
import cn from 'classnames';
import mobileViewSvg from 'assets/images/patient-information/mobile_view.png';
import clearAlignerPng from 'assets/images/patient-information/clear_aligner.png';
import treatmentDurationPng from 'assets/images/patient-information/treatment_duration.png';
import interproximalReductionPng from 'assets/images/patient-information/interproximal_reduction.jpeg';
import attachmentsPng from 'assets/images/patient-information/attachments.png';
import retainerPng from 'assets/images/patient-information/retainer.png';
import externalSvg from 'assets/images/patient-information/external.svg';

interface IBigTypographyTitleProps {
  children: React.ReactNode;
  isFullSizePage: boolean;
}

export const BigTypographyTitle: FC<IBigTypographyTitleProps> = ({ children, isFullSizePage }) => (
  <Typography.Title
    level={3}
    className={cn('!mb-4 pb-2 border-0 border-solid border-b border-gray-300 font-semibold text-sm', {
      'md:text-lg': isFullSizePage,
    })}
  >
    {children}
  </Typography.Title>
);

interface IFurtherInfoComponentProps {
  title: string;
  paragraphs: string[];
  photo: any;
  photoAlt: string;
  isFullSizePage: boolean;
  revertUI?: boolean;
  moreContent?: React.ReactNode;
}

const FurtherInfoComponent: FC<IFurtherInfoComponentProps> = ({
  title,
  paragraphs,
  photo,
  photoAlt,
  revertUI,
  isFullSizePage,
  moreContent,
}) => (
  <div className={cn('custom-further-info py-3', { 'md:py-4': isFullSizePage })}>
    <Typography.Title
      level={4}
      className={`mb-3 pb-2 font-semibold text-sm ${isFullSizePage ? 'md:hidden md:text-base' : ''}`}
    >
      {title}
    </Typography.Title>
    <div
      className={cn('justify-between items-center', {
        'md:flex': isFullSizePage,
        'md:flex-row-reverse': revertUI && isFullSizePage,
      })}
    >
      <img
        className={`flex w-full radius-10 ${isFullSizePage ? (moreContent ? 'md:w-2/5' : 'md:w-1/3') : ''}`}
        src={photo}
        alt={photoAlt}
      />
      <div className={cn('w-full pt-3', { 'md:pt-0 md:w-1/2': isFullSizePage })}>
        <Typography.Title
          level={4}
          className={cn('mb-3 pb-2 hidden font-semibold text-sm', { 'md:block  md:text-base': isFullSizePage })}
        >
          {title}
        </Typography.Title>
        <ul>
          {paragraphs.map((text, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li className="text-sm font-normal line-height-2 py-1" key={`${text}${index}`}>
              {text}
            </li>
          ))}
        </ul>
        {moreContent}
      </div>
    </div>
  </div>
);

interface IPreviewProposalMoreInfoComponentProps {
  isFullSizePage?: boolean;
}

export const PreviewProposalMoreInfoComponent: FC<IPreviewProposalMoreInfoComponentProps> = ({
  isFullSizePage = false,
}) => (
  <div className={cn('custom-faqs pt-4 pb-4', { 'md:pt-7 md:pb-7': isFullSizePage })}>
    <BigTypographyTitle isFullSizePage={isFullSizePage}>In Person and Virtual Check ins</BigTypographyTitle>

    <FurtherInfoComponent
      title=""
      photo={mobileViewSvg}
      photoAlt="mobile view image"
      paragraphs={[
        'We’ll be reviewing your treatment in person on a regular basis',
        'We’ll also be reviewing your progress in person on a regular basis.',
        'You’ll receive a text message to submit selfie photographes with aligners in & out.',
        'In this way, you’ll be able to easily access me and raise any concerns.',
        'I will be monitoring  your treatment in every step!',
      ]}
      isFullSizePage={isFullSizePage}
      moreContent={
        <div
          className={cn(
            'flex justify-start w-full font-semibold border-0 border-solid border-t border-gray-300 pt-3 custom-external-link',
            { 'md:mt-4': isFullSizePage },
          )}
        >
          <a
            className="flex"
            target="_blank"
            href={`${window.location.origin}/files/2022_general_patient_consent_form _for_clear_aligner_orthodontics.pdf`}
            rel="noreferrer"
          >
            Detailed consent <img src={externalSvg} alt="" />
          </a>
        </div>
      }
    />

    <div className="mt-6" />

    <BigTypographyTitle isFullSizePage={isFullSizePage}>Further information</BigTypographyTitle>

    <FurtherInfoComponent
      title="Clear Aligners"
      photo={clearAlignerPng}
      photoAlt="clear aligner image"
      paragraphs={[
        'Clear Aligners are the modern way to straighten teeth vs metal wires and brackets',
        'Much more subtle, almost invisible',
        'Much more flexible, can be removed',
        'Custom-made for each patient based on a 3D model of their teeth',
        'The design of the aligners is based on a Treatment Plan, which specifies how each tooth will be moved in fine detail',
        'High quality treatment plans are hard to achieve, need a great deal of experience',
      ]}
      isFullSizePage={isFullSizePage}
    />

    <FurtherInfoComponent
      title="Treatment duration"
      photo={treatmentDurationPng}
      photoAlt="treatment duration image"
      paragraphs={[
        'Even with good compliance, there is always a chance that biological factors can influence how predictably your teeth will move',
        'You may require additional aligners during or at the end of the first stage to achieve the desired result.',
        'This may increase the duration of treatment',
      ]}
      isFullSizePage={isFullSizePage}
      revertUI
    />

    <FurtherInfoComponent
      title="Interproximal reduction"
      photo={interproximalReductionPng}
      photoAlt="interproximal reduction image"
      paragraphs={[
        'Interproximal reduction (IPR) is the removal of small amounts of outer enamel tooth surface between two adjacent teeth. It is a means to acquire additional space to create ideal tooth alignment.',
        'IPR takes only a few minutes and does not damage the enamel, the maximum amount of enamel removed is 0.25mm from a tooth’s edge',
        'The spaces created will be closed over the course of your treatment',
      ]}
      isFullSizePage={isFullSizePage}
    />

    <FurtherInfoComponent
      title="Attachments"
      photo={attachmentsPng}
      photoAlt="attachments image"
      paragraphs={[
        'Attachments are shapes added to the teeth to engage the aligner and assist with movements.',
        'They act like small handles for moving teeth',
        'Attachments are placed at the start of treatment and removed at the end of treatment',
      ]}
      isFullSizePage={isFullSizePage}
      revertUI
    />

    <FurtherInfoComponent
      title="Retainers"
      photo={retainerPng}
      photoAlt="retainers image"
      paragraphs={[
        'Removable retainers or fixed bonded retainers must be worn for as long as you want teeth to stay in their corrected position after treatment',
        'Fixed retainers are not suitable for every patient, for example, if there is insufficient space after treatment',
      ]}
      isFullSizePage={isFullSizePage}
    />

    <div className="mt-6" />

    <BigTypographyTitle isFullSizePage={isFullSizePage}>FAQ’s</BigTypographyTitle>

    <Collapse
      expandIconPosition="end"
      defaultActiveKey={[]}
      // eslint-disable-next-line react/no-unstable-nested-components
      expandIcon={({ isActive }) => (
        <Button className="collapse-btn flex justify-center items-center" shape="circle" size="small">
          <DownOutlined style={{ fontSize: 14 }} rotate={isActive ? 180 : 0} />
        </Button>
      )}
      ghost
    >
      {[
        {
          question: 'Can anyone tell that I’m wearing the aligners?',
          answer:
            'Clear aligners are virtually invisible. Some people may notice the small attachment ‘bumps’ on your teeth',
        },
        {
          question: 'Can I take my aligners off whenever I need to?',
          answer:
            'Yes, you can remove your aligners for eating, drinking, brushing, and flossing. You can take them off for special occasions, like a wedding or a job interview.. Just remember to wear them to 22 hours a day to get the best results.',
        },
        {
          question: 'Are there any restrictions on what I can eat?',
          answer: 'None! Clear aligners are removable, so there are no food restrictions at all.',
        },
        {
          question: 'Is clear aligner treatment painful?',
          answer:
            'Clear aligner treatment moves your teeth by applying gentle, constant force, but some people do feel temporary discomfort for the first few days of wearing a new set of aligners. Typically, people describe it as feeling “pressure” and it’s normal — it’s a sign your clear aligners are working and transforming your smile.',
        },
        {
          question: 'Will wearing clear aligners affect my speech?',
          answer:
            'It may take a couple of days for your tongue to get used to having your aligners in your mouth, and you may have a slight lisp. But this should disappear quickly.',
        },
        {
          question: 'What’s the best way to clean my aligners?',
          answer:
            'Clean your aligners at least twice daily with a soft brush and mild soap in lukewarm water. Do not use hot water, toothpaste or denture-cleaning agents to wash your aligners.',
        },
      ].map((faq) => (
        <Collapse.Panel key={faq.question} header={faq.question}>
          {faq.answer}
        </Collapse.Panel>
      ))}
    </Collapse>
  </div>
);

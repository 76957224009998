import { IDataLayerObject } from './types';

export const pushToDataLayer = (obj: IDataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const setUserData = (userId: string) => {
  pushToDataLayer({
    event: 'customUser',
    userData: {
      userId,
    },
  });
};

export const createPageEvent = (path: string) => {
  const obj: IDataLayerObject = {
    event: 'customPage',
    pageData: {
      path,
    },
  };
  pushToDataLayer(obj);
};

export const createGenericEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean,
) => {
  const obj: IDataLayerObject = {
    event: 'customEvent',
    eventData: {
      category,
      action,
      label,
      value,
      nonInteraction,
    },
  };
  pushToDataLayer(obj);
};

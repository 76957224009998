import NiceModal, { useModal } from '@ebay/nice-modal-react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAppVersionNotMatch } from 'redux/appSlice';

const NewAppVersionModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <NiceAntModal
      modal={modal}
      title="💎 New version available!"
      closable={false}
      maskClosable={false}
      footer={[
        <NiceButton niceType="orange" size="small" onClick={() => window.location.reload()}>
          Reload
        </NiceButton>,
      ]}
      width={400}
      keyboard={false}
    >
      We've just made some shiny new updates to 32Co. To get the latest and greatest version, just hit the 'Reload'
      button. Enjoy.
    </NiceAntModal>
  );
});

export const useAppVersionChecking = () => {
  const isAppVersionNotMatch = useSelector(selectAppVersionNotMatch);

  useEffect(() => {
    if (isAppVersionNotMatch) {
      NiceModal.show(NewAppVersionModal);
    }
  }, [isAppVersionNotMatch]);
};

// eslint-disable-next-line import/no-extraneous-dependencies
import io, { Socket } from 'socket.io-client';

type TDefaultEventsMap = {
  [eventName: string]: (...args: any[]) => void;
};

class SocketService {
  public socket: Socket | null = null;

  public connect(userId: string): Promise<Socket<TDefaultEventsMap, TDefaultEventsMap>> {
    console.log('userId', userId);
    return new Promise((rs, rj) => {
      this.socket = io(process.env.REACT_APP_BASE_URL || 'http://localhost:3003', {
        path: '/chat',
        transports: ['websocket'],
        upgrade: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        withCredentials: true,
        query: {
          userId,
        },
      });

      if (!this.socket) {
        rj();
        return;
      }

      this.socket.on('connect', () => {
        rs(this.socket as Socket);
      });

      this.socket.on('connect_error', (error) => {
        console.error(error);
      });
    });
  }

  public disconnect() {
    this.socket?.disconnect();
  }
}

export default new SocketService();

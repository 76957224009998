import { Button, Form, notification } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ModalCongratulation } from 'components/modals/modal-congratulation';
import Input from 'antd/lib/input/Input';
import { useRemindDentistJoinUsMutation } from '../../services/authApi';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { notificationApiError } from 'utils/ui';

interface IReminderModalProps {
  dentisEmailCast: string;
}

export const ReminderModal = NiceModal.create<IReminderModalProps>(({ dentisEmailCast }) => {
  const modal = useModal();
  const [form] = Form.useForm();

  const [remindDentistJoinUs, { isLoading }] = useRemindDentistJoinUsMutation();

  const handleFinish = async (values: { email: string }) => {
    try {
      await remindDentistJoinUs(values).unwrap();
      notification.success({
        message: 'Set reminder successfully!',
        description: 'Set reminder successfully!',
        placement: 'topRight',
        duration: 3,
      });
      modal.hide();
    } catch (error: any) {
      notificationApiError(error);
    }
  };

  return (
    <ModalCongratulation modal={modal} value="Wait" noAnimation>
      <br />
      <div className="text-base font-semibold text-darkBlueColor">Not ready to join us just yet?</div>
      <br />
      <div className="text-center">
        Would you like us to send you a quick reminder via email to join at a better time?
      </div>
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{ email: dentisEmailCast }}
        layout="vertical"
        size="large"
        autoComplete="off"
        className="mt-6 -mb-4"
      >
        <FormItemControl
          form={form}
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email address',
            },
            { type: 'email', message: 'Please enter your full email address' },
          ]}
          normalize={(value) => value?.toLowerCase()}
        >
          <Input className="custom-input" placeholder="Enter your email address" style={{ height: '40px' }} />
        </FormItemControl>
        <div className="mt-3">
          <Button className="mr-4 px-8" onClick={() => modal.hide()}>
            Exit
          </Button>
          <Button
            htmlType="submit"
            loading={isLoading}
            shape="round"
            style={{
              fontSize: '14px',
              background: 'linear-gradient(270deg, #E82A63 0%, #EA4C2A 100%)',
              color: 'white',
              border: 'none',
            }}
            id="remind-me"
          >
            Remind Me
          </Button>
        </div>
      </Form>
    </ModalCongratulation>
  );
});

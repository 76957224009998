import { useEffect, useState } from 'react';
import { BREAKPOINT } from '../utils/ui';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
    isSm: true,
    isMd: false,
    isLg: false,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isSm: window.innerWidth >= BREAKPOINT.SM,
        isMd: window.innerWidth >= BREAKPOINT.MD,
        isLg: window.innerWidth >= BREAKPOINT.LG,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

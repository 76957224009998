import { Select, SelectProps } from 'antd';
import { useGetScannerQuery } from 'services/api';

export interface IScanner {
  type: string;
  name: string;
  id: string;
  supportScannerUpload: boolean;
}

export const ScannerSelect = (props: SelectProps<IScanner>) => {
  const { data, isFetching, isLoading } = useGetScannerQuery();

  return (
    <Select
      placeholder="Select Scanner"
      allowClear
      showArrow
      loading={isFetching || isLoading}
      fieldNames={{ label: 'name', value: 'id' }}
      options={data as any}
      {...props}
    />
  );
};

import { FormInstance } from 'antd';
import Form, { RuleObject } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import {
  MobileNumberInput,
  IMobileNumberInputValue,
  IMobileNumberInputProps,
} from '../../mobile-number-input/mobile-number-input';
import { FormItemControl, IFormItemControlProps } from '../form-item-control/form-item-control';

// refer to https://en.wikipedia.org/wiki/List_of_mobile_telephone_prefixes_by_country
const NUMBER_VALID_LENGTH_MAP: { [key in string]: number } = {
  '+44': 10, // UK
  '+91': 10, // India
  '+1': 10, // USA
  '+84': 9, // Vietnam
};

const PHONE_NUMER_MIN_LENGTH = 4;
const ERROR_MESSAGE = 'We need your phone number in case we need to reach you';

const requireMobileNumber = (value?: IMobileNumberInputValue) => {
  const selectedRegion: string | undefined = value?.ext;
  const phoneNumber: string | undefined = value?.number !== undefined ? value.number?.toString() : undefined;

  if (!selectedRegion && !phoneNumber) {
    return Promise.reject(new Error(ERROR_MESSAGE));
  }

  return Promise.resolve();
};

const validMobileNumber = (value?: IMobileNumberInputValue) => {
  const selectedRegion: string | undefined = value?.ext;
  const phoneNumber: string | undefined = value?.number === undefined ? undefined : value.number?.toString();

  if (selectedRegion) {
    const numberValidLength = NUMBER_VALID_LENGTH_MAP[selectedRegion!] || PHONE_NUMER_MIN_LENGTH;
    if (!phoneNumber || phoneNumber.length < numberValidLength) {
      // case input region only, not number or number does not correct
      return Promise.reject(new Error(ERROR_MESSAGE));
    }
  } else if (!isEmpty(phoneNumber)) {
    // case input number but cannot recognize region
    return Promise.reject(new Error(ERROR_MESSAGE));
  }

  return Promise.resolve();
};

interface IFormItemPhoneNumberProps extends IMobileNumberInputProps, IFormItemControlProps {
  // eslint-disable-next-line react/no-unused-prop-types
  form?: FormInstance;
  name: NamePath;
  label?: string;
  isRequired?: boolean;
}

export const FormItemPhoneNumber: FC<IFormItemPhoneNumberProps> = (props) => {
  const { name, label, isRequired, ...inputProps } = props;
  const form = Form.useFormInstance();

  return (
    <FormItemControl
      {...inputProps}
      form={form}
      label={label}
      name={name}
      rules={[
        ...(isRequired
          ? [{ validator: (_: RuleObject, value: IMobileNumberInputValue) => requireMobileNumber(value) }]
          : []),
        { validator: (_, value) => validMobileNumber(value) },
      ]}
    >
      <MobileNumberInput {...inputProps} />
    </FormItemControl>
  );
};

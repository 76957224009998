import { useRef, useEffect, FC } from 'react';

interface IScrollToViewProps {
  smooth?: boolean;
  block?: 'start' | 'center' | 'end' | 'nearest';
}

export const ScrollToView: FC<IScrollToViewProps> = ({ smooth = false, block = 'start' }) => {
  const myRef: any = useRef(null);

  useEffect(() => {
    if (myRef !== null) {
      myRef?.current?.scrollIntoView({
        behavior: smooth ? 'smooth' : 'auto',
        block,
      });
    }
  }, []);

  return <div ref={myRef} />;
};

import cn from 'classnames';
import { FC, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useGeolocationIp } from 'hooks/useGeolocationIp';
import './styles.less';
import { isNil } from 'lodash';
import { useCountryOptions } from 'hooks/useCountryOptions';

export interface IMobileNumberInputValue {
  ext?: string;
  number?: string | number;
}
export interface IMobileNumberInputProps {
  value?: IMobileNumberInputValue;
  onChange?: (value: IMobileNumberInputValue | null) => void;
  numberPlaceholder?: string;
  inputClassName?: string;
  disabled?: boolean;
}

export const MobileNumberInput: FC<IMobileNumberInputProps> = (props) => {
  const { numberPlaceholder = 'Enter phone number', value, onChange, inputClassName = '', disabled } = props;
  const geo = useGeolocationIp();
  const { getCountryInfoFromCode } = useCountryOptions();

  useEffect(() => {
    if (geo?.country && !value?.ext && getCountryInfoFromCode) {
      const country = getCountryInfoFromCode(geo.country);
      onChange?.({ ext: country.ext });
    }
  }, [geo, getCountryInfoFromCode]);

  const inputValue = `${(value?.ext ?? '')?.replace('+', '')}${value?.number ?? ''}` || null;

  const handleChange = (phone: string, country: { dialCode: string }) => {
    if (country?.dialCode && phone?.length < country?.dialCode?.length) {
      // clear country once user hit backspace dial code
      onChange?.(null);
    } else {
      const ext = country?.dialCode ? `+${country?.dialCode}` : undefined;
      const number = !isNil(phone) && phone !== '' ? `+${phone}`.replace(ext || '', '') : phone;
      onChange?.({ ext, number });
    }
  };

  return (
    <PhoneInput
      containerClass={cn('ant-input mobile-number-input-container', inputClassName)}
      inputClass="mobile-number-input ant-input"
      buttonClass="mobile-number-button"
      dropdownClass="mobile-number-dropdown"
      value={inputValue}
      onChange={handleChange}
      placeholder={numberPlaceholder}
      searchPlaceholder="Search country"
      enableSearch
      disabled={disabled}
    />
  );
};

import React, { Suspense } from 'react';
import { USER_ROLES } from '../../config/constants';
import { IRoute } from 'interface/IRoute';

const LazyDashboardComponent = React.lazy(() => import('./dashboard/dashboard.component'));
const LazyPatientsComponent = React.lazy(() => import('./patients/patients.component'));
const LazyPatientDetailsComponent = React.lazy(() => import('./patients/patient-details/patient-details.component'));
const LazySubmitInstructions = React.lazy(() => import('./submit-instructions/submit-instructions'));
const LazyPatientRejectComponent = React.lazy(() => import('./case-not-suitable/case-not-suitable.component'));
const LazyPatientAdviceComponent = React.lazy(() => import('./submit-advice/submit-advice.component'));
const LazyPatientTreatmentDesignComponent = React.lazy(
  () => import('./patients/patient-details/treatment-proposal/treatment-proposal-details.component'),
);
const LazyProfileComponent = React.lazy(() => import('../../components/profile/profile'));
const LazyEdu = React.lazy(() => import('./edu/edu'));

const Loading = () => <div>Loading...</div>;

export const specialistRoutes: IRoute[] = [
  {
    path: '/specialist',
    exact: true,
    name: 'Dashboard',
    key: 'dashboard_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyDashboardComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients',
    exact: true,
    name: 'Patients',
    key: 'patients_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientsComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/profile',
    exact: true,
    name: 'Profile',
    key: 'profile_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyProfileComponent userType={USER_ROLES.SPECIALIST} />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_details_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientDetailsComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId/submit',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_details_submit_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazySubmitInstructions />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId/reject',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_details_reject_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientRejectComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId/design/:designId',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_details_td_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientTreatmentDesignComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId/design/:designId/advice',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_advice_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientAdviceComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId/draft-treatment-advice',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_advice_draft_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientAdviceComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId/draft-treatment-advice/:adviceId/edit',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_advice_edit_draft_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientAdviceComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/patients/:submissionId/design/:designId/advice/:adviceId/edit',
    exact: true,
    name: 'PatientDetails',
    key: 'patient_advice_edit_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyPatientAdviceComponent />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
  {
    path: '/specialist/education',
    exact: false,
    name: 'Education',
    key: 'edu_page',
    component: () => (
      <Suspense fallback={Loading}>
        <LazyEdu />
      </Suspense>
    ),
    role: USER_ROLES.SPECIALIST,
  },
];

const nestedRoutes = ['/specialist/education/:contentId'];
export const specialistRoutesToCheck = specialistRoutes.map((route) => route.path).concat(...nestedRoutes);

import { Button } from 'antd';
import React from 'react';
import './auth-button.styles.less';
import cn from 'classnames';

interface IAuthButtonComponentProps {
  children: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  outline?: boolean;
  onClick?: () => void;
}

export const AuthButtonComponent: React.FC<IAuthButtonComponentProps> = ({
  children,
  isLoading = false,
  className = '',
  outline = false,
  onClick,
}) => (
  <Button
    className={cn('auth-button', className)}
    onClick={onClick}
    htmlType={!onClick ? 'submit' : undefined}
    loading={isLoading}
    shape="round"
    style={{
      height: '50px',
      fontSize: '14px',
      background: outline ? 'none' : 'linear-gradient(270deg, #E82A63 0%, #EA4C2A 100%)',
      color: outline ? '#050D43' : 'white',
      border: outline ? '1px solid #050D43' : 'none',
    }}
  >
    {children}
  </Button>
);

import { Progress } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IDentistRegisterPayload, useRegisterMutation } from '../services/authApi';
import { pushToDataLayer } from 'utils/gtm';
import { AuthLayout } from '../components/auth-layout';
import { Greeting } from './steps/greeting';
import { PersonalDetails } from './steps/personal-details';
import { Experience } from './steps/experience/experience';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';
import { SuccessModal } from './success-modal/success-modal';
import { ReminderModal } from './reminder-modal/reminder-modal';
import { setCredentials } from '../authSlice';
import { useDispatch } from 'react-redux';
import { useQuery } from 'hooks/useQuery';
import { BREAKPOINT, notificationApiError } from 'utils/ui';
import './signup.styles.less';

const DIV = styled.div`
  .title {
    font-size: 22px;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    .title {
      font-size: 28px;
    }
  }
`;

const defaultFormData = {
  exp: {
    clinics: [
      {
        name: null,
        address: null,
        isScannerAvailable: null,
      },
    ],
  },
};

export const DentistSignup: FC = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [step, setStep] = useState(-1);
  const [formData, setFormData]: any = useState(defaultFormData);
  const [register, { isLoading: formSubmitting }] = useRegisterMutation();
  const { push } = useHistory();
  const allowShowRemider = query.get('show_reminder') !== 'false';
  const [isReminded, setIsReminded] = useState(!allowShowRemider);
  const [dentisEmailCast, setDentisEmailCast] = useState('');

  const handleNextButton = () => {
    switch (step) {
      case -1:
        pushToDataLayer({ event: 'signupform.signupclick' });
        break;
      case 0:
        pushToDataLayer({ event: 'signupform.personaldetails.next' });
        break;
      default:
    }
    setStep(step + 1);
  };

  const handleBackButton = () => {
    if (step === 1) {
      pushToDataLayer({ event: 'signupform.experience.previous' });
    }

    setStep(step - 1);
  };

  const handleSubmitButton = async (values: any) => {
    pushToDataLayer({ event: 'signupform.submit' });
    const { confirmPassword, ...details } = formData.personalDetails;

    const payload: IDentistRegisterPayload = {
      type: 'dentist',
      ...details,
      ...values,
    };

    try {
      const response = await register(payload).unwrap();
      if (response) {
        setIsReminded(true);
        NiceModal.show(SuccessModal, {
          gotoDashboard: () => {
            const userObject = { ...response, type: response.role?.toLowerCase() };
            dispatch(setCredentials(userObject));
            localStorage.setItem('userType', JSON.stringify(userObject.type));
            push('/');
          },
        });
      }
    } catch (error: any) {
      notificationApiError(error);
    }
  };

  const getStepValue = (key: string, values: object) => {
    const copyFormData = {
      ...formData,
      [key]: values,
    };
    setFormData(copyFormData);
  };

  const handleDentisEmailChange = (email: string) => {
    setDentisEmailCast(email);
  };
  const [isStayOver10s, setIsStayOver10s] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setIsStayOver10s(true), 8000);
  }, []);

  const handleMouseLeave = () => {
    if (!isReminded && !formSubmitting && (isStayOver10s || step > -1)) {
      NiceModal.show(ReminderModal, { dentisEmailCast });
      setIsReminded(true);
    }
  };

  return (
    <AuthLayout onMouseLeave={handleMouseLeave}>
      <DIV className="signup-page">
        {step === -1 && <Greeting onNext={handleNextButton} />}

        {step > -1 && (
          <div className="flex justify-between">
            <div className="text-darkBlueColor font-bold title">
              {step === 0
                ? 'First, a few details so we can create your account'
                : 'Finally, a little bit about your work as a dentist'}
            </div>
            <Progress
              type="circle"
              percent={(99.99 * (step + 1)) / 2}
              format={(percent) => `${Math.round((percent || 100 / 2) / (100 / 2))}/2`}
              width={54}
            />
          </div>
        )}
        <div className="steps-content">
          {step === 0 && (
            <PersonalDetails
              initialValues={formData.personalDetails || {}}
              submittedValues={getStepValue}
              handleNextButton={handleNextButton}
              onDentisEmailChange={handleDentisEmailChange}
            />
          )}
          {step === 1 && (
            <Experience
              initialValues={formData.exp}
              submittedValues={getStepValue}
              handleNextButton={handleSubmitButton}
              handleBackButton={handleBackButton}
              formSubmitting={formSubmitting}
            />
          )}
        </div>
      </DIV>
    </AuthLayout>
  );
};

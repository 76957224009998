/* eslint-disable class-methods-use-this */
import { TNotificationItem } from 'services/notification/types';
import socketService from './index';

class NotificationsSocketService {
  public async onNotificationCome(listiner: (notis: TNotificationItem) => void) {
    if (socketService.socket) {
      socketService?.socket?.on('notification', (notis) => listiner(notis));
    }
  }

  public async offNotificationCome() {
    if (socketService.socket) {
      socketService?.socket?.off('notification');
    }
  }
}

export default new NotificationsSocketService();

import { specialistRoutes } from './specialist-routes';
import { DashboardLayout } from 'components/layout/dashboard-layout/dashboard-layout';
import { useEffect } from 'react';
import { useFetchProfile } from 'hooks/useFetchProfile';

const SpecialistModule = () => {
  const { fetchProfile } = useFetchProfile();

  useEffect(() => {
    fetchProfile();
  }, []);

  return <DashboardLayout routes={specialistRoutes} />;
};

export default SpecialistModule;

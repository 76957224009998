import { useEffect } from 'react';
import { Result, Spin } from 'antd';
import { useQuery } from 'hooks/useQuery';
import { useVerifyEmailMutation } from '../services/authApi';
import { useHistory } from 'react-router-dom';
import '../signup-success/signup-success.styles.less';

export const EmailSuccessfullyVerifiedPage = () => {
  const query = useQuery();
  const history = useHistory();
  const [verifyEmail, { isError, isSuccess }] = useVerifyEmailMutation();
  const idToken = query.get('id');

  useEffect(() => {
    if (idToken) {
      verifyEmail(idToken);
    }
  }, []);

  if (isSuccess) {
    history.push('/');
  }

  if (isError || !idToken) {
    return (
      <Result
        status="500"
        title="Error"
        subTitle="Sorry, something went wrong."
        extra={<a href="mailto:support@32co.com">support@32co.com</a>}
      />
    );
  }

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <Spin />
    </div>
  );
};

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import classNames from 'classnames';
import './team-onboarding-video-modal.styles.less';
import NiceAntModal from '../../../../commons/nice-ant-modal/nice-ant-modal.component';

export const TeamVideoModal = NiceModal.create<{ onClose?: () => void }>(({ onClose }) => {
  const modal = useModal();
  return (
    <NiceAntModal modal={modal} afterClose={onClose} className={classNames('teamOnboardingVideoModal')}>
      <iframe
        className="teamOnboardingVideoModal__video"
        width="550"
        height="340"
        src="https://www.youtube.com/embed/Z6B-7XTfsj8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </NiceAntModal>
  );
});

import { api, VERSION } from 'services/api';
import { IUser } from './type';
import type { IAddress } from 'components/address-field/address-field';

export interface ISignedURLRequest {
  fileName: any;
  mimeType: any;
  folderName: string;
}

export interface IPersonalDetails {
  firstName: string;
  lastName: string;
  password: string;
  email: {
    email: string;
  };
  mobile: TPhone;
}

export interface ILoginRequest {
  email: string;
  password: string;
  remember?: boolean | null;
}

type TClinicItem = {
  clinicName: string;
  address: {
    formattedAddress?: string; // TODO: need to remove
    address1?: string;
    postCode?: string;
    state?: string;
  };
  scannerAvailable: 'Yes' | 'No';
};
export interface IDentistRegisterPayload {
  type: 'dentist';
  referredCode?: string;
  personalDetails: {
    firstName: string;
    lastName: string;
    password: string;
    dob: string;
    email: {
      email: string;
    };
    mobile: TPhone;
    gdcNumber: string;
    country: string;
  };
  exp: {
    practicesYear: number;
    totalClearAlignerCases: string;
  };
  clinics: TClinicItem[];
}

export interface IDesignerRegisterRequest {
  type: string;
  personalDetails: IPersonalDetails;
  business: {
    address: IAddress;
  };
  bankDetails: any;
  profilePic: string;
  designLabName: string;
}

export interface IManufacturerRequest {
  personalDetails?: any;
  type: string;
  profilePic?: string;
  business?: any;
}

export interface ISpecialistRequest {
  personalDetails?: any;
  type: string;
  profilePic?: string;
  business?: any;
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IUser, ILoginRequest>({
      query: (credentials) => ({
        url: `/${VERSION}/user/auth/signin`,
        method: 'POST',
        body: credentials,
      }),
    }),
    register: builder.mutation<
      any,
      IDentistRegisterPayload | IDesignerRegisterRequest | IManufacturerRequest | ISpecialistRequest
    >({
      query: (
        credentials: IDentistRegisterPayload | IDesignerRegisterRequest | IManufacturerRequest | ISpecialistRequest,
      ) => ({
        url: `/${VERSION}/user/auth/register/${credentials.type}`,
        method: 'POST',
        body: credentials,
      }),
    }),
    verifyResetPasswordToken: builder.mutation<any, any>({
      query: (credentials: any) => ({
        url: `/${VERSION}/user/auth/password/reset/verify`,
        method: 'POST',
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation<
      any,
      IDentistRegisterPayload | IDesignerRegisterRequest | IManufacturerRequest | ISpecialistRequest
    >({
      query: (credentials: any) => ({
        url: `/${VERSION}/user/auth/password/reset`,
        method: 'POST',
        body: credentials,
      }),
    }),
    getProfileSignedUrl: builder.mutation<any, ISignedURLRequest>({
      query: (credentials) => ({
        url: `/${VERSION}/user/files/signed-url`, // public, no authorize
        method: 'POST',
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<
      any,
      IDentistRegisterPayload | IDesignerRegisterRequest | IManufacturerRequest | ISpecialistRequest
    >({
      query: (credentials: any) => ({
        url: `/${VERSION}/user/auth/password/reset/request`,
        method: 'POST',
        body: credentials,
      }),
    }),
    verifyEmail: builder.mutation<any, string>({
      query: (id) => ({
        url: `/${VERSION}/user/auth/verify`,
        method: 'POST',
        body: { token: id },
      }),
    }),
    remindDentistJoinUs: builder.mutation<any, { email: string }>({
      query: (payload) => ({
        url: `/${VERSION}/user/auth/dentist-signup-reminder`,
        method: 'POST',
        body: payload,
      }),
    }),
    signOut: builder.mutation<any, void>({
      query: () => ({
        url: `/${VERSION}/user/auth/signout`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyResetPasswordTokenMutation,
  useResetPasswordMutation,
  useGetProfileSignedUrlMutation,
  useForgotPasswordMutation,
  useVerifyEmailMutation,
  useRemindDentistJoinUsMutation,
  useSignOutMutation,
} = authApi;

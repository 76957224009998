import { FC } from 'react';
import { Form, Input } from 'antd';
import type { IPersonalDetails } from '../../constants';
import { AuthButtonComponent } from '../../components/auth-button/auth-button.component';
import { CountryInput } from 'components/country-input/country-input';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { FormItemPhoneNumber } from 'components/form/form-item-phone-number/form-item-phone-number';
import { PasswordField } from 'components/password-field/password-field';
import { useCountryOptions } from 'hooks/useCountryOptions';

interface IPersonalDetailsProps {
  initialValues?: IPersonalDetails;
  submittedValues: Function;
  handleNextButton: Function;
  onDentisEmailChange: (email: string) => void;
}

export const PersonalDetails: FC<IPersonalDetailsProps> = ({
  initialValues,
  submittedValues,
  handleNextButton,
  onDentisEmailChange,
}) => {
  const [form] = Form.useForm();
  const { getCountryInfoFromCode } = useCountryOptions();

  const onFinish = async (values: IPersonalDetails) => {
    submittedValues('personalDetails', values);
    handleNextButton();
  };

  const onFinishFailed = ({ errorFields }: any) => {
    form.scrollToField(errorFields[0].name, { block: 'center' });
  };

  const handleCountryChange = (newCountry: string) => {
    const mobile = form.getFieldValue('phone');
    if (getCountryInfoFromCode && (!mobile?.ext || !mobile.number)) {
      const newExt = getCountryInfoFromCode(newCountry)?.ext;
      if (newExt) form.setFieldsValue({ phone: { ext: newExt } });
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      layout="vertical"
      size="large"
      autoComplete="off"
      className="mt-4 animate-fadeIn"
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="firstName"
            label="First Name*"
            rules={[
              {
                required: true,
                message: 'Please tell us your name so we know what to call you',
              },
            ]}
          >
            <Input className="custom-input" placeholder="John" />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="lastName"
            label="Last Name*"
            rules={[
              {
                required: true,
                message: 'We need your last name as well please',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Doe" />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="country"
            label="What country do you work in?*"
            rules={[
              {
                required: true,
                message: 'Please tell us where you work',
              },
            ]}
          >
            <CountryInput className="custom-input" placeholder="Select your country" onChange={handleCountryChange} />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemPhoneNumber
            form={form}
            label="What's your phone number? (Personal)*"
            name="phone"
            isRequired
            inputClassName="custom-input"
            numberPlaceholder="Enter mobile number"
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="email"
            label="Email Address*"
            rules={[
              {
                required: true,
                message: 'Please enter your email address',
              },
              { type: 'email', message: 'Please enter your full email address' },
            ]}
            normalize={(value) => value?.toLowerCase()}
          >
            <Input
              className="custom-input"
              onChange={(e) => onDentisEmailChange(e.target.value)}
              placeholder="john.doe@gmail.com"
            />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <PasswordField form={form} />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="confirmPassword"
            label="Confirm Password*"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please enter the same password again, just to be sure',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Sorry, those passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password className="custom-input" placeholder="Enter confirm password" />
          </FormItemControl>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="referredCode"
            label="Did someone tell you about us and give you a referral code?"
          >
            <Input className="custom-input" placeholder="Enter referral code if you have one" />
          </FormItemControl>
        </div>
      </div>
      <div className="steps-action">
        <Form.Item>
          <AuthButtonComponent>Next</AuthButtonComponent>
        </Form.Item>
      </div>
    </Form>
  );
};

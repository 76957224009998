import { FC, useEffect } from 'react';
import { Form, FormInstance, FormItemProps } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import classNames from 'classnames';

export interface IFormItemControlProps extends FormItemProps {
  name?: NamePath;
  form?: FormInstance<any>;
  formListName?: string;
  visible?: (formInstance: FormInstance) => boolean; // to trigger this feature, have to pass `shouldUpdate` OR `dependencies` prop as well
  disableError?: boolean;
}

export const FormItemControl: FC<IFormItemControlProps> = ({
  name,
  isListField,
  children,
  formListName,
  visible,
  disableError,
  validateTrigger,
  className,
  ...propsRest
}) => {
  const combinedName = formListName ? [formListName, ...((name as Array<any>) || [])] : name ?? '';
  const form = Form.useFormInstance();
  const watchValue = Form.useWatch(combinedName, form);

  useEffect(() => {
    if (isListField && !formListName) {
      console.error('Warning: Need to provide [formListName] property for FormItemControl component!!');
    }
  }, []);

  useEffect(() => {
    if (form.getFieldError(combinedName).length > 0) {
      form.validateFields([combinedName]);
    }
  }, [watchValue]);

  if (visible)
    return (
      <Form.Item noStyle shouldUpdate={propsRest.shouldUpdate} dependencies={propsRest.dependencies}>
        {(formInstance: FormInstance) => (visible(formInstance) ? children : null)}
      </Form.Item>
    );

  return (
    <Form.Item
      name={name}
      isListField={isListField}
      {...propsRest}
      validateTrigger={validateTrigger || ['onSubmit', 'onBlur']}
      validateStatus={disableError ? 'success' : undefined}
      help={disableError ? '' : undefined}
      className={classNames('mb-0', className)}
    >
      {children}
    </Form.Item>
  );
};

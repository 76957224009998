import { FC } from 'react';
import { Alert, Button } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { WhatIsNewModal } from '../../../../what-is-new-modal/what-is-new-modal';

interface IWhatIsNewAlertProps {
  toggleNotifications?: () => void;
}

export const WhatIsNewAlert: FC<IWhatIsNewAlertProps> = ({ toggleNotifications }) => {
  const onWhatIsNewClick = () => {
    toggleNotifications?.();
    NiceModal.show(WhatIsNewModal);
  };

  return (
    <Alert
      type="info"
      message={
        <span className="text-xs">
          Click{' '}
          <Button type="link" className="p-0 inline" onClick={onWhatIsNewClick}>
            here
          </Button>{' '}
          to see What's New in the 32Co Platform
        </span>
      }
    />
  );
};

import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from 'services/api';
import { patientsApi } from 'services/patients-api/endpoints';
import authReducer from '../modules/auth/authSlice';
import appSlice from './appSlice';
import caseReducer from './caseSlice';
import eduContentReducer from 'components/edu-content/edu-content-list/eduContentSlice';
import socketReducer from './socketSlice';
import notificationsReducer from './notificationsSlice';
import chatReducer from './chatSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  [patientsApi.reducerPath]: patientsApi.reducer,
  auth: authReducer,
  app: appSlice,
  submission: caseReducer,
  eduContent: eduContentReducer,
  socket: socketReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
});

export const middlewares = [api.middleware];

export default persistReducer(persistConfig, reducers);

import { Card, Spin, Typography } from 'antd';
import { useQuery } from '../../../hooks/useQuery';
import { useGetPatientCheckingsQuery } from '../../../services/patients-api/endpoints';
import { SubmitTreatmentCheckInForm } from './submit-treatment-check-ins-form/submit-treatment-check-ins-form';
import midLogoSvg from 'assets/images/auth/mid-logo.svg';

const { Text } = Typography;

const PatientTreatmentCheckIn = () => {
  const query = useQuery();
  const token = query.get('token');
  const checkInNumber = query.get('checkInNumber');
  const { data, isLoading, isError } = useGetPatientCheckingsQuery(token!, {
    skip: !token,
    refetchOnMountOrArgChange: true,
  });

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <div>
          <Spin />
        </div>
        <div>
          <Text>Loading...</Text>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="success">
        <Card style={{ maxWidth: 500 }} bordered={false} className="mx-4">
          <div className="text-center my-4">
            <img src={midLogoSvg} alt="logo" className="mx-auto" />
          </div>
          <div className="success-message-container mt-6">
            <span className="title text-center text-[#102e51]">You’ve already checked in using this link</span>
            <div className="half-border" />
            <p className="mt-3 expired-content">
              We generate a unique link for every treatment check in. Please check your email or text messages to use
              the latest link
            </p>
          </div>
        </Card>
      </div>
    );
  }

  if (data && !isLoading && checkInNumber && token) {
    return <SubmitTreatmentCheckInForm token={token} checkInNumber={checkInNumber} checkInData={data} />;
  }

  return null;
};

export default PatientTreatmentCheckIn;

import { api, VERSION } from '../api';
import { IOnboardingQuestionType, IOnboardingAnswerRequestType } from './types';

export const onboardingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOnboardingQuestions: build.query<{ onBoardingQuestions: IOnboardingQuestionType[] }, void>({
      query: () => `/${VERSION}/user/onboardings/questions`,
    }),
    postOnboardingAnswers: build.mutation<
      void,
      { onBoardingAnswers: IOnboardingAnswerRequestType[]; isConfident?: boolean }
    >({
      query(data) {
        return {
          url: `/${VERSION}/user/onboardings/submit-answer`,
          method: 'POST',
          body: data,
        };
      },
    }),
    resendVerifyEmail: build.mutation<{ message: string }, void>({
      query: () => ({
        url: `/${VERSION}/user/onboardings/verify-email/resend`,
        method: 'POST',
      }),
    }),
    unlockAccount: build.mutation<{ message: string }, void>({
      query: () => ({
        url: `/${VERSION}/user/account/unlock`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetOnboardingQuestionsQuery,
  usePostOnboardingAnswersMutation,
  useResendVerifyEmailMutation,
  useUnlockAccountMutation,
} = onboardingApi;

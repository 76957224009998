import { Button, Popover, Tooltip } from 'antd';
import { memo, SyntheticEvent, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { EllipsisOutlined, CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { dateToDuration, parseNotificationData } from './utils';
import { TNotificationItem } from 'services/notification/types';

const DIV = styled.div`
  &:hover {
    background: #e5f0ff;
  }
`;

type TNotificationItemProps = {
  notification: TNotificationItem;
  markNotificationsAs: (notisItem: TNotificationItem, isViewedTo: boolean) => Promise<void>;
};

export const NotificationItem = memo(({ notification, markNotificationsAs }: TNotificationItemProps) => {
  const { user } = useAuth();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [isMarkAsOpen, setIsMarkAsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsSubscribed(true);
    return () => setIsSubscribed(false);
  }, []);

  const handleClick = async (n: any, path?: string) => {
    if (isSubscribed) {
      await markNotificationsAs(n, true);

      if (path) {
        window.location.href = path;
      }
    }
  };

  const handleAction = async (e: SyntheticEvent<HTMLElement>) => {
    if (isSubscribed) {
      e.stopPropagation();
      await markNotificationsAs(notification, !notification.isViewed);
      setIsMarkAsOpen(false);
    }
  };

  const { svgIcon, actionText, actionUrl } = parseNotificationData(notification, user?.type);

  return (
    <Tooltip title={actionText}>
      <DIV className="flex cursor-pointer p-4 " onClick={() => handleClick(notification, actionUrl)}>
        <div className="flex-none">
          <div className="flex items-center justify-center mr-4">{svgIcon}</div>
        </div>
        <div className="grow w-full">
          <div className="notification-list-header flex justify-between items-center w-full">
            <span
              className="text-base w-3/4"
              style={{ wordBreak: 'break-word', fontWeight: notification.isViewed ? '400' : '700' }}
            >
              {notification.title}
            </span>
            <span className="text-xs text-body">{dateToDuration(notification.createdAt)}</span>
          </div>
          <div className="mt-2 flex items-end">
            <div
              className="text-xs w-full"
              style={{ wordBreak: 'break-word' }}
              dangerouslySetInnerHTML={{ __html: notification.message }}
            />

            <Popover
              placement="bottomLeft"
              trigger="click"
              open={isMarkAsOpen}
              onOpenChange={setIsMarkAsOpen}
              content={
                <Button icon={<CheckOutlined />} className="text-[#DA3A6D]" onClick={handleAction}>
                  {notification.isViewed ? 'Mark as unread' : `Mark as read`}
                </Button>
              }
            >
              <Button icon={<EllipsisOutlined />} onClick={(e) => e.stopPropagation()} style={{ height: 28 }} />
            </Popover>
          </div>
        </div>
      </DIV>
    </Tooltip>
  );
});

import { createContext, useContext } from 'react';
import { TTreatmentQuoteTableColumn } from './types/TreatmentQuoteTableColumnType';
import { ITreatmentQuoteInfo } from 'services/patient-proposal-api/types';

export interface ITreatmentQuoteTableContext {
  data: ITreatmentQuoteInfo[];
  columns: TTreatmentQuoteTableColumn[];
}

export const TreatmentQuoteTableContext = createContext<ITreatmentQuoteTableContext | null>(null);

export const useTreatmentQuoteTableContext = (): ITreatmentQuoteTableContext => {
  const ctx = useContext(TreatmentQuoteTableContext);

  if (!ctx) {
    throw new Error('TreatmentQuoteTableContext is not provided');
  }

  return ctx;
};

import { useEffect, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { WhatIsNewModal } from 'components/what-is-new-modal/what-is-new-modal';
import { updateUserData } from '../modules/auth/authSlice';
import { notificationDesSafe } from 'utils/ui';
import { useAuth } from './useAuth';
import { useUpdateUserProfileMutation } from 'services/api';
import { useAppDispatch } from './store';

export const useWhatIsNew = ({ disable = false } = {}): boolean => {
  const [isShown, setIsShown] = useState(false);
  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const [updateUserProfile] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (disable) {
      return;
    }
    if (user?.newRelease === true) {
      setIsShown(true);
      NiceModal.show(WhatIsNewModal, {
        onClose: async () => {
          await updateUserProfile({
            newRelease: false,
          })
            .unwrap()
            .then(() => {
              dispatch(
                updateUserData({
                  ...user,
                  newRelease: false,
                }),
              );
            })
            .catch((error) => {
              error?.data?.errors?.forEach((err: any) => {
                notificationDesSafe.error({
                  message: 'Error',
                  description: err.message,
                  placement: 'topRight',
                });
              });
            })
            .finally(() => {
              setIsShown(false);
            });
        },
      });
    }
  }, [user?.newRelease, disable]);

  return isShown;
};

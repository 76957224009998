import packageJson from '../../package.json';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_IPINFO_TOKEN,
  REACT_APP_CALENDLY_API_TOKEN,
  REACT_APP_ENV,
} = process.env;

export const GDP_SIGNUP_FORM_STEPS = 4;
export const DESIGNER_SIGNUP_FORM_STEPS = 2;
export const SPECIALIST_SIGNUP_FORM_STEPS = 2;
export const IPINFO_TOKEN = REACT_APP_IPINFO_TOKEN;
export const CALENDLY_API_TOKEN = REACT_APP_CALENDLY_API_TOKEN;
export const APP_ENV = REACT_APP_ENV || 'dev';
export const APP_VERSION = packageJson.version;
export const USER_ROLES = {
  DENTIST: 'dentist',
  DESIGNER: 'designer',
  SPECIALIST: 'specialist',
  MANUFACTURER: 'manufacturer',
  ADMIN: 'admin',
} as const;

type TUserKeys = keyof typeof USER_ROLES;
export type TUserType = (typeof USER_ROLES)[TUserKeys];

export const FIREBASE_CONFIG = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
};

export const REACT_TOUR_START_ONBOARDING = 'reactour-start-onboarding';

export enum SUBMISSION_SERVICE_TYPE {
  DUO = 'DUO',
  SOLO = 'SOLO',
}

export enum SUBMISSION_STATUS_TYPE {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  AWAITING_FILES = 'AWAITING_FILES',
  AWAITING_DESIGNER_ACCEPTANCE = 'AWAITING_DESIGNER_ACCEPTANCE',
  AWAITING_STL_VERIFICATION = 'AWAITING_STL_VERIFICATION',
  AWAITING_SPECIALIST_ACCEPTANCE = 'AWAITING_SPECIALIST_ACCEPTANCE',
  AWAITING_INSTRUCTION = 'AWAITING_INSTRUCTION',
  ON_HOLD = 'ON_HOLD',
  NOT_SUITABLE_PENDING = 'NOT_SUITABLE_PENDING',
  AWAITING_DESIGN = 'AWAITING_DESIGN',
  AWAITING_ADVICE = 'AWAITING_ADVICE',
  REVIEW_DESIGN = 'REVIEW_DESIGN',
  AWAITING_DENTIST_APPROVAL = 'AWAITING_DENTIST_APPROVAL',
  APPROVED = 'APPROVED',
  REVIEW_STL_FILES = 'REVIEW_STL_FILES',
  WITH_MANUFACTURER = 'WITH_MANUFACTURER',
  WITH_32CO = 'WITH_32CO',
  IN_TREATMENT = 'IN_TREATMENT',
  NOT_SUITABLE = 'NOT_SUITABLE',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}

export const API_STATUS_CODES = {
  BAD_REQUEST: 400,
  SUCCESS: 200,
  FORBIDDEN: 403,
};

import { FC, useMemo } from 'react';
import { IOnboardingAnswerRequestType, IOnboardingQuestionType } from 'services/onboarding-api/types';
import DrAmaPng from 'assets/images/tell-us-flow/dr-ama.png';
import { Col, Input, Row } from 'antd';
import { TellUsOptionItem } from './tell-us-option-item';

export const TELL_US_OTHER_OPTION = 'Other';

export const TellUsQuestion: FC<{
  question: IOnboardingQuestionType;
  selectedAnswer: IOnboardingAnswerRequestType;
  onAnswer: (newAnswer: IOnboardingAnswerRequestType) => void;
}> = (props) => {
  const {
    question: { description, title, answers },
    selectedAnswer,
    onAnswer,
  } = props;
  const handleAnswerChange = (answerId: string) => {
    onAnswer({
      ...(selectedAnswer || {}),
      onBoardingAnswer: answerId,
    });
  };

  const handleNotesChange = (e: any) => {
    onAnswer({
      ...(selectedAnswer || {}),
      notes: e.target.value,
    });
  };

  const hasOtherOption = useMemo(
    () => answers.findIndex((answer) => answer.title === TELL_US_OTHER_OPTION) >= 0,
    [answers],
  );

  return (
    <div className="question-container">
      <div className="flex">
        <img src={DrAmaPng} height="52" width="52" alt="" />
        <div className="ml-2">
          <p className="text-xl mb-1">
            <b>{title}</b>
          </p>
          <span style={{ color: '#999' }}>{description}</span>
        </div>
      </div>
      <br />
      <div>
        <Row gutter={[18, 18]}>
          {answers.map((option) => (
            <Col span={24} md={12} key={option.id}>
              <TellUsOptionItem
                option={option.title}
                onClick={() => handleAnswerChange(option.id)}
                isSelected={selectedAnswer?.onBoardingAnswer === option.id}
              />
            </Col>
          ))}
        </Row>
        <Input.TextArea
          onChange={handleNotesChange}
          placeholder={
            hasOtherOption
              ? 'If other, please tell us in your own words. Feel free to tell us more anyway.'
              : 'Feel free to tell us more.'
          }
          style={{ borderRadius: 8, padding: 15, marginTop: 18 }}
        />
      </div>
    </div>
  );
};

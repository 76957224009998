/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TRootState } from 'redux/store';
import type { IEducationFilter } from './content-filter/content-filter';

type TEduContentState = {
  page: number | undefined;
  perPage: number | undefined;
  filter: IEducationFilter;
};

const slice = createSlice({
  name: 'eduContent',
  initialState: { page: 1, perPage: 10, filter: {} } as TEduContentState,
  reducers: {
    setEduContentPagination: (
      state,
      {
        payload: { page, perPage },
      }: PayloadAction<{
        page: number | undefined;
        perPage: number | undefined;
      }>,
    ) => {
      state.page = page;
      state.perPage = perPage;
    },
    setEduContentFilter: (state, { payload }: PayloadAction<IEducationFilter>) => {
      state.filter = { ...state.filter, ...payload };
      state.page = 1;
    },
  },
});

export const { setEduContentPagination, setEduContentFilter } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: TRootState) => state.eduContent.page;
export const selectPageSize = (state: TRootState) => state.eduContent.perPage;
export const selectFilter = (state: TRootState) => state.eduContent.filter;

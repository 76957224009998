import { FC, useEffect, useState } from 'react';
import { Button, Drawer, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import notifySvg from 'assets/images/dashboard/notify.svg';
import { WhatIsNewAlert } from './what-is-new-alert/what-is-new-alert';
import { NotificationsContainer, UNREAD_NOTIS_CONTAINER_ID } from './notifications-container/notifications-container';
import { usePrimaryNotification } from './hooks/use-primary-notification';
import { TNotisType } from 'redux/notificationsSlice';
import { toogleBodyElmScroll } from 'utils/ui';

const items: TabsProps['items'] = [
  {
    key: 'unread',
    label: `Unread`,
    children: <NotificationsContainer type="unread" />,
  },
  {
    key: 'all',
    label: `All`,
    children: <NotificationsContainer type="all" />,
  },
  {
    key: 'read',
    label: `Read`,
    children: <NotificationsContainer type="read" />,
  },
];

type TPrimaryNotificationProps = {
  showNotifications: boolean;
  onSetShowNotifications: (b: boolean) => void;
};

export const PrimaryNotification: FC<TPrimaryNotificationProps> = ({ showNotifications, onSetShowNotifications }) => {
  const [selectedTab, setSelectedTab] = useState<TNotisType>('unread');
  const { unreadNotificationsCount, markAllNotificationsAsRead } = usePrimaryNotification();

  const closeNotifications = () => {
    onSetShowNotifications(false);
  };

  const handleGotoUnread = () => {
    setSelectedTab('unread');
    setTimeout(() => document.getElementById(UNREAD_NOTIS_CONTAINER_ID)?.scrollTo(0, 0), 0);
  };

  useEffect(() => {
    toogleBodyElmScroll(!showNotifications);
  }, [showNotifications]);

  return (
    <div>
      <div onClick={() => onSetShowNotifications(true)}>
        <div className="w-6 h-6 flex">
          <img src={notifySvg} alt="notifySvg" height={24} />
        </div>
        {unreadNotificationsCount > 0 && (
          <span
            className="flex absolute h-2 w-2 top-0 right-0"
            style={{
              marginRight: 3,
              marginTop: -1,
            }}
          >
            <span className="animate-pingSlow absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75" />
            <span className="relative inline-flex rounded-full h-2 w-2 bg-rose-500 " />
          </span>
        )}
      </div>
      <Drawer
        placement="right"
        closable={false}
        onClose={closeNotifications}
        visible={showNotifications}
        contentWrapperStyle={{
          width: '380px',
          maxWidth: '90vw',
        }}
        footer={<WhatIsNewAlert toggleNotifications={closeNotifications} />}
        bodyStyle={{ padding: 0, overflow: 'hidden' }}
      >
        <div className="flex justify-between px-4 py-6 absolute z-10 bg-white w-full">
          <div className="flex items-center">
            <span className="text-head">Notifications</span>
            <span className="relative inline-flex ml-2 cursor-pointer" onClick={handleGotoUnread}>
              <div className="flex justify-center items-center px-4 py-1 bg-[#09363D] rounded-md">
                <span className="text-sm text-white font-semibold">{unreadNotificationsCount}</span>
              </div>
              {unreadNotificationsCount > 0 && (
                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span className="animate-pingSlow absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75" />
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-rose-500 " />
                </span>
              )}
            </span>
          </div>
        </div>

        <Tabs
          size="large"
          defaultActiveKey={selectedTab}
          activeKey={selectedTab}
          onChange={setSelectedTab as any}
          tabBarStyle={{
            padding: '0 16px',
            marginBottom: '0px',
            position: 'absolute',
            top: '58px',
            width: '100%',
            zIndex: 4,
            backgroundColor: 'white',
          }}
          tabBarExtraContent={
            unreadNotificationsCount > 0 && selectedTab !== 'read' ? (
              <Button type="link" id="mark-all-as-read" size="small" onClick={markAllNotificationsAsRead}>
                Mark all as read
              </Button>
            ) : null
          }
          items={items}
        />
      </Drawer>
    </div>
  );
};

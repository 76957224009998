import { IThankyouMessage } from 'modules/specialist/dashboard/components/thank-you-message-panel/thank-you-modal/thank-you-modal';
import { api, VERSION, formatUrl } from '../api';
import { IPerformanceResponse } from './types';

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getThankYouMessageForSpecialist: build.query<any, any>({
      query: ({ page, perPage }) => {
        let paramsString = '';
        if (page) paramsString += `page=${page}`;
        if (perPage) paramsString += `&perPage=${perPage}`;
        return formatUrl(`/dashboard/thankyou-messages?${paramsString}`);
      },
    }),
    getThankYouMessageByCase: build.query<IThankyouMessage, any>({
      query: (submissionId) => formatUrl(`/dashboard/messages?submission=${submissionId}`),
    }),
    updateLastActiveHubspot: build.mutation<any, any>({
      query() {
        return {
          url: `/${VERSION}/user/profiles/last-active`,
          method: 'PUT',
          body: {},
        };
      },
    }),
    getAnalyticsReferralData: build.query<any, any>({
      query: () => formatUrl(`/analytics/referral`),
    }),
    getSpecialistPerformanceData: build.query<IPerformanceResponse, any>({
      query: () => formatUrl('/dashboard/performance'),
    }),
  }),
});

export const {
  useGetThankYouMessageForSpecialistQuery,
  useGetThankYouMessageByCaseQuery,
  useUpdateLastActiveHubspotMutation,
  useGetAnalyticsReferralDataQuery,
  useGetSpecialistPerformanceDataQuery,
} = dashboardApi;

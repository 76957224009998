import { FC } from 'react';
import { Form, Input } from 'antd';
import { IDesignerPersonalDetails } from '../constants';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';
import { NiceDatePicker } from 'components/commons/nice-date-picker';
import moment from 'moment';
import { FormItemPhoneNumber } from 'components/form/form-item-phone-number/form-item-phone-number';
import { CountryInput } from 'components/country-input/country-input';
import { AddressField } from 'components/address-field/address-field';
import { PasswordField } from 'components/password-field/password-field';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { useCountryOptions } from 'hooks/useCountryOptions';

interface IDesignerPersonalDetailsComponentProps {
  initialValues?: IDesignerPersonalDetails;
  submittedValues: Function;
  handleNextButton: Function;
}

const PersonalDetails: FC<IDesignerPersonalDetailsComponentProps> = ({
  initialValues,
  submittedValues,
  handleNextButton,
}) => {
  const [form] = Form.useForm();
  const businessAddress = Form.useWatch(['business', 'address'], form);
  const { getCountryInfoFromCode } = useCountryOptions();

  const onFinish = (values: IDesignerPersonalDetails) => {
    submittedValues('personalDetails', { ...values });
    handleNextButton();
  };

  const onFinishFailed = ({ errorFields }: any) => {
    form.scrollToField(errorFields[0].name, { block: 'center' });
  };

  const handleCountryChange = (newCountry: string) => {
    const mobile = form.getFieldValue('phone');
    if (getCountryInfoFromCode && (!mobile?.ext || !mobile.number)) {
      const newExt = getCountryInfoFromCode(newCountry)?.ext;
      if (newExt) form.setFieldsValue({ phone: { ext: newExt } });
    }
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      layout="vertical"
      size="large"
      autoComplete="off"
      className="mt-4 animate-fadeIn"
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1">
          <FormItemControl
            name="firstName"
            label="First Name *"
            rules={[
              {
                required: true,
                message: 'Please input first name',
              },
            ]}
          >
            <Input className="custom-input" placeholder="John" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl
            name="lastName"
            label="Last Name *"
            // hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input last name',
              },
            ]}
          >
            <Input className="custom-input" placeholder="Doe" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl
            name="dob"
            label="Date of birth *"
            rules={[
              {
                required: true,
                message: 'Please input date of birth',
              },
            ]}
          >
            <NiceDatePicker disabledDate={(current) => current && current > moment()} className="custom-input w-full" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl
            name="email"
            label="Email Address *"
            rules={[
              {
                required: true,
                message: 'Please enter your email address',
              },
              { type: 'email', message: 'Please enter your full email address' },
            ]}
            normalize={(fieldValue) => fieldValue?.toLowerCase()}
          >
            <Input className="custom-input" placeholder="Enter your email address" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name="country" label="Country">
            <CountryInput className="custom-input" placeholder="Select your country" onChange={handleCountryChange} />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemPhoneNumber
            form={form}
            label="Mobile Number *"
            name="phone"
            isRequired
            inputClassName="custom-input"
            numberPlaceholder="Enter your mobile number"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <PasswordField form={form} />
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl
            name="confirmPassword"
            label="Confirm Password *"
            dependencies={['password']}
            // hasFeedback
            rules={[
              {
                required: true,
                message: 'Please input confirm password',
              },
              ({ getFieldValue }) => ({
                validator(_, fieldValue) {
                  if (!fieldValue || getFieldValue('password') === fieldValue) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password className="custom-input" placeholder="Enter Confirm Password" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['business', 'name']} label="Design Lab Name">
            <Input className="custom-input" placeholder="Enter Design Lab Name" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <AddressField
            name={['business', 'address', 'address1']}
            label="Business Address *"
            rules={[{ required: true, message: 'Please enter business address' }]}
            placeholder="Enter clinic address"
            onFormSetFieldsValueAddress={(address) => {
              form.setFieldValue(['business', 'address'], { ...businessAddress, ...address });
            }}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['business', 'address', 'postCode']} label="Business Postcode">
            <Input className="custom-input" placeholder="Enter your postcode" />
          </FormItemControl>
          <FormItemControl name={['business', 'address', 'country']} hidden />
          <FormItemControl name={['business', 'address', 'coordinates']} hidden />
        </div>
      </div>

      <div className="steps-action">
        <Form.Item>
          <AuthButtonComponent>Next</AuthButtonComponent>
        </Form.Item>
      </div>
    </Form>
  );
};

export { PersonalDetails };

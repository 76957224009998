import { FC, useMemo } from 'react';
import { PreviewProposalComponent } from 'modules/gdp/patients/proposal/components/preview-proposal/preview-proposal.component';
import { initialData } from 'modules/gdp/patients/proposal/consts/initialData';
import styles from './view-proposal.module.less';
import { useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import {
  useBookProposalAsPatientMutation,
  useGetProposalAsPatientQuery,
} from 'services/patient-proposal-api/endpoints';
import { ITreatmentProposalForm } from 'modules/gdp/patients/proposal/types/ITreatmentProposalForm';
import { PageLoader } from 'components/page-loader/page-loader.component';
import { notification, Result } from 'antd';
import { notificationApiError } from 'utils/ui';

export const ViewProposal: FC = () => {
  const token = useQuery().get('token')!;
  const { proposalId } = useParams<{ proposalId: string }>();

  const [
    bookProposal,
    {
      isLoading: isProposalBooking, // TODO handle loading case
      // isError: isBookingError,
      isSuccess: isBookingSuccess,
    },
  ] = useBookProposalAsPatientMutation();

  const {
    data: patientProposal,
    isLoading: isPatientProposalLoading,
    isError: isPatientProposalLoadingError,
  } = useGetProposalAsPatientQuery({
    proposalId,
    token,
  });

  const hideBookButton = isBookingSuccess || patientProposal?.status === 'BOOKED';

  const mappedProposalData: ITreatmentProposalForm = useMemo(() => {
    if (isPatientProposalLoading) {
      return initialData;
    }

    return {
      dentist: {
        name: `${patientProposal?.dentist?.firstName} ${patientProposal?.dentist?.lastName}`,
        profileUrl: patientProposal?.dentist?.profilePic!,
        location: patientProposal?.submission?.practice?.address?.address1,
        practiceName: patientProposal?.submission?.practice?.name,
      },
      patientName: patientProposal?.patientName!,
      duration: patientProposal?.duration!,
      numberofAligner: patientProposal?.numberofAligner!,
      email: patientProposal?.email!,
      treatmentGoals: patientProposal?.treatmentGoals!,
      assessmentImages: patientProposal?.assessmentImages ?? [],
      quotes: patientProposal?.quotes ?? [],
      mobile: patientProposal?.mobile,
      toothMovementURL: patientProposal?.toothMovementURL,
      selectedQuotation: patientProposal?.selectedQuotation,
    } as ITreatmentProposalForm;
  }, [patientProposal, isPatientProposalLoading]);

  const onBook = async ({ message, quote }: { message: string; quote: string }) => {
    let quoteToUse = quote;
    if (!quote && mappedProposalData.quotes.length === 1) {
      quoteToUse = mappedProposalData.quotes[0].id!;
    }

    try {
      await bookProposal({
        proposalId,
        quote: quoteToUse,
        message,
      }).unwrap();

      notification.success({
        message: 'Sent Successfully!',
      });
    } catch (error) {
      notificationApiError(error);
    }
  };

  if (isPatientProposalLoadingError) {
    return (
      <Result
        status="500"
        title="Error"
        subTitle="Sorry, something went wrong."
        extra={<a href="mailto:support@32co.com">support@32co.com</a>}
      />
    );
  }

  if (isPatientProposalLoading) {
    return <PageLoader />;
  }

  return (
    <div className={styles.viewProposal}>
      <div className={styles.viewProposalContainer}>
        <PreviewProposalComponent
          proposalData={mappedProposalData}
          onSubmit={onBook}
          hideBookButton={hideBookButton}
          showSubmitButtonLoader={isProposalBooking}
          isFullSizePage
        />
      </div>
    </div>
  );
};

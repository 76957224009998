import { Avatar } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import Ceren from 'assets/images/auth/ceren.png';
import { AuthButtonComponent } from '../../components/auth-button/auth-button.component';

const DIV = styled.div`
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  margin-top: -40px;
`;

interface IGreetingProps {
  onNext: () => void;
}
export const Greeting: FC<IGreetingProps> = ({ onNext }) => (
  <DIV>
    <div className="animate-fadeIn flex flex-col lg:flex-row">
      <div style={{ width: 184 }} className="m-auto md:m-0 md:mr-16">
        <Avatar size={184} className="mt-8 mb-6" src={Ceren} />
      </div>
      <div className="font-medium text-center md:text-left">
        <div className="text-3xl font-bold mb-6 text-darkBlueColor">Hi 👋, Welcome to 32Co</div>
        <div className="text-base font-semibold mb-8" style={{ color: '#2d2d2d' }}>
          I’m Dr. Ceren, one of the many dentists that work for 32Co. It’s time to get you providing Clear Aligners
          differently.
        </div>
        <p className="text-sm mb-6" style={{ color: '#333' }}>
          Get started by telling us a little bit about yourself to gain instant access to the platform and our
          educational content. Don’t worry it only takes 2 minutes
        </p>
        <p className="text-sm mb-6" style={{ color: '#333' }}>
          If you get stuck along the way contact us at{' '}
          <a href="mailto:support@32co.com">
            <b className="c-black">support@32co.com</b>
          </a>
          , we’re always here to help.
        </p>
        <div className="flex justify-center lg:justify-start">
          <AuthButtonComponent onClick={onNext}>Get Started</AuthButtonComponent>
        </div>
      </div>
    </div>
  </DIV>
);

import { Typography, Form, Input, InputNumber, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useSubmitPatientSubmissionMutation } from 'services/patients-api/endpoints';
import { IImage, TVerifyPatientTokenResponse } from 'services/patients-api/types';
import { formatSaveImage } from 'helpers';
import { notificationDefaultError, ZINDEX } from 'utils/ui';
import reportPng from 'assets/images/patient-information/report.png';
import './submit-treatment-check-ins-form.less';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import { ImageDragger } from 'modules/gdp/case-submission/components/file-upload-form/panel-items/image-dragger/image-dragger';
import { NiceRadioGroup } from 'components/commons/nice-radio-group/nice-radio-group';
import { YESNO } from 'modules/gdp/case-submission/utils';
import { useWatch } from 'antd/lib/form/Form';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { PreviewImageGroup } from 'modules/gdp/case-submission/components/preview-image-group/preview-image-group';

const { TextArea } = Input;
const { Text } = Typography;

type TProps = {
  token: string;
  checkInNumber: string;
  checkInData: TVerifyPatientTokenResponse;
};

export const SubmitTreatmentCheckInForm: FC<TProps> = ({ token, checkInNumber, checkInData }) => {
  const [form] = Form.useForm();
  const [submitPatientSubmission, { isLoading }] = useSubmitPatientSubmissionMutation();
  const history = useHistory();
  const havingConcerns = useWatch('havingConcerns', form);
  const images: IImage[] = useWatch('images', form);

  const onFinish = async (values: any) => {
    const reqData = {
      ...values,
      images: formatSaveImage(values.images),
    };
    try {
      const res: any = await submitPatientSubmission(reqData);
      if (res && res.data && res.data.id) {
        history.push('/patients/check-in/success', { replace: true });
      } else {
        notificationDefaultError();
      }
    } catch (e) {
      notificationDefaultError();
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      password: token,
      checkInNumber: parseInt(checkInNumber, 10),
      patientChecking: checkInData.id,
    });
  }, []);

  const onFinishFailed = ({ errorFields }: any) => {
    form.scrollToField(errorFields[0].name, { block: 'center' });
  };

  const onDeleteImage = (url: string, name: string) => {
    const filterImages = images?.filter((item) => item.url !== url);
    form.setFieldsValue({
      [name]: filterImages,
    });
  };

  return (
    <div className="check-in-container p-4 mt-4">
      <div className="flex items-center mt-4 mb-4 md:mt-7 md:mb-6">
        <img
          src={reportPng}
          alt="reportPng"
          style={{
            height: '60px',
            filter: 'drop-shadow(0px 7px 4px #dddddd)',
          }}
        />
        <div className="pl-3 text-base font-semibold md:text-lg">Time to Check in!</div>
      </div>
      <div className="pb-2 mb-2">
        <div className="text-sm">
          <strong>Clear Aligner Treatment Check in </strong>
        </div>
        <div>
          <Text className="text-body">{checkInData.patient?.fullName}</Text>
        </div>
      </div>
      <div className="border-t border-gray-100 pt-1">
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          size="large"
          className="mt-3"
        >
          <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-12 sm:col-span-12 md:col-span-6 sm:pt-0">
              <div className="flex flex-col">
                <Text className="text-head">Name</Text>
                <Text className="text-body">{checkInData.patient?.fullName}</Text>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6 sm:pt-0">
              <div className="flex flex-col">
                <Text className="text-head">Date</Text>
                <Text className="text-body">{moment().format('DD/MM/YYYY')}</Text>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6 sm:pt-0">
              <div className="flex flex-col">
                <Text className="text-head mb-4">Aligner stage*</Text>
                <FormItemControl
                  name="alignerStage"
                  rules={[
                    {
                      required: true,
                      message: 'Aligner stage is required',
                    },
                  ]}
                >
                  <InputNumber className="w-full" />
                </FormItemControl>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6 sm:pt-0">
              <div className="flex flex-col">
                <Text className="text-head mb-4">How many days have you been wearing this aligner?*</Text>
                <FormItemControl
                  name="wearingDuration"
                  rules={[
                    {
                      required: true,
                      message: 'Wearing duration is required',
                    },
                  ]}
                >
                  <InputNumber className="w-full" />
                </FormItemControl>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6 sm:pt-0 treatment-checkins-radio-btn">
              <div className="flex flex-col">
                <Text className="text-head mb-4">Do you have any questions or concerns?*</Text>
                <FormItemControl
                  name="havingConcerns"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <NiceRadioGroup options={YESNO} itemStyle={{ minWidth: 80, textAlign: 'center' }} />
                </FormItemControl>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-6 sm:pt-0">
              <div className="flex flex-col">
                <Text className="text-head mb-4">
                  {havingConcerns ? 'Please detail your concerns here*' : 'My concerns or questions'}
                </Text>
                <FormItemControl
                  name="concerns"
                  rules={[
                    {
                      required: havingConcerns,
                      message: 'Please detail your concerns before submitting',
                    },
                  ]}
                >
                  <TextArea rows={3} />
                </FormItemControl>
              </div>
            </div>
            <Form.Item name="password" hidden />
            <Form.Item name="checkInNumber" hidden />
            <Form.Item name="patientChecking" hidden />
            <div className="col-span-12 sm:col-span-12 md:col-span-12 sm:pt-0">
              <div className="flex flex-col">
                <Text className="text-head">My photo update</Text>
                <div className="mt-2">
                  <span>
                    Please upload at least 3 photos whilst wearing your aligners and biting down. Please include:
                  </span>
                  <ul>
                    <li>Left</li>
                    <li>Front</li>
                    <li>Right</li>
                  </ul>
                  <span>
                    If you have any concerns or questions please try and capture close ups of the relevant area or
                    aligner in addition.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:pt-0 grid mb-4">
              <div className="col-span-12 md:col-span-6">
                <FormItemControl name="images">
                  <ImageDragger name="images" publicUpload />
                </FormItemControl>
              </div>
            </div>
            <div className="col-span-12 mt-4 mb-6">
              <Row gutter={16} className="mb-4">
                <PreviewImageGroup name="images" images={images} deleteImage={onDeleteImage} />
              </Row>
            </div>
          </div>
          <div
            className="bg-white border-0 border-solid border-t border-gray-300 pt-4"
            style={{ zIndex: ZINDEX.SUBMIT_TREATMENT_CHECKIN }}
          >
            <div className="grid grid-cols-12 gap-2 w-full">
              <div className="col-span-12 text-center">
                <NiceButton
                  id="submit-treatment-checkins"
                  htmlType="submit"
                  loading={isLoading}
                  niceType="orange"
                  className="w-32"
                  disabled={!!images?.find((img) => !img.url)}
                >
                  SUBMIT
                </NiceButton>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

import { FC, useEffect } from 'react';
import NiceAntModal from '../commons/nice-ant-modal/nice-ant-modal.component';
import { useGetLatestReleaseNotesQuery } from 'services/release-notes-api/endpoints';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useAuth } from 'hooks/useAuth';
import { NotionRenderer } from 'react-notion-x';
import { useGetNotionDataQuery } from 'services/api';
import 'react-notion-x/src/styles.css';
import './what-is-new-modal.less';
import { notification, Spin } from 'antd';

const WhatIsNewNotionReader: FC<{ contentUrl: string }> = ({ contentUrl }) => {
  const { data: recordMap, error, isLoading } = useGetNotionDataQuery(contentUrl);

  return (
    <div>
      {error && <div className="flex justify-center items-center">Content URL is invalid!</div>}
      {isLoading && (
        <div className="flex justify-center items-center" style={{ minHeight: '500px' }}>
          <Spin tip="Loading content..." />
        </div>
      )}
      {!isLoading && recordMap && <NotionRenderer recordMap={recordMap.data} fullPage darkMode={false} />}
    </div>
  );
};

interface IWhatIsNewModalProps {
  onClose?: () => void;
}

export const WhatIsNewModal = NiceModal.create<IWhatIsNewModalProps>(({ onClose }) => {
  const { user } = useAuth();
  const modal = useModal();

  const { data, isLoading, isError, error } = useGetLatestReleaseNotesQuery();

  useEffect(() => {
    if (isError) {
      modal.hide();
      modal.remove();
      onClose?.();

      notification.error({
        message: 'Release notes loading error',
      });
    }
  }, [isError, error, modal]);

  if (isLoading || isError || !data || !['all', user!.type].includes(data!.userType)) {
    return null;
  }

  return (
    <NiceAntModal
      width={700}
      modal={modal}
      onCancel={() => {
        modal.hide();
        modal.remove();
        onClose?.();
      }}
      bodyStyle={{
        maxHeight: '95vh',
        overflow: 'scroll',
      }}
    >
      <WhatIsNewNotionReader contentUrl={data.notionUrl} />
    </NiceAntModal>
  );
});

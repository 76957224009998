import { APP_ENV } from 'config/constants';
import { IUser } from 'modules/auth/services/type';
import { useEffect } from 'react';

export const usePendo = (user: IUser | null) => {
  useEffect(() => {
    if (user?.id && APP_ENV === 'prod') {
      const { id, ...restInfo } = user || {};
      (window as any).pendo?.initialize?.({
        visitor: {
          ...restInfo,
          id: user.id,
          full_name: `${user.firstName} ${user.lastName}`,
          role: user.type,
          env: APP_ENV,
        },

        account: {
          id: user.type,
          name: user.type,
        },
      });
    }
  }, [user?.id]);
};

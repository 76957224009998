import { FC } from 'react';
import styles from './assessment.module.less';
import cn from 'classnames';
import { Button, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { PopconfirmDelete } from 'components/popconfirm/popconfirm-delete/popconfirm-delete';

interface IAssessmentComponentProps {
  assessment: { url: string; type: string };
  onClose?: () => void;
  isFullSizePage: boolean;
}

export const AssessmentComponent: FC<IAssessmentComponentProps> = ({ assessment, onClose, isFullSizePage }) => (
  <div className={cn(styles.assessment)}>
    <div className={cn(styles.imgWrapper, 'flex', 'justify-center')}>
      {assessment && (
        <Image
          src={assessment.url}
          width="100%"
          style={{
            objectFit: 'cover',
          }}
          className={cn('h-28', {
            'md:h-40 lg:h-52 xl:h-64': isFullSizePage,
          })}
        />
      )}
    </div>
    {onClose && (
      <PopconfirmDelete onConfirm={onClose}>
        <Button className={cn(styles.btnRemove)} type="ghost" shape="circle" icon={<CloseOutlined />} size="small" />
      </PopconfirmDelete>
    )}
  </div>
);

import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { TNotificationItem } from 'services/notification/types';

interface IParams {
  submissionId?: string;
}

type TProps = {
  unreadNotifications?: TNotificationItem[];
  markNotificationsAs: (notis: TNotificationItem, isViewed: boolean) => Promise<void>;
};

export const useMarkNotSuitableSubmissionAsRead = ({ unreadNotifications, markNotificationsAs }: TProps): void => {
  const [isVisited, setIsVisited] = useState(false);
  const params: IParams = useParams();
  const queryTarget = useQuery();
  const notSuitableSubmissionId = useMemo(
    () => (!isVisited && queryTarget.get('target') === 'notSuitable' ? params.submissionId : ''),
    [params, isVisited],
  );
  useEffect(() => {
    if (notSuitableSubmissionId && unreadNotifications) {
      const foundUnreadNotification = unreadNotifications.find((n) => n.submission === notSuitableSubmissionId);
      if (foundUnreadNotification) markNotificationsAs(foundUnreadNotification, true);
      setIsVisited(true);
    }
  }, [notSuitableSubmissionId, unreadNotifications]);
};

import classNames from 'classnames';
import { FC } from 'react';

export const TellUsOptionItem: FC<{
  option: string;
  isSelected: boolean;
  onClick: () => void;
}> = ({ option, isSelected, onClick }) => (
  <div className={classNames('option-item', { 'is-selected': isSelected })} onClick={() => onClick()}>
    {option}
  </div>
);

import { Divider, Popover, Tooltip, Typography } from 'antd';
import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as RightArrowSVG } from 'assets/images/right_arrow.svg';
import { REACT_TOUR_START_ONBOARDING, SUBMISSION_SERVICE_TYPE, USER_ROLES } from '../../../config/constants';
import styled from 'styled-components';
import smallLogoSvg from 'assets/images/auth/small-logo.svg';
import logoSvg from 'assets/images/dashboard/logo.svg';
import { ReactComponent as MainSVG } from 'assets/images/dashboard/main.svg';
import { ReactComponent as PatientsSVG } from 'assets/images/dashboard/patients.svg';
import { ReactComponent as EducationSVG } from 'assets/images/dashboard/education.svg';
import { ReactComponent as JourneySVG } from 'assets/images/dashboard/journey.svg';
import { ReactComponent as CartSVG } from 'assets/images/dashboard/cart.svg';
import { IUser, UserStatuses } from '../../../modules/auth/services/type';
import classNames from 'classnames';
import './dashboard-layout.styles.less';

export const MENU_DURATION = '0.2s';

const ItemStyled = styled.div<{ active: boolean; smallMode: boolean; disabled: boolean }>`
  position: relative;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: 'white';
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  :hover {
    background: ${({ disabled }) => (disabled ? 'transparent' : '#1890ff5e')};
  }

  .menuBack {
    transition: width ${MENU_DURATION};
    width: ${({ active, smallMode }) => (active && !smallMode ? '140px' : '40px')};
    height: 40px;
    box-shadow: inset 0px 0.5px 4px rgb(96 97 112 / 32%);
    border-radius: 20px;
    position: absolute;
    color: ${({ disabled }) => (disabled ? 'black' : 'white')};
    background: ${({ disabled }) => (disabled ? 'rgba(225, 225, 225, 0.75)' : '#f9f9fa')};
  }

  .menuContext {
    margin-left: 11px;
    padding-top: 11px;
    z-index: 1;
    position: absolute;

    svg {
      fill: ${({ active }) => (active ? 'white' : '#050D43')};
    }
  }
`;

const NotificationDot = styled.div<{ active: boolean }>`
  position: absolute;
  top: 3px;
  left: 20px;

  width: 10px;
  height: 10px;
  border-radius: 5px;

  background-color: ${(props) => (props.active ? 'white' : 'red')};
`;

interface IMenuItemProps {
  active: boolean;
  onClick?: () => void;
  icon: React.ReactNode;
  text: string;
  smallMode: boolean;
  notification?: boolean;
  disabled?: boolean;
  tooltip?: string;
  id?: string;
}

const MenuItem: FC<IMenuItemProps> = ({
  active,
  onClick,
  icon,
  text,
  smallMode,
  notification = false,
  disabled = false,
  tooltip,
  id,
}) => (
  <ItemStyled active={active} id={id} smallMode={smallMode} onClick={disabled ? () => {} : onClick} disabled={disabled}>
    <Tooltip placement="right" title={disabled ? tooltip : null}>
      <div style={{ height: '40px' }}>
        <div className="flex align-items-center menuContext ">
          {icon}
          {!smallMode && <span className="ml-6 text-white">{text}</span>}
          {notification && <NotificationDot active={active} />}
        </div>
        <div
          className="menuBack"
          style={{ backgroundImage: active ? 'linear-gradient(27.16deg, #DA3A6D 21.81%, #DA533C 65.38%)' : 'unset' }}
        />
      </div>
    </Tooltip>
  </ItemStyled>
);

interface IDashboardMenuProps {
  user: IUser;
  activeMenu: string;
  showOnboardingWizard: boolean;
  onSetShowOnboardingWizard: (show: boolean) => void;
  isOnboardingContentVisible: boolean;
  onSetIsOnboardingContentVisible: (show: boolean) => void;
  hilightWizardPopover?: boolean;
  smallMode?: boolean;
}

export const DashboardMenu: FC<IDashboardMenuProps> = ({
  user,
  activeMenu,
  showOnboardingWizard,
  onSetShowOnboardingWizard,
  isOnboardingContentVisible,
  onSetIsOnboardingContentVisible,
  hilightWizardPopover = false,
  smallMode = false,
}) => {
  const history = useHistory();

  const onVisibleChange = (visible: boolean) => {
    if (showOnboardingWizard) {
      onSetIsOnboardingContentVisible(true);
    } else {
      setTimeout(() => {
        onSetIsOnboardingContentVisible(visible);
      }, 400);
    }
  };

  const navigateToOnboarding = (text: string) => {
    switch (text) {
      case 'duo-case':
        onSetIsOnboardingContentVisible(false);
        onSetShowOnboardingWizard(false);
        history.push(`/${user?.type}/patients/submit/${SUBMISSION_SERVICE_TYPE.DUO}?open=onboarding`);
        break;
      case 'proposal':
        onSetIsOnboardingContentVisible(false);
        onSetShowOnboardingWizard(false);
        history.push(`/${user?.type}/patients/987654321/design/987654321?open=onboarding`);
        break;
      default:
    }
  };

  const onBoardingContent = (
    <div className={classNames('w-60 p-2', REACT_TOUR_START_ONBOARDING)}>
      <Typography.Text className="font-bold text-sm">Quick Guides</Typography.Text>
      <Divider className="mt-1 mb-2" />
      <div className="mr-1">
        <Typography.Text className="text-xs leading-none text-gray-500">
          Select where you would like to start
        </Typography.Text>

        <div
          className="flex items-center justify-between mt-4 cursor-pointer"
          onClick={() => navigateToOnboarding('duo-case')}
          id="onboarding-submitting-a-duo-case-menu"
        >
          <Typography.Text className="text-xs leading-none">Submitting a Duo case</Typography.Text>
          <RightArrowSVG />
        </div>

        <div
          className="flex items-center justify-between mt-4 cursor-pointer"
          onClick={() => navigateToOnboarding('proposal')}
          id="onboarding-receiving-my-proposal-menu"
        >
          <Typography.Text className="text-xs leading-none">Receiving my proposal</Typography.Text>
          <RightArrowSVG />
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        color: 'white',
        fontWeight: 400,
        fontSize: '12px',
      }}
      id="dashboard-menu"
    >
      <div className="py-8 flex justify-center">
        <Link to="/">
          {smallMode ? <img src={smallLogoSvg} height={33} alt="logo" /> : <img src={logoSvg} alt="logo" height={33} />}
        </Link>
      </div>

      <div>
        <Link id="dashboard-menu" to={`/${user?.type || USER_ROLES.DENTIST}`}>
          <MenuItem
            smallMode={smallMode}
            active={activeMenu === (user?.type || USER_ROLES.DENTIST)}
            icon={<MainSVG fill="white" />}
            text="Dashboard"
          />
        </Link>

        {user?.type !== USER_ROLES.MANUFACTURER && (
          <Link id="patients-menu" to={`/${user?.type}/patients`}>
            <MenuItem smallMode={smallMode} active={activeMenu === 'patients'} icon={<PatientsSVG />} text="Patients" />
          </Link>
        )}

        {user?.type === USER_ROLES.DENTIST && (
          <Link id="education-menu" to={`/${user?.type}/education`}>
            <MenuItem
              smallMode={smallMode}
              active={activeMenu === 'education'}
              icon={<EducationSVG />}
              text="Education"
              notification={user.newContentAssigned}
              // disabled={user?.status === 'applied'}
            />
          </Link>
        )}

        {user?.type === USER_ROLES.DENTIST && (
          <>
            {isOnboardingContentVisible && hilightWizardPopover && <div className="journeyPopoverBg" />}
            <Popover
              content={onBoardingContent}
              visible={isOnboardingContentVisible}
              onVisibleChange={onVisibleChange}
              title=""
              placement="right"
              trigger="click"
            >
              <MenuItem
                smallMode={smallMode}
                active={activeMenu === 'onboarding-wizard'}
                icon={<JourneySVG />}
                text="Guides"
                id="guides-menu"
                disabled={user?.status === UserStatuses.APPLIED}
                tooltip="Complete your onboarding steps to unlock Guide"
              />
            </Popover>
          </>
        )}
        {user?.type === USER_ROLES.DENTIST && (
          <Link
            id="pricing-menu"
            to={`/${user?.type}/pricing`}
            className={user?.status === UserStatuses.APPLIED ? 'pointer-events-none' : ''}
          >
            <MenuItem
              smallMode={smallMode}
              active={activeMenu === 'pricing'}
              icon={<CartSVG fill="white" />}
              text="Pricing"
              disabled={user?.status === UserStatuses.APPLIED}
              tooltip="Complete your onboarding steps to unlock Pricing"
            />
          </Link>
        )}
      </div>
    </div>
  );
};

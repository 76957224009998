import { useState, useEffect } from 'react';
import { ZINDEX } from 'utils/ui';

export const NoInternetConnectionCheck = () => {
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  return isOnline ? null : (
    <div
      className="text-white text-center text-sm fixed w-full"
      style={{ zIndex: ZINDEX.NO_CONECTION, background: 'red' }}
    >
      No Interner Connection.
    </div>
  );
};

import UnAuthenticatedRoute from 'components/UnAuthenticatedRoute';
import authRoutes from './auth-routes';

const AuthModule = () => (
  <>
    {authRoutes.map((route) => (
      <UnAuthenticatedRoute
        key={route.key}
        path={route.path}
        exact={route.exact}
        name={route.name}
        component={route.component}
      />
    ))}
  </>
);

export const authRoutesToCheck = authRoutes.map((route) => route.path);

export default AuthModule;

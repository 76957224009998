import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import type { IScanner } from 'components/scanner-select/scanner-select';
import { APP_VERSION } from 'config/constants';
import { setAppVersionNotMatch } from 'redux/appSlice';
import { logout } from '../modules/auth/authSlice';
import type { IUser } from '../modules/auth/services/type';

// const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api-dev.32co.com';
const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api-alpha.32co.com';

export const VERSION = process.env.REACT_APP_BASE_URL_VERSION || 'v1';
export const REACT_APP_32CO_WEBSITE_LINK = process.env.REACT_APP_32CO_WEBSITE_LINK || 'https://32co.com';

export const formatUrl = (path: string) => {
  const userType = localStorage.getItem('userType');
  let type = '';
  if (userType) {
    type = JSON.parse(userType);
  }

  if (path.includes('conversation')) {
    return `/${VERSION}/user${path}`;
  }
  return `/${VERSION}/${type}${path}`;
};

export interface ISignedURLRequest {
  fileName: any;
  mimeType: any;
  folderName: string;
}

export interface IUploadFileRequest {
  url: string;
  file: any;
}

export interface IMetaOption {
  // id: string;
  value: string;
  label: string;
  fieldId: string;
}

type TMetaOptionsResponse = {
  fieldKey: string;
  options: IMetaOption[];
}[];

type TPostMetaOptionRequest = {
  fieldId?: string;
  label: string;
  value: string;
};

export interface IUpdateUserProfilePayload {
  firstName?: string;
  lastName?: string;
  preferences?: {
    bio?: string;
    screenName?: string;
  };
  phone?: TPhone;
  licenseNumber?: string;
  profilePic?: string;
  practicesYear?: number;
  totalClearAlignerCases?: string;
  topics?: string[];
  newRelease?: boolean;
}

export interface ICountryOption {
  code: string;
  name: string;
  ext: string;
  flag: string;
  currencyName: string;
  currencySymbol: string;
}

const encodeUrl = (url: string) => {
  try {
    return encodeURIComponent(url);
  } catch (error) {
    return '';
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers: any) => {
    headers.set('x-client-version', APP_VERSION);
    return headers;
  },
  credentials: 'include',
});
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  const { error, data } = result as any;
  if (error?.status === 401 || error?.status === 403) {
    api.dispatch(logout());
  } else if (data?.error === 'APP_VERSION_NOT_MATCH') {
    api.dispatch(setAppVersionNotMatch({ isAppVersionNotMatch: true }));
    throw Error('APP_VERSION_NOT_MATCH');
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Submissions',
    'SubmissionsByPatient',
    'TreatmentDesigns',
    'PatientProposals',
    'Modifications',
    'CaseInvitations',
    'Discounts',
    'Notes',
    'PatientSubmissions',
    'ChatHub',
    'EduContent',
    'EduContentDetails',
    'Profiles',
    'UserSetting',
  ],
  endpoints: (builder) => ({
    getSignedUrl: builder.mutation<any, ISignedURLRequest>({
      query: (credentials) => ({
        url: `/${VERSION}/user/files/signed-url/private`,
        method: 'POST',
        body: credentials,
      }),
    }),
    uploadFile: builder.mutation<any, IUploadFileRequest>({
      query: (credentials) => ({
        url: credentials.url,
        method: 'PUT',
        body: credentials.file,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    }),
    getUserProfile: builder.query<IUser, any>({
      query: () => `/${VERSION}/user/auth/profile`,
      // Note*: Because we're using lazy query and want to manually trigger the request
      // so should not add tags for this query due to https://github.com/reduxjs/redux-toolkit/issues/2802
    }),
    updateUserProfile: builder.mutation<IUser, IUpdateUserProfilePayload>({
      query: (body) => ({
        url: `/${VERSION}/user/profiles`,
        method: 'PUT',
        body,
      }),
    }),
    getScanner: builder.query<IScanner[], void>({
      query: () => ({
        url: `/${VERSION}/user/scanners`,
      }),
    }),
    getMetaOptions: builder.query<TMetaOptionsResponse, string>({
      query: (q) => `/${VERSION}/user/forms/options?formName=${q}`,
    }),
    postMetaOption: builder.mutation<Partial<IMetaOption>, TPostMetaOptionRequest>({
      query: (body) => ({
        url: `/${VERSION}/user/forms/options`,
        method: 'POST',
        body,
      }),
    }),
    getNotionData: builder.query<{ data: any }, string>({
      query: (url) => `/${VERSION}/user/forms/notions?url=${encodeUrl(url)}`,
    }),
    getCountryOptions: builder.query<ICountryOption[], void>({
      query: () => `/${VERSION}/user/forms/countries`,
    }),
  }),
});

export const {
  useGetSignedUrlMutation,
  useUploadFileMutation,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useGetScannerQuery,
  useGetMetaOptionsQuery,
  usePostMetaOptionMutation,
  useGetNotionDataQuery,
  useGetCountryOptionsQuery,
} = api;

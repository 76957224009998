/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TCaseState = {
  searchStr: string;
  page: number;
  perPage: number;
};

const slice = createSlice({
  name: 'case',
  initialState: { searchStr: '', page: 1, perPage: 10 } as TCaseState,
  reducers: {
    setSearchStr: (state, { payload: { q } }: PayloadAction<{ q: string }>) => {
      state.searchStr = q;
      state.page = 1;
    },
    setPage: (state, { payload: { page } }: PayloadAction<{ page: number }>) => {
      state.page = page;
    },
    setPerPage: (state, { payload: { perPage } }: PayloadAction<{ perPage: number }>) => {
      state.page = 1;
      state.perPage = perPage;
    },
  },
});

export const { setSearchStr, setPage, setPerPage } = slice.actions;

export default slice.reducer;

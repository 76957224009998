/* eslint-disable import/no-extraneous-dependencies */
import { DoubleLeftOutlined, DoubleRightOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { Divider, Drawer, Dropdown, Layout, Menu, Popover, Spin, Typography } from 'antd';
import messagesSvg from 'assets/images/chat/messages.svg';
import logoutSvg from 'assets/images/dashboard/logout.svg';
import userSvg from 'assets/images/dashboard/user.svg';
import { RedirectToSignInWithBackUrl } from 'components/PrivateRoute';
import { API_STATUS_CODES, REACT_TOUR_START_ONBOARDING } from 'config/constants';
import { getDatabase, onDisconnect, ref, update } from 'firebase/database';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useAppVersionChecking } from 'hooks/useAppVersionChecking';
import { useAuth } from 'hooks/useAuth';
import { usePendo } from 'hooks/usePendo';
import { useQuery } from 'hooks/useQuery';
import { useWhatIsNew } from 'hooks/useWhatIsNew';
import { useWindowSize } from 'hooks/useWindowSize';
import { IRoute } from 'interface/IRoute';
import { logout, updateJustLoggedIn } from 'modules/auth/authSlice';
import { useSignOutMutation } from 'modules/auth/services/authApi';
import { UserStatuses } from 'modules/auth/services/type';
import { FC, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { ReactourStep } from 'reactour';
import { selectPageLoader } from 'redux/appSlice';
import { setIsConnected } from 'redux/socketSlice';
import socketService from 'services/socket';
import { ZINDEX, notificationApiError } from 'utils/ui';
import { AddToBookmarksModal } from '../../../modules/gdp/dashboard/components/add-to-bookmarks/add-to-bookmarks-modal';
import { shouldShowBookmarkUsModal } from '../../../modules/gdp/dashboard/components/add-to-bookmarks/utils';
import PageOverlayLoader from '../../page-overlay-loader/page-overlay-loader.component';
import { ReactTourComponent } from '../../react-tour/react-tour';
import './dashboard-layout.styles.less';
import { DashboardMenu, MENU_DURATION } from './dashboard-menu';
import { TeamVideoModal } from './onboarding/team-video-modal/team-video-modal.component';
import { PatientSearch } from './patient-search/patient-search';
import { PrimaryNotification } from './primary-notification/primary-notification';

const { Header, Content } = Layout;
let timeout: any;
const DashboardLayoutComponent: FC = ({ children }) => {
  const { user, justLoggedIn } = useAuth();
  const { isMd } = useWindowSize();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const queryParams = useQuery();
  const display = queryParams.get('display');
  const open = queryParams.get('open');

  const showOnboardingVideo = open === 'onboardingVideo';
  const isOnboarding = open === 'onboarding' || display === 'onboarding';

  const wizardQuery = queryParams.get('wizard');
  const isWizardSubmittedModalShown = wizardQuery === 'caseSubmitted' || wizardQuery === 'proposalSubmitted';

  const showAppPageLoader = useAppSelector(selectPageLoader);
  const { isConnected } = useAppSelector((state) => state.socket);
  const { unreadNotificationsCount } = useAppSelector((state) => state.notifications);

  const [activeMenu, setActiveMenu] = useState<string>(user?.type || 'dentist');
  const [collapseMenu, setCollapseMenu] = useState(!!localStorage.getItem('collapseMenu'));
  const [showDrawer, setShowDrawer] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const showBookmarkSuggestionModal = shouldShowBookmarkUsModal();

  /* ONBOARDING RELATED HOOKS */
  const [isOnboardingContentVisible, setIsOnboardingContentVisible] = useState(false);
  const [justWatchedVideo, setJustWatchedVideo] = useState(false);
  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);
  const [hilightWizardPopover, setHilightWizardPopover] = useState(false);
  /* END OF ONBOARDING RELATED HOOKS */

  usePendo(user);
  useAppVersionChecking();

  const [signOutApi, { isLoading: isSignOutLoading }] = useSignOutMutation();

  const connectSocket = async () => {
    if (!user?.id) return;

    try {
      const socket = await socketService.connect(user.id);
      console.log('socket connected', socket);
      dispatch(setIsConnected(true));
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const disconnectSocket = () => {
    console.log('socket disconnected');
    dispatch(setIsConnected(false));
    socketService.disconnect();
  };

  useEffect(() => {
    if (user && user.id && !isConnected) {
      timeout = setTimeout(connectSocket, 500);
    }

    const handleConnect = () => {
      console.log('connected called');
      dispatch(setIsConnected(true));
    };

    const handleDisconnect = () => {
      console.log('disconnected called');
      dispatch(setIsConnected(false));
    };

    const handleOnline = () => {
      console.log('online called');
      connectSocket();
    };

    const handleOffline = () => {
      console.log('offline called');
      disconnectSocket();
    };

    socketService.socket?.on('connect', handleConnect);
    socketService.socket?.on('disconnect', handleDisconnect);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      clearTimeout(timeout);
      disconnectSocket();
      socketService.socket?.off('connect', handleConnect);
      socketService.socket?.off('disconnect', handleDisconnect);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    console.log('i am rendered');
    return () => {
      console.log('i am removed');
    };
  }, []);

  useEffect(() => {
    const getActiveMenu = () => {
      const path = location.pathname;
      if (path.includes('patients')) {
        return 'patients';
      }
      if (path.includes('education')) {
        return 'education';
      }
      if (path.includes('orders')) {
        return 'orders';
      }
      if (path.includes('pricing')) {
        return 'pricing';
      }
      return user?.type || 'dentist';
    };

    const actMenu = getActiveMenu();
    setActiveMenu(actMenu);
    setShowDrawer(false);
  }, [location, user]);

  const pageAccessedByReload =
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map((nav: any) => nav?.type)
      .includes('reload');

  useEffect(() => {
    if (display && display === 'onboarding') {
      setIsOnboardingContentVisible(true);
      setTimeout(() => {
        setShowOnboardingWizard(true);
      }, 500);

      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 900);
    }
  }, [display, pageAccessedByReload]);

  useEffect(() => {
    if (!isOnboardingContentVisible) {
      setHilightWizardPopover(false);

      if (justWatchedVideo && !isOnboarding && !isWizardSubmittedModalShown) {
        setJustWatchedVideo(false);
      }
    }
  }, [isOnboardingContentVisible, justWatchedVideo, isOnboarding, isWizardSubmittedModalShown]);

  useEffect(() => {
    if (showOnboardingVideo) {
      NiceModal.show(TeamVideoModal, {
        onClose: () => {
          history.push(location.pathname);
        },
      });
    }
  }, [showOnboardingVideo]);

  const isWhatIsNewShown = useWhatIsNew();

  useEffect(() => {
    if (!user?.newRelease || !isWhatIsNewShown) {
      // wait for new release popup is shown first (if any) AND notification loaded
      const showPopup = async () => {
        // show bookmark popup
        if ([UserStatuses.LIVE, UserStatuses.PENDING_PACKAGE].includes(user?.status!) && showBookmarkSuggestionModal) {
          await NiceModal.show(AddToBookmarksModal);
        }

        if (justLoggedIn) {
          if (unreadNotificationsCount) setShowNotifications(true);
          dispatch(updateJustLoggedIn({ justLoggedIn: false }));
        }
      };
      showPopup();
    }
  }, [isWhatIsNewShown, showBookmarkSuggestionModal, justLoggedIn, unreadNotificationsCount]);

  const toggleSideDrwer = () => {
    setShowDrawer(!showDrawer);
  };

  const onClose = () => {
    setShowDrawer(false);
  };

  const logoutUser = async () => {
    try {
      await signOutApi().unwrap();
      dispatch(logout());
      history.push('');
      const database = getDatabase();
      const userRef = ref(database, `users/${user?.id}`);
      update(userRef, {
        isOnline: false,
      })
        .then(() => {
          onDisconnect(userRef).update({ isOnline: false });
        })
        .catch();
    } catch (e: any) {
      if (e?.status !== API_STATUS_CODES.FORBIDDEN) notificationApiError(e);
    }
  };

  const userProfile = () => {
    history.push(`/${user?.type}/profile`);
  };

  const onboardingSteps: ReactourStep[] = [
    {
      selector: `.${REACT_TOUR_START_ONBOARDING}`,
      content: (
        <>
          <Typography.Text className="text-base font-semibold">Your Journey Tutorial</Typography.Text>
          <div className="text-sm font-normal text-gray-500 mt-2">
            In case you want to restart your journey again? You can start from here!
          </div>
        </>
      ),
      mutationObservables: [`[data-tour-id="mask-position-recompute"]`],
    },
  ];

  const handleTourRequestClose = () => {
    setIsOnboardingContentVisible(false);
    setShowOnboardingWizard(false);
    document.body.style.overflow = 'initial';
    history.push(`/${user?.type}`);

    setJustWatchedVideo(false);
  };

  const helpPopoverContent = (
    <div className="w-60 p-2">
      <Typography.Text className="font-bold text-sm">Help and Support</Typography.Text>
      <Divider className="mt-1 mb-2" />
      <div className="flex">
        <div className="grow mr-1 flex-row text-justify">
          <Typography.Text className="text-xs leading-none text-gray-500">
            If you have a question about a patient click into their profile and use the <strong>chat tool!</strong> Look
            for this icon bottom right
          </Typography.Text>
          <div className="mt-2">
            <Typography.Text className="text-xs leading-none text-gray-500">
              For anything else please contact <br />{' '}
              <a href="mailto:support@32co.com" className="text-gray-500 font-bold">
                support@32co.com
              </a>
            </Typography.Text>
          </div>
        </div>
        <div className="flex flex-none mt-3 ml-3">
          <div className="flex justify-center items-center cursor-pointer chat-icon-container w-15 h-15">
            <img alt="" src={messagesSvg} />
          </div>
        </div>
      </div>
    </div>
  );

  const renderRightMoreMenu = () => (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            <Popover
              content={helpPopoverContent}
              title=""
              placement="right"
              trigger="click"
              className="cursor-pointer"
              showArrow={false}
            >
              <span className="text-sm">Help</span>
            </Popover>
          </Menu.Item>
          <Menu.Item>
            <div onClick={userProfile}>
              <span className="text-sm">Profile</span>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div onClick={logoutUser}>
              Sign out
              {isSignOutLoading && <Spin size="small" className="ml-2" />}
            </div>
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
      trigger={['click']}
      overlayClassName="w-28 pr-2"
    >
      <div className="md:hidden cursor-pointer px-3 flex items-start">
        <MoreOutlined className="text-lg font-semibold flex" style={{ color: '#999' }} />
      </div>
    </Dropdown>
  );

  return (
    <Layout className="layout">
      <div
        className="custom-menu-s hidden md:block"
        style={{
          width: collapseMenu ? '100px' : '200px',
          transition: `width ${MENU_DURATION}`,
        }}
      >
        <DashboardMenu
          user={user!}
          activeMenu={activeMenu}
          showOnboardingWizard={showOnboardingWizard}
          onSetShowOnboardingWizard={setShowOnboardingWizard}
          isOnboardingContentVisible={isOnboardingContentVisible}
          onSetIsOnboardingContentVisible={setIsOnboardingContentVisible}
          hilightWizardPopover={hilightWizardPopover}
          smallMode={collapseMenu}
        />
        <div
          className="hidden md:flex cursor-pointer items-center text-white fixed bottom-0 left-0 justify-end p-3 text-lg"
          onClick={() => {
            localStorage.setItem('collapseMenu', !collapseMenu ? 'true' : '');
            setCollapseMenu(!collapseMenu);
          }}
          style={{
            width: collapseMenu ? '100px' : '200px',
            transition: `width ${MENU_DURATION}`,
          }}
        >
          {collapseMenu ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
        </div>
      </div>

      <Drawer
        className="md:hidden"
        placement="left"
        onClose={onClose}
        visible={showDrawer}
        bodyStyle={{ padding: 0 }}
        contentWrapperStyle={{
          width: '200px',
          background: '#060A43',
        }}
        closable={false}
      >
        <div className="custom-menu-s-mobile">
          <DashboardMenu
            user={user!}
            activeMenu={activeMenu}
            showOnboardingWizard={showOnboardingWizard}
            onSetShowOnboardingWizard={setShowOnboardingWizard}
            isOnboardingContentVisible={isOnboardingContentVisible}
            onSetIsOnboardingContentVisible={setIsOnboardingContentVisible}
            hilightWizardPopover={hilightWizardPopover}
            smallMode={false}
          />
        </div>
      </Drawer>

      <Layout
        className="site-layout"
        style={{
          marginLeft: isMd ? (collapseMenu ? '100px' : '200px') : '0px',
          transition: `margin-left ${MENU_DURATION}`,
        }}
      >
        <Header
          id="MAIN_HEADER_ID"
          className="app-header bg-white fixed"
          style={{
            width: isMd ? (collapseMenu ? 'calc(100% - 100px)' : 'calc(100% - 200px)') : '100%',
            zIndex: isOnboarding ? 1 : ZINDEX.LAYOUT_HEADER,
          }}
        >
          <div className="!px-0 md:!px-3 flex justify-between">
            <div className="md:hidden cursor-pointer px-3 flex items-center" onClick={toggleSideDrwer}>
              <MenuOutlined className="text-base" style={{ color: '#bbb' }} />
            </div>
            <div />
            <div className="flex justify-between flex-1 md:flex-none">
              <div className="flex items-center w-full">
                <div className="flex-1">{user && user?.type === 'dentist' && <PatientSearch />}</div>
                <Popover
                  content={helpPopoverContent}
                  title=""
                  placement="bottomRight"
                  trigger="click"
                  className="cursor-pointer hidden md:block"
                >
                  <span className="text-sm ml-8">Help</span>
                </Popover>

                <div className="ml-4 md:ml-8 relative cursor-pointer w-6 h-6 flex items-center">
                  <PrimaryNotification
                    showNotifications={showNotifications}
                    onSetShowNotifications={setShowNotifications}
                  />
                </div>

                <div onClick={userProfile} className="ml-8 cursor-pointer hidden md:flex w-6 h-6">
                  <img src={userSvg} alt="userProfile" height={24} />
                </div>
                <div onClick={logoutUser} className="ml-8 cursor-pointer hidden md:flex w-6 h-6">
                  {isSignOutLoading ? <Spin /> : <img src={logoutSvg} alt="logoutUser" height={24} />}
                </div>

                {renderRightMoreMenu()}
              </div>
            </div>
          </div>
        </Header>
        <Content className="content-section mx-3">
          {children}
          <PageOverlayLoader loading={showAppPageLoader} />
        </Content>
        <ReactTourComponent
          onboardingSteps={onboardingSteps}
          handleTourRequestClose={handleTourRequestClose}
          startOnboardingWizard={showOnboardingWizard}
          showNextPrevButtons={false}
          lastStepNextButton="Close"
          className="dashboard-layout-onboarding !rounded-2xl"
        />
      </Layout>
    </Layout>
  );
};

const AuthenticatedRoute: FC<IRoute & RouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          // not logged in so redirect to login page with the return url
          return <RedirectToSignInWithBackUrl />;
        }

        // check if route is restricted by role
        if (user.type !== rest.role) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: `/not-authorized` }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export const DashboardLayout: FC<{ routes: IRoute[] }> = ({ routes }) => (
  <DashboardLayoutComponent>
    {routes.map((route) => (
      <AuthenticatedRoute {...route} />
    ))}
  </DashboardLayoutComponent>
);

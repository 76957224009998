import { Popconfirm } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { FC } from 'react';

interface IPopoverDeleteProps {
  children: React.ReactNode;
  onDelete: () => void;
  placement?: TooltipPlacement;
}

export const PopoverDelete: FC<IPopoverDeleteProps> = ({ children, onDelete, placement }) => (
  <Popconfirm
    placement={placement}
    title="Please confirm you want to delete?"
    okText="Delete"
    okButtonProps={{
      danger: true,
      className: 'ml-2 btn-danger-redesigned',
      shape: 'round',
    }}
    cancelText="Cancel"
    cancelButtonProps={{
      className: 'ml-2 btn-default-redesigned',
      shape: 'round',
    }}
    onConfirm={onDelete}
  >
    {children}
  </Popconfirm>
);

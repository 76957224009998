import { FC, ReactNode } from 'react';
import { IDentistInfo } from '../../types/IDentistInfo';
import cn from 'classnames';
import styles from './dentist-info.module.less';
import { Avatar, Button, Image, Space, Typography } from 'antd';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface IDentistInfoComponentProps {
  dentist: IDentistInfo;
  editable?: boolean;
  replyToDentistBtnSlot?: ReactNode;
}

export const DentistInfoComponent: FC<IDentistInfoComponentProps> = ({ dentist, editable, replyToDentistBtnSlot }) => (
  <div className={cn('flex', styles.dentistInfo)}>
    <div className="w-36">
      {dentist?.profileUrl ? (
        <Image className="" src={dentist?.profileUrl} alt="profile picture" style={{ borderRadius: 10 }} />
      ) : (
        <Avatar shape="square" size={120} icon={<UserOutlined />} />
      )}
    </div>
    <div className="ml-5" id="dentist-info">
      <Space direction="vertical" size="small">
        <div className="text-lg font-semibold">Dr. {dentist.name}</div>
        <Typography.Link>{dentist.practiceName}</Typography.Link>
        <Typography.Text type="secondary">
          <EnvironmentOutlined />
          {dentist.location}
        </Typography.Text>
        {editable && (
          <Link to="/dentist/profile">
            <Button shape="round" className="btn-default-redesigned" size="large">
              Update profile picture
            </Button>
          </Link>
        )}
        {replyToDentistBtnSlot}
      </Space>
    </div>
  </div>
);

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { logout } from '../../modules/auth/authSlice';
import { useAppDispatch } from 'hooks/store';

const NotFound = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <>
          <Button
            type="primary"
            className="btn-primary-redesigned"
            size="large"
            onClick={() => {
              history.push('/');
            }}
          >
            Back Home
          </Button>{' '}
          <Button
            size="large"
            className="ml-2 btn-secondary-redesigned"
            onClick={() => {
              dispatch(logout());
              history.push('/', { replace: true });
            }}
          >
            Logout
          </Button>
        </>
      }
    />
  );
};

export default NotFound;

import { TTreatmentQuoteTableColumn } from './types/TreatmentQuoteTableColumnType';

export const columns: TTreatmentQuoteTableColumn[] = [
  {
    title: 'Description',
    dataIndex: 'title',
    key: 'title',
    editable: false,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    editable: true,
    render: (value) => (
      <span>
        <b>£</b>
        &nbsp;&nbsp;
        {value}
      </span>
    ),
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    editable: true,
  },
];

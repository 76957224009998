import imageForChromePng from '../../../../../assets/images/add-to-bookmarks/image-for-chrome.png';
import imageForSafariPng from '../../../../../assets/images/add-to-bookmarks/image-for-safari.png';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const DAYS_DELAY_UNTIL_SHOW_AGAIN = 60;

export enum BrowserType {
  safari = 'safari',

  chrome = 'chrome',
  firefox = 'firefox',
  opera = 'opera',
  edge = 'edge',

  unknown = 'unknown',
}

export const isMac = (): boolean =>
  /(macintosh|macintel|macppc|mac68k|macos)/.test(window.navigator.userAgent.toLowerCase());

export const detectBrowserType = (): BrowserType => {
  const { userAgent } = window.navigator;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return BrowserType.chrome;
  }
  if (userAgent.match(/firefox|fxios/i)) {
    return BrowserType.firefox;
  }
  if (userAgent.match(/safari/i)) {
    return BrowserType.safari;
  }
  if (userAgent.match(/opr\//i)) {
    return BrowserType.opera;
  }
  if (userAgent.match(/edg/i)) {
    return BrowserType.edge;
  }

  return BrowserType.unknown;
};

export const getShortcut = (): string | null => {
  if (isMac()) {
    return '⌘ + D';
  }
  return 'CTRL + D';
};

export const getImage = (): string | null => {
  const browserType = detectBrowserType();

  if (browserType === BrowserType.safari) {
    return imageForSafariPng;
  }

  return imageForChromePng;
};

/*
  localStorage[LS_BOOKMARK_SUGGESTION_MODAL_SHOWN_KEY]
  can be: undefined | 'true' | '<last-show-date-timestamp>'
*/
const LS_BOOKMARK_SUGGESTION_MODAL_SHOWN_KEY = 'bookmarkSuggestionModalWasShownInThisBrowserOrLastShowDate';

export const shouldShowBookmarkUsModal = (): boolean => {
  /* Do not show bookmark modal on mobile for now */
  if (isMobile) {
    return false;
  }

  const lsValue = localStorage.getItem(LS_BOOKMARK_SUGGESTION_MODAL_SHOWN_KEY);

  // If popup already was shown, no need to show it again
  if (lsValue === 'true') {
    return false;
  }

  // If no value in localStorage, so popup was never shown, so we can show it
  if (!lsValue) {
    return true;
  }

  // If value is not 'true' and it is not a date, so remove it and show the popup
  if (lsValue && Number.isNaN(Date.parse(lsValue!))) {
    localStorage.removeItem(LS_BOOKMARK_SUGGESTION_MODAL_SHOWN_KEY);
    return true;
  }

  const lastPopupShowDate = moment(lsValue);
  const now = moment();

  const differenceInDays = now.diff(lastPopupShowDate, 'days');

  if (differenceInDays >= DAYS_DELAY_UNTIL_SHOW_AGAIN) {
    return true;
  }

  return false;
};

export const saveUserAcceptedBookmarkSuggestion = () => {
  localStorage.setItem(LS_BOOKMARK_SUGGESTION_MODAL_SHOWN_KEY, 'true');
};

export const saveUserDeclinedBookmarkSuggestion = () => {
  localStorage.setItem(LS_BOOKMARK_SUGGESTION_MODAL_SHOWN_KEY, new Date().toISOString());
};

import { FC } from 'react';
import midlLogoSvg from 'assets/images/auth/mid-logo.svg';
import logoSvg from 'assets/images/dashboard/logo.svg';
import alignerPng from 'assets/images/auth/aligner.png';
import { useHistory } from 'react-router-dom';
import { pushToDataLayer } from 'utils/gtm';
import styled from 'styled-components';
import { Button, Carousel } from 'antd';
import { BREAKPOINT } from '../../../utils/ui';
import { useWindowSize } from 'hooks/useWindowSize';

const DIV = styled.div`
  min-height: 100vh;

  .left {
    background-color: #050a46;
    padding-left: 26px;
    padding-right: 60px;
    position: fixed;
    width: 360px;
    height: 100vh;

    .title {
      font-size: 26px;
      font-weight: bold;
      margin-top: 25px;
      margin-bottom: 50px;
    }
  }

  .topPart {
    height: 70px;
    top: 0;
    z-index: 2;
    background: white;
    width: 100%;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    justify-content: space-between;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    .right {
      margin-left: 360px;
    }

    .topPart {
      box-shadow: none;
    }
  }
`;

interface IAuthLayoutProps {
  children: React.ReactNode;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export const AuthLayout: FC<IAuthLayoutProps> = ({ children, onMouseLeave }) => {
  const history = useHistory();
  const { isMd } = useWindowSize();

  return (
    <DIV onMouseLeave={onMouseLeave}>
      <div className="hidden md:flex flex-col left">
        <div className="mt-8">
          <img src={logoSvg} alt="logo" height={63} width={169} />
        </div>
        <div className="flex-auto flex items-center text-white">
          <Carousel
            autoplay
            autoplaySpeed={5000}
            pauseOnHover
            style={{
              width: '274px',
              marginTop: '40px',
              marginBottom: '50px',
            }}
          >
            {[
              {
                title: 'Duran by Scheu',
                subtitle: '1 refinement included',
                description: 'Specialist Orthodontist Supported Design and Plan',
              },
              {
                title: 'Zendura FLX by Straumann',
                subtitle: 'All refinements included',
                description: 'Specialist Orthodontist Supported Design and Plan',
              },
              {
                title: 'Essix by Dentsply',
                subtitle: '2 refinements included',
                description: 'Specialist Orthodontist Supported Design and Plan',
              },
            ].map((item, index) => (
              <div key={item.title} className="text-white">
                <img src={alignerPng} alt="aligner" width="100%" style={{ transform: `rotateY(${180 * index}deg)` }} />
                <div className="title">{item.title}</div>
                <div className="text-sm font-medium">{item.subtitle}</div>
                <div className="text-base font-semibold mt-4 mb-8">{item.description}</div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <div className="right">
        <div className="flex items-center px-3 topPart fixed md:relative">
          {!isMd ? <img src={midlLogoSvg} alt="logo" height={40} width={107} /> : <div />}
          <div className="flex items-center">
            <span className="hidden md:block font-medium mr-4">Already have account?</span>
            <Button
              onClick={() => {
                const pathName = window.location.pathname.replace('/', '');
                pushToDataLayer({ event: `${pathName}.signin.click` });
                history.push('/signin');
              }}
              style={{
                width: '94px',
                borderColor: '#e83c41',
                height: '36px',
              }}
            >
              Sign in
            </Button>
          </div>
        </div>

        <div className="children px-4 lg:px-8">{children}</div>
      </div>
    </DIV>
  );
};

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const boot = () => {
  const env = process.env.REACT_APP_ENV; // dev, stage, prod

  if (env === 'prod') {
    const sentryProdDsn = process.env.REACT_APP_SENTRY_PROD_DSN;
    Sentry.init({
      dsn: sentryProdDsn,
      // "https://e575577bedab4591a79fa4aac2863f71@o1101080.ingest.sentry.io/6126753", // prod
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  } else if (env === 'stage') {
    // TODO: setup stage sentry project
    const sentryProdDsn = process.env.REACT_APP_SENTRY_STAGE_DSN;
    Sentry.init({
      dsn: sentryProdDsn,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  } else if (env === 'dev') {
    // TODO: setup dev sentry project
    const sentryProdDsn = process.env.REACT_APP_SENTRY_DEV_DSN;
    Sentry.init({
      dsn: sentryProdDsn,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

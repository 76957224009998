import { FC } from 'react';
import './auth-layout.styles.less';
import smallLogoSvg from 'assets/images/auth/small-logo.svg';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { pushToDataLayer } from 'utils/gtm';
import { useAppVersionChecking } from 'hooks/useAppVersionChecking';

interface IAuthLayoutProps {
  children: React.ReactNode;
  hideSmallLogo?: boolean;
  showGotoSignin?: boolean;
  background2?: boolean;
}

const AuthLayout: FC<IAuthLayoutProps> = ({
  children,
  hideSmallLogo = false,
  showGotoSignin = false,
  background2 = false,
}) => {
  useAppVersionChecking();

  return (
    <div className={cn('auth-layout', { background2 })}>
      <div className="top-part">
        {!hideSmallLogo && <img src={smallLogoSvg} alt="logo" className="small-logo" />}
        {showGotoSignin && (
          <div className="goto-signin">
            <span>Already have account?</span>
            <Link
              to="/signin"
              onClick={() => {
                const pathName = window.location.pathname.replace('/', '');
                pushToDataLayer({ event: `${pathName}.signin.click` });
              }}
            >
              Sign in
            </Link>
          </div>
        )}
      </div>
      <div className="page-container pb-4 md:pb-6">{children}</div>
    </div>
  );
};

export default AuthLayout;

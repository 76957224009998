/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IUser } from './services/type';
import type { TRootState } from 'redux/store';

export type TAuthState = {
  user: IUser | null;
  isFullProfileLoaded: boolean;
  justLoggedIn: boolean;
};

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, isFullProfileLoaded: false, justLoggedIn: false } as TAuthState,
  reducers: {
    setCredentials: (state, { payload: user }: PayloadAction<IUser>) => {
      state.user = user;
      state.isFullProfileLoaded = false;
      state.justLoggedIn = true;
    },
    updateUserData: (state, { payload: user }: PayloadAction<IUser>) => {
      state.user = user;
      state.isFullProfileLoaded = true;
    },
    logout: (state) => {
      state.user = null;
      state.isFullProfileLoaded = false;
      state.justLoggedIn = false;
    },
    updateJustLoggedIn: (state, { payload: { justLoggedIn } }: PayloadAction<{ justLoggedIn: boolean }>) => {
      state.justLoggedIn = justLoggedIn;
    },
  },
});

export const { setCredentials, updateUserData, logout, updateJustLoggedIn } = slice.actions;

export default slice.reducer;

export const selectAuthState = (state: TRootState) => state.auth;

import { Form, Input, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import { useForgotPasswordMutation } from '../services/authApi';
import keySvg from 'assets/images/auth/key.svg';
import { SigninFormItem } from '../components/signin-form-item/signin-form-item';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';
import { useForm } from 'antd/lib/form/Form';
import { notificationDefaultError } from 'utils/ui';
import { API_STATUS_CODES } from 'config/constants';

const ForgotPassword = () => {
  const history = useHistory();
  const [form] = useForm();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const onSubmit = async (formState: any) => {
    try {
      await forgotPassword(formState).unwrap();
      notification.success({
        message: 'Check your email',
        description: "We've sent an email to the address provided. Click the link in the email to reset your password.",
        placement: 'topRight',
        duration: 8,
      });
      setTimeout(() => {
        history.replace('/signin');
      }, 2000);
    } catch (err: any) {
      if (err?.data?.statusCode === API_STATUS_CODES.BAD_REQUEST) {
        notificationDefaultError(err?.data?.message);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthLayout showGotoSignin>
      <div className="flex items-start mt-20 tracking-wide">
        <div className="mr-6">
          <img src={keySvg} alt="key" />
        </div>

        <div className="flex-1">
          <p className="text-base font-semibold mb-2 text-darkBlueColor">Forgot Password</p>
          <p className="text-gray-500">We will send you an email to reset your password.</p>

          <Form
            form={form}
            name="basic"
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            size="large"
            layout="vertical"
            autoComplete="off"
            className="animate-fadeIn w-full mt-12"
          >
            <SigninFormItem
              form={form}
              formName="email"
              label="Enter your email address"
              formRules={[
                { required: true, message: 'Please enter your email address' },
                { type: 'email', message: 'Please enter your full email address' },
              ]}
              hasEmailSuffix
            >
              <Input />
            </SigninFormItem>

            <Form.Item className="!mt-12 text-right">
              <AuthButtonComponent isLoading={isLoading}>Submit</AuthButtonComponent>
            </Form.Item>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;

/* eslint-disable no-param-reassign */
import type { TRootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TAppState = {
  loading: boolean;
  googlePlacesLoaded: boolean;
  isAppVersionNotMatch: boolean;
};

const INITIAL_STATE: TAppState = {
  loading: false,
  googlePlacesLoaded: false,
  isAppVersionNotMatch: false,
};

const slice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    setPageLoader: (state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) => {
      state.loading = loading;
    },
    setGooglePlacesLoaded: (state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) => {
      state.googlePlacesLoaded = loading;
    },
    setAppVersionNotMatch: (
      state,
      { payload: { isAppVersionNotMatch } }: PayloadAction<{ isAppVersionNotMatch: boolean }>,
    ) => {
      state.isAppVersionNotMatch = isAppVersionNotMatch;
    },
  },
});

export const { setPageLoader, setGooglePlacesLoaded, setAppVersionNotMatch } = slice.actions;

export default slice.reducer;

export const selectPageLoader = (state: TRootState) => state.app.loading;
export const selectGooglePlacesLoaded = (state: TRootState) => state.app.googlePlacesLoaded;
export const selectAppVersionNotMatch = (state: TRootState) => state.app.isAppVersionNotMatch;

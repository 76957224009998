import { api } from 'services/api';
import { buildQueryString } from 'services/utils';
import { TGetNotificationsResponse, TGetNotificationsRequest, TUpdateNotificationsIsViewRequest } from './types';

export const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<TGetNotificationsResponse, TGetNotificationsRequest>({
      query: (queryArg) => {
        const paramsString = buildQueryString(queryArg);
        return `/v1/user/notifications?${paramsString}`;
      },
    }),
    updateNotificationsIsView: build.mutation<void, TUpdateNotificationsIsViewRequest>({
      query: ({ id, isViewed }) => ({
        url: `/v1/user/notifications/${id}`,
        method: 'PUT',
        body: { isViewed },
      }),
    }),
    readAllNotifications: build.mutation<void, void>({
      query: () => ({
        url: '/v1/user/notifications/read-all',
        method: 'POST',
      }),
    }),
  }),
});

export const { useLazyGetNotificationsQuery, useUpdateNotificationsIsViewMutation, useReadAllNotificationsMutation } =
  notificationApi;

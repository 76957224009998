import { SearchOutlined } from '@ant-design/icons';
import { Input, Spin } from 'antd';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSearchSubmissionsQuery } from 'services/patients-api/endpoints';
import { ISubmission } from 'services/patients-api/types';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { toogleBodyElmScroll } from 'utils/ui';
import { SUBMISSION_STATUS_TYPE } from 'config/constants';

type TSearchOption = TOptionString & {
  href: string;
};

const DIV = styled.div`
  line-height: 24px;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background-color: #efefef;
  }
`;

type TOptionProps = {
  label: string;
  onClick: () => void;
};

const Option: FC<TOptionProps> = ({ label, onClick }) => (
  <DIV onClick={onClick} className="px-3 py-2">
    <SearchOutlined className="mr-2" />
    {label}
  </DIV>
);

export const PatientSearch: FC = () => {
  const [input, setInput] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const boxRef = useRef<HTMLDivElement>(null);
  const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
  useOnClickOutside(boxRef, () => setIsBoxOpen(false));

  const { searchedPatients, isSearchingPatients, isFetchingPatients } = useSearchSubmissionsQuery(searchValue, {
    skip: searchValue.length < 3,
    selectFromResult: ({ data, isLoading, isFetching, isUninitialized }) => ({
      searchedPatients: data?.docs.map((item: ISubmission) => ({
        label: item.patient.fullName,
        value: item.id,
        href:
          item.status === SUBMISSION_STATUS_TYPE.DRAFT
            ? `/dentist/patients/submit/${item.serviceType}?submissionId=${item.id}`
            : `/dentist/patients/${item.id}`,
      })),
      isSearchingPatients: isLoading,
      isFetchingPatients: isFetching,
      isUninitializedPatients: isUninitialized,
    }),
  });

  const handleChange = (value: string) => {
    setInput(value);
  };

  const setSearchValueDebounce = useCallback(
    debounce((value) => setSearchValue(value), 500),
    [],
  );

  useEffect(() => {
    setSearchValueDebounce(input);
  }, [input, setSearchValueDebounce]);

  const handleClick = (option: TSearchOption) => {
    window.location.href = option.href;
  };

  return (
    <div
      className="relative"
      ref={boxRef}
      onMouseOver={(_e) => toogleBodyElmScroll(false)}
      onMouseOut={(_e) => toogleBodyElmScroll(true)}
      onBlur={() => undefined}
      onFocus={() => undefined}
    >
      <Input
        placeholder="Search for a patient"
        value={input}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => setIsBoxOpen(true)}
        className="md:w-72"
      />
      {isBoxOpen && (
        <div
          className="absolute custom-shadow-2 bg-white overflow-y-scroll radius-5 w-full md:w-72"
          style={{ maxHeight: 400 }}
        >
          {input.length < 3 ? (
            <div className="text-center">Please input at least 3 letters</div>
          ) : isSearchingPatients || isFetchingPatients ? (
            <div className="flex justify-center items-center" style={{ minHeight: '100px' }}>
              <Spin size="small" tip="Searching patient..." />
            </div>
          ) : searchedPatients && searchedPatients.length ? (
            searchedPatients.map((patient) => (
              <Option key={patient.label} label={patient.label} onClick={() => handleClick(patient)} />
            ))
          ) : (
            <div className="text-center">No patient found</div>
          )}
        </div>
      )}
    </div>
  );
};

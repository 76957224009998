import { useEffect } from 'react';
import { DashboardLayout } from 'components/layout/dashboard-layout/dashboard-layout';
import { useLastActive } from 'hooks/useLastActive';
import { gdpRoutes } from './gdp-routes';
import NiceModal from '@ebay/nice-modal-react';
import { TellUsQuestionsModal } from './dashboard/components/tell-us-questions-modal/tell-us-question-modal';
import { useFetchProfile } from 'hooks/useFetchProfile';

const GDPModule = () => {
  const { fetchProfile } = useFetchProfile();
  useLastActive();

  useEffect(() => {
    (async () => {
      const userResponse = await fetchProfile();
      // check logic show tell us here
      if (userResponse?.role === 'DENTIST' && !userResponse?.onBoarding?.info) NiceModal.show(TellUsQuestionsModal);
    })();
  }, [fetchProfile]);

  return <DashboardLayout routes={gdpRoutes} />;
};

export default GDPModule;

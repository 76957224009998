import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Checkbox, Input } from 'antd';
import { setCredentials } from '../authSlice';
import { useLoginMutation } from '../services/authApi';
import type { ILoginRequest } from '../services/authApi';
import AuthLayout from 'components/layout/auth-layout/auth-layout.component';
import midLogoSvg from 'assets/images/auth/mid-logo.svg';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';
import { SigninFormItem } from '../components/signin-form-item/signin-form-item';
import { pushToDataLayer } from 'utils/gtm';
import { useQuery } from 'hooks/useQuery';
import { useForm } from 'antd/lib/form/Form';
import { FC } from 'react';
import { notificationApiError } from 'utils/ui';
import { REACT_APP_32CO_WEBSITE_LINK } from 'services/api';

const Signin: FC<any> = () => {
  const dispatch = useDispatch();
  const params = useQuery();
  const [form] = useForm();

  const redirectUrl = params.get('redirectUrl');

  const { push } = useHistory();

  const [login, { isLoading }] = useLoginMutation();

  const onSubmit = async (formState: ILoginRequest) => {
    try {
      const userResponse: any = await login({ ...formState, email: formState.email.toLowerCase() }).unwrap();
      if (userResponse.forcePasswordReset) {
        localStorage.setItem('resetPasswordToken', JSON.stringify(userResponse.resetPasswordToken));
        push('/reset-password');
      } else {
        const userObject = { ...userResponse, type: userResponse.role?.toLowerCase() };
        dispatch(setCredentials(userObject));
        localStorage.setItem('userType', JSON.stringify(userObject?.type));

        if (redirectUrl) {
          push(redirectUrl);
        } else {
          push('/');
        }
      }
    } catch (err: any) {
      notificationApiError(err);
    }
  };

  return (
    <AuthLayout hideSmallLogo>
      <div>
        <div className="text-center mb-20">
          <img src={midLogoSvg} alt="logo" className="mx-auto" />
        </div>
        <div className="animate-fadeIn">
          <Form
            form={form}
            name="basic"
            onFinish={onSubmit}
            size="large"
            layout="vertical"
            autoComplete="off"
            className="w-full"
          >
            <SigninFormItem
              form={form}
              formName="email"
              className="!mb-8"
              label="Enter your email address"
              formRules={[
                { required: true, message: 'Please enter your email address' },
                { type: 'email', message: 'Please enter your full email address' },
              ]}
              hasEmailSuffix
            >
              <Input />
            </SigninFormItem>
            <SigninFormItem
              form={form}
              formName="password"
              label="Password"
              className="!mb-8"
              formRules={[
                { required: true, message: "You'll need to enter your password" },
                { min: 8, message: 'Please enter a password with at least 8 characters' },
              ]}
            >
              <Input.Password />
            </SigninFormItem>

            <Form.Item className="!mb-6 relative">
              <div className="flex justify-between items-end text-xs md:text-sm" style={{ color: '#767675' }}>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox className="text-gray-500">Remember me</Checkbox>
                </Form.Item>
                <div className="flex tracking-wide">
                  <a
                    href={`${REACT_APP_32CO_WEBSITE_LINK}/dentist/provider?register=true`}
                    rel="noreferrer"
                    className="mr-1 md:mr-3 text-gray-500"
                    onClick={() => {
                      pushToDataLayer({ event: 'signin.notregistered.click' });
                    }}
                    target="_blank"
                  >
                    Not Registered?
                  </a>
                  <span>|</span>
                  <Link
                    to="/forgot-password"
                    className="ml-1 md:ml-3 text-gray-500"
                    onClick={() => {
                      pushToDataLayer({ event: 'signin.forgotpassword.click' });
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </Form.Item>

            <Form.Item className="text-right">
              <AuthButtonComponent isLoading={isLoading}>Login</AuthButtonComponent>
            </Form.Item>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Signin;

import { useCallback } from 'react';
import { ICountryOption, useGetCountryOptionsQuery } from 'services/api';

let countryCodeMap: Record<string, ICountryOption>;

export const useCountryOptions = () => {
  const { data, isLoading } = useGetCountryOptionsQuery();

  const getCountryInfoFromCode = useCallback(
    (countryCode: string) => {
      if (!countryCodeMap && data) {
        countryCodeMap = data.reduce(
          (newMap, item) => ({ ...newMap, [item.code]: item }),
          {} as Record<string, ICountryOption>,
        );
      }
      return countryCodeMap[countryCode];
    },
    [data],
  );

  return { countries: data, isLoading, getCountryInfoFromCode: data ? getCountryInfoFromCode : undefined };
};

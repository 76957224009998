import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../modules/auth/authSlice';
import { IUser, TUserType } from '../modules/auth/services/type';
import { useLazyGetUserProfileQuery } from 'services/api';

interface IUseFetchProfileReturnType {
  fetchProfile: () => Promise<IUser | undefined>;
  isLoading: boolean;
}

export const useFetchProfile = (): IUseFetchProfileReturnType => {
  const dispatch = useDispatch();
  const [getUserProfile, result] = useLazyGetUserProfileQuery();

  const fetchProfile = useCallback(async (): Promise<IUser | undefined> => {
    const data = await getUserProfile({}).unwrap();
    if (data) dispatch(updateUserData({ ...data, type: data.role?.toLowerCase() as TUserType }));
    return data;
  }, [dispatch]);

  return { fetchProfile, isLoading: result.isLoading };
};

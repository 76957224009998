import { FC } from 'react';
import { Empty } from 'antd';
import cn from 'classnames';

interface ISmilePreviewComponentProps {
  url?: string | null;
  isFullSizePage: boolean;
}

const STARTS_WITH = 'https://webview.32-stories.com';

export const SmilePreviewComponent: FC<ISmilePreviewComponentProps> = ({ url, isFullSizePage }) => (
  <div className="text-center">
    {url && url !== '/' && url.startsWith(STARTS_WITH) ? (
      <iframe
        className={cn('w-full', { 'md:w-10/12 lg:w-9/12': isFullSizePage })}
        height="450px"
        src={url}
        title="Preview"
      />
    ) : (
      <Empty />
    )}
  </div>
);

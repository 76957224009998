import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Col, notification, Radio, Row } from 'antd';
import { useMemo, useState } from 'react';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';
import './tell-us-questions.style.less';
import DrAmaPng from 'assets/images/tell-us-flow/dr-ama.png';
import classNames from 'classnames';
import { NiceButton } from 'components/commons/nice-button/nice-button';
import {
  useGetOnboardingQuestionsQuery,
  usePostOnboardingAnswersMutation,
} from 'services/onboarding-api/onboardingApi';
import { IOnboardingAnswerRequestType } from 'services/onboarding-api/types';
import { useFetchProfile } from 'hooks/useFetchProfile';
import { notificationDefaultError } from 'utils/ui';
import { TellUsQuestion, TELL_US_OTHER_OPTION } from './tell-us-question';

const INIT_QUESTION_INDEX = -1;

export const TellUsQuestionsModal = NiceModal.create(() => {
  const modal = useModal();
  const { data, isLoading: isQuestionLoading } = useGetOnboardingQuestionsQuery();
  const [postOnboardingAnswers, { isLoading: isPostLoading }] = usePostOnboardingAnswersMutation();
  const [isConfident, setIsConfident] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(INIT_QUESTION_INDEX);
  const [answers, setAnswers] = useState<IOnboardingAnswerRequestType[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { fetchProfile, isLoading: isFetchProfileLoading } = useFetchProfile();

  const onboardingQuestions = useMemo(() => {
    if (isConfident === null || !data) return [];
    return data?.onBoardingQuestions?.map((item) => ({
      ...item,
      answers: item.answers.filter((answerItem) => answerItem.isConfident === isConfident),
    }));
  }, [data, isConfident]);

  const isConfidentQuestion = useMemo(
    () => (
      <Row gutter={[36, 36]}>
        <Col span={24} md={8}>
          <img src={DrAmaPng} className="w-full max-w-[200px]" alt="" />
        </Col>
        <Col span={24} md={16}>
          <div className="text-3xl font-bold mb-4 text-darkBlueColor">Welcome to 32Co</div>
          <p style={{ color: '#333333', fontSize: 16, fontWeight: 600 }}>
            Hello, I’m Prof. Ama, one of the Orthodontic Specialists at 32Co.
          </p>
          <p>
            I’m glad you’re here and I can’t wait for you to start your first case. Whether you’re new to clear aligners
            or can write a treatment plan in your sleep, there’s plenty here for you.
          </p>
          <p>
            To make sure we show you relevant and interesting content please answer 3 quick questions before you start
            exploring.
          </p>
          <br />
          <p>
            <b>First of all, are you confident treating patients with clear aligners?</b>
          </p>
          <Radio.Group
            buttonStyle="solid"
            className="answer-radio-group"
            onChange={(e) => setIsConfident(e.target.value)}
          >
            <Radio.Button value>Yes</Radio.Button>
            <Radio.Button value={false} style={{ marginLeft: 20 }}>
              No
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
    ),
    [],
  );

  const thankYouContent = useMemo(
    () => (
      <Row gutter={[25, 25]} className="py-5 p-3">
        <Col span={24} md={8}>
          <img src={DrAmaPng} style={{ width: '100%' }} alt="" />
        </Col>
        <Col span={24} md={16}>
          <p style={{ color: '#050d43', fontSize: 20, fontWeight: 500 }}>Thank you - almost done!</p>
          <p>
            <p>
              To start your first case, we need to unlock your account. You can book a 1:1 call or hit unlock on your
              dashboard. We’ve added some videos to help as well.
            </p>
            <p>
              If you hit unlock then our team will review your information and get back to you if they need anything
              else. We recommend watching the videos in your account to guide you through the platform and your first
              case.
            </p>
            <p>
              If you choose to book a 1:1 call with the team, they can answer any of your questions, show you around and
              unlock your full account at the same time.
            </p>
          </p>
        </Col>
      </Row>
    ),
    [],
  );

  const renderContent = () => {
    // Yes/No question
    if (questionIndex === INIT_QUESTION_INDEX) return isConfidentQuestion;
    // thank you content
    if (questionIndex === onboardingQuestions.length) return thankYouContent;
    // selection question
    const currentQuestion = onboardingQuestions[questionIndex];
    return (
      <>
        <TellUsQuestion
          key={currentQuestion.id}
          question={currentQuestion}
          selectedAnswer={answers[questionIndex]}
          onAnswer={(newAnswer) => {
            const newAnswers = [...answers];
            newAnswers[questionIndex] = newAnswer;
            setAnswers(newAnswers);
            if (errorMessage) setErrorMessage(''); // reset validate each selection changing
          }}
        />
        <p style={{ color: '#ff4d4f', padding: '0 20px', height: 22 }}>{errorMessage}</p>
      </>
    );
  };

  const handleNextAction = () => {
    if (questionIndex > INIT_QUESTION_INDEX && questionIndex < onboardingQuestions.length) {
      // VALIDATE
      // case 1: has `notes` but not choose `option`
      const currentAnswer = answers[questionIndex];
      if (!currentAnswer.onBoardingAnswer || !currentAnswer.onBoardingAnswer?.length) {
        setErrorMessage('Please choose one answer.');
        return;
      }
      // case 2: choose `Other` option but not fill `notes`
      const otherAnswerOption = onboardingQuestions[questionIndex].answers.find(
        (option) => option.title === TELL_US_OTHER_OPTION,
      );
      if (otherAnswerOption && currentAnswer.onBoardingAnswer === otherAnswerOption.id && !currentAnswer.notes) {
        setErrorMessage('Please tell us in your own words.');
        return;
      }
    }
    setErrorMessage('');
    setQuestionIndex(questionIndex + 1);
  };

  const handleGetGoingAction = async () => {
    try {
      await postOnboardingAnswers({
        onBoardingAnswers: answers,
        isConfident: !!isConfident,
      }).unwrap();
      await fetchProfile();
      modal.hide();
      notification.success({
        message: 'Success',
        description:
          'Your first pieces of education and ‘Get Started’ videos have been added to your educational content',
      });
    } catch (err: any) {
      notificationDefaultError(err?.data?.errors?.[0]?.message);
    }
  };

  const renderActionButton = () => {
    if (questionIndex === onboardingQuestions.length)
      return (
        <NiceButton
          niceType="orange"
          onClick={handleGetGoingAction}
          loading={isPostLoading || isFetchProfileLoading}
          className="w-28"
        >
          Get Going
        </NiceButton>
      );
    return (
      <NiceButton
        niceType="orange"
        onClick={handleNextAction}
        disabled={questionIndex !== INIT_QUESTION_INDEX && !answers[questionIndex]}
        loading={isQuestionLoading}
        className={classNames(
          'w-24',
          questionIndex === INIT_QUESTION_INDEX && isConfident === null ? 'invisible' : 'visible',
        )}
      >
        Next
      </NiceButton>
    );
  };

  return (
    <NiceAntModal
      width={800}
      modal={modal}
      closable={false}
      maskClosable={false}
      className="tell-us-questions-container"
      bodyStyle={{ padding: 36 }}
    >
      {renderContent()}
      <div className="text-right mt-4">{renderActionButton()}</div>
    </NiceAntModal>
  );
});

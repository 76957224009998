import { ITreatmentProposalForm } from '../types/ITreatmentProposalForm';
import { emptyTreatmentQuote } from './emptyTreatmentQuote';

export const initialData: Readonly<ITreatmentProposalForm> = {
  dentist: {
    name: '',
    location: '',
    profileUrl: null,
    practiceName: '',
  },
  submissionId: '',
  caseCreatedAt: '',
  patientId: '',
  patientName: '',
  email: '',
  duration: '',
  numberofAligner: 0,
  treatmentGoals: '',
  mobile: {
    ext: '',
    number: '',
  },
  toothMovementURL: '', // https://webview.32-stories.com/?mlink=https://32s-prod-ftp.s3.eu-west-2.amazonaws.com/data/Client4070/10130/8F3E43307CF9488CBBF8B47B06D3A450.iiwgl&fg=ccc&bg=000&p=XIPWPQ
  quotes: [emptyTreatmentQuote],
  addDentistInMail: false,
  assessmentImages: [],
};

import { api, formatUrl, VERSION } from '../api';
import {
  IAddPatientProposalRequest,
  IUpdatePatientProposal,
  INotePatientProposalRequest,
  IPatientProposal,
  TGetPatientProposalPayload,
  ITreatmentOption,
} from './types';
import pickBy from 'lodash/pickBy';

const PATIENT_PROPOSAL_URL = '/patient-proposals';

export const patientProposalApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPatientProposals: build.query<{ total: number; docs: IPatientProposal[] }, TGetPatientProposalPayload>({
      query: (params: any) =>
        formatUrl(
          // pickBy removes keys with empty values from params
          `${PATIENT_PROPOSAL_URL}?${new URLSearchParams(pickBy(params))}`,
        ),
      providesTags: () => [{ type: 'PatientProposals', id: 'LIST' }],
    }),
    getPatientProposalById: build.query<IPatientProposal, { id: string }>({
      query: ({ id }) => formatUrl(`${PATIENT_PROPOSAL_URL}/${id}`),
      providesTags: (result, error, { id }) => [{ type: 'PatientProposals', id }],
    }),
    addPatientProposal: build.mutation<{ id: string }, IAddPatientProposalRequest>({
      query: (body) => ({
        url: formatUrl(PATIENT_PROPOSAL_URL),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { submission }) => [
        { type: 'Submissions', id: 'LIST' },
        { type: 'PatientProposals', id: 'LIST' },
        { type: 'PatientProposals', id: submission },
      ],
    }),
    updatePatientProposal: build.mutation<unknown, { id: string; body: IUpdatePatientProposal }>({
      query: ({ id, body }) => ({
        url: formatUrl(`${PATIENT_PROPOSAL_URL}/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (res, err, { id }) => [
        { type: 'Submissions', id: 'LIST' },
        { type: 'PatientProposals', id: 'LIST' },
        { type: 'PatientProposals', id },
      ],
    }),
    notePatientProposal: build.mutation<unknown, INotePatientProposalRequest>({
      query: ({ id, note }) => ({
        url: formatUrl(`${PATIENT_PROPOSAL_URL}/${id}/notes`),
        method: 'PUT',
        body: note,
      }),
    }),
    getTreatmentOptions: build.query<ITreatmentOption[], void>({
      query: () => ({
        url: formatUrl(`${PATIENT_PROPOSAL_URL}/treatment-options`),
      }),
    }),
    getProposalAsPatient: build.query<IPatientProposal, { proposalId: string; token: string }>({
      query: ({ proposalId, token }) => ({
        url: `/${VERSION}/user${PATIENT_PROPOSAL_URL}/${proposalId}?token=${token}`,
      }),
    }),
    bookProposalAsPatient: build.mutation<void, { proposalId: string; quote: string; message: string }>({
      query: ({ proposalId, quote, message }) => ({
        url: `/${VERSION}/user${PATIENT_PROPOSAL_URL}/${proposalId}/book`,
        method: 'PUT',
        body: { quote, message },
      }),
    }),
  }),
});

export const {
  useGetProposalAsPatientQuery,
  useGetAllPatientProposalsQuery,
  useGetPatientProposalByIdQuery,
  useAddPatientProposalMutation,
  useUpdatePatientProposalMutation,
  useNotePatientProposalMutation,
  useGetTreatmentOptionsQuery,
  useBookProposalAsPatientMutation,
} = patientProposalApi;

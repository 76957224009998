import { ICalendlyScheduleDataType } from 'components/calendly-booking-modal';
import { IClinic } from 'services/patients-api/types';

export enum UserStatuses {
  APPLIED = 'APPLIED',
  INVITED = 'INVITED',
  PENDING_PACKAGE = 'PENDING_PACKAGE',
  LIVE = 'LIVE',
}

export type TUserType = 'dentist' | 'designer' | 'manufacturer' | 'specialist' | 'admin';

// remove TUserTypeUpperCase when BE add user type lowerCase
export type TUserTypeUpperCase = 'DENTIST' | 'DESIGNER' | 'MANUFACTURER' | 'SPECIALIST' | 'ADMIN';
export interface IUserOnboarding {
  calendlyEventLink: string;
  info: boolean; // tell us questions
  isValidDentist: boolean;
  isEmailVerified: boolean;
  isOnboardingBooked: boolean;
  isOnboardingComplete: boolean;
  reminded: boolean;
  onboardingDate: string;
  onBoardingAnswerDetails: { onBoardingAnswer: string }[];
}

export interface IUser {
  firstName: string;
  lastName: string;
  fullName: string;
  dob: string;
  phone: TPhone;
  email: string;
  isEmailVerified: boolean;
  country: string;
  profilePic: string;
  role: TUserTypeUpperCase;
  type: TUserType; // is the lower case of `role`, client only
  deleted: boolean;
  isActive: boolean;
  isOnline: boolean;
  referralCode: string;
  id: string;
  meeting: ICalendlyScheduleDataType;
  status: UserStatuses;
  showOnboardingTutorial?: boolean;

  newContentAssigned: boolean;
  completePercent: number;
  newRelease?: boolean;

  completeProfileDiscountClaimed?: boolean;
  onBoarding: IUserOnboarding;
  clinics?: IClinic[];
  preferences: {
    screenName: string;
    bio: string;
  };
  licenseNumber?: string;
  topics?: string[];

  isUnlockRequested?: boolean;
}

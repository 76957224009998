import { useEffect, useState } from 'react';
import { IPINFO_TOKEN } from '../config/constants';

interface IUseGeolocationIpReturnType {
  country: string;
}

let geoData: IUseGeolocationIpReturnType;
let geoDataLoading = false;

export const useGeolocationIp = (): IUseGeolocationIpReturnType => {
  const [geo, setGeo] = useState(geoData);

  useEffect(() => {
    if (!geo && !geoDataLoading && IPINFO_TOKEN) {
      geoDataLoading = true;
      fetch(`https://ipinfo.io/?token=${IPINFO_TOKEN}`)
        .then((response) => response.json())
        .then((payload) => {
          if (payload) {
            geoData = payload;
            setGeo(payload);
          }
        })
        .finally(() => {
          geoDataLoading = false;
        });
    }
  }, [geo]);

  return geo;
};

import React, { FC, useMemo, useState } from 'react';
import produce from 'immer';
import { Button, Form, Space, Table, Typography } from 'antd';
import { columns } from './columns';
import './treatment-quote-table.styles.less';
import { EditableCell } from './components/editable-cell.component';
import { TTreatmentQuoteTableColumn } from './types/TreatmentQuoteTableColumnType';
import { CloseCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { AddToQuoteModal } from '../add-to-quote-modal/add-to-quote-modal.component';
import { ITreatmentOption, ITreatmentQuoteInfo } from 'services/patient-proposal-api/types';
import { ITreatmentQuoteTableContext, TreatmentQuoteTableContext } from './context';
import { PopoverDelete } from 'components/popovers/popover-delete';

interface IItemProps {
  record: ITreatmentQuoteInfo;
  editingKey: string;
  onCancelItem: () => void;
  onSaveItem: (title: string) => void;
  onRemoveItem: (title: string) => void;
  onEditItem: (record: ITreatmentQuoteInfo) => void;
}
const Item: FC<IItemProps> = ({ record, editingKey, onCancelItem, onSaveItem, onRemoveItem, onEditItem }) => {
  const editable = record.title === editingKey;

  return editable ? (
    <span>
      <Button danger type="text" icon={<CloseCircleOutlined />} onClick={onCancelItem}>
        Cancel
      </Button>
      <Button type="text" icon={<SaveOutlined />} onClick={() => onSaveItem(record.title)}>
        Save
      </Button>
    </span>
  ) : (
    <span>
      <PopoverDelete onDelete={() => onRemoveItem(record.title)}>
        <Button danger type="text" icon={<CloseCircleOutlined />}>
          Delete
        </Button>
      </PopoverDelete>
      <Button type="text" icon={<EditOutlined />} disabled={editingKey !== ''} onClick={() => onEditItem(record)}>
        Edit
      </Button>
    </span>
  );
};

interface ITreatmentQuoteTableComponentProps {
  data: ITreatmentQuoteInfo[];
  setData?: (data: ITreatmentQuoteInfo[]) => void;
  onRemove?: () => void;
  editable?: boolean;
  treatmentOptions?: ITreatmentOption[];
}

const tableComponents = {
  body: {
    cell: EditableCell,
  },
};

export const TreatmentQuoteTableComponent: FC<ITreatmentQuoteTableComponentProps> = ({
  data,
  setData = () => {},
  onRemove = () => {},
  editable,
  treatmentOptions,
}) => {
  const [form] = Form.useForm<ITreatmentQuoteInfo>();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: ITreatmentQuoteInfo) => record.title === editingKey;

  const onAddClick = () => {
    NiceModal.show(AddToQuoteModal, {
      data,
      treatmentOptions,
      onConfirm: (values: ITreatmentQuoteInfo[]) => {
        setData(values);
      },
    });
  };

  const edit = (record: Partial<ITreatmentQuoteInfo> & { title: React.Key }) => {
    form.setFieldsValue({ price: 0, details: '', ...record });
    setEditingKey(record.title);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const remove = (title: string) => {
    setData(data.filter((d) => d.title !== title));
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as ITreatmentQuoteInfo;

      const index = data.findIndex((item) => key === item.title);

      if (index > -1) {
        setData(
          produce(data, (draft) => {
            const item = draft[index];
            // eslint-disable-next-line no-param-reassign
            draft[index] = {
              ...item,
              ...row,
            };
          }),
        );
        setEditingKey('');
      } else {
        setData([...data, row]);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columnsToUse: TTreatmentQuoteTableColumn[] = [
    ...columns,
    ...(editable
      ? [
          {
            title: '',
            dataIndex: '',
            key: '',
            editable: false,
            render: (_: unknown, record: ITreatmentQuoteInfo) => (
              <Item
                record={record}
                editingKey={editingKey}
                onCancelItem={cancel}
                onSaveItem={save}
                onRemoveItem={remove}
                onEditItem={edit}
              />
            ),
          },
        ]
      : []),
  ].map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'price' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    } as TTreatmentQuoteTableColumn;
  });

  const contextValue: ITreatmentQuoteTableContext = useMemo(
    () => ({
      columns: columnsToUse,
      data,
    }),
    [columnsToUse, data],
  );
  return (
    <TreatmentQuoteTableContext.Provider value={contextValue}>
      <div className="treatment-quote-table">
        <Form form={form} component={false}>
          <Table
            pagination={false}
            dataSource={data}
            columns={columnsToUse}
            components={tableComponents}
            // eslint-disable-next-line react/no-unstable-nested-components
            footer={() => (
              <table>
                <tbody>
                  <tr>
                    <td width={editable ? '25%' : '33.3%'}>
                      <Typography.Text>
                        <b>Total </b>
                      </Typography.Text>
                    </td>
                    <td width={editable ? '25%' : '33.3%'}>
                      <Typography.Text>
                        <b>
                          £&nbsp;&nbsp;
                          {data.reduce((sum, curr) => {
                            const price = curr.price ?? 0;
                            if (Number.isNaN(price)) {
                              return sum;
                            }
                            return sum + price;
                          }, 0)}
                        </b>
                      </Typography.Text>
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            )}
          />
        </Form>
        {editable && (
          <Space className="mt-2 flex justify-between pr-4">
            <Button shape="round" onClick={onAddClick}>
              + Add
            </Button>
            <PopoverDelete onDelete={onRemove}>
              <Button danger>Delete quote</Button>
            </PopoverDelete>
          </Space>
        )}
      </div>
    </TreatmentQuoteTableContext.Provider>
  );
};

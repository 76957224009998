import { Image, Select, SelectProps } from 'antd';
import { FC, useEffect } from 'react';
import { useGeolocationIp } from 'hooks/useGeolocationIp';
import { useCountryOptions } from 'hooks/useCountryOptions';

export const CountryInput: FC<SelectProps> = (props) => {
  const { value, onChange } = props;
  const geo = useGeolocationIp();
  const { countries, isLoading, getCountryInfoFromCode } = useCountryOptions();

  useEffect(() => {
    if (geo?.country && !value && getCountryInfoFromCode) {
      const country = getCountryInfoFromCode(geo.country);
      onChange?.(geo.country, {
        label: country?.name,
        value: country?.code,
      });
    }
  }, [geo, getCountryInfoFromCode]);

  return (
    <Select {...props} showSearch optionFilterProp="label" loading={isLoading}>
      {countries?.map((option) => (
        <Select.Option key={option.code} value={option.code} label={option.name}>
          <div>
            <Image src={option.flag} width={30} preview={false} />
            <span className="ml-2">{option.name}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

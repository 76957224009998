import { CloseOutlined } from '@ant-design/icons';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import { Button, Divider, Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import { useCallback } from 'react';
import './nice-ant-modal.styles.less';

// USAGE
// There are two ways to manage visible state
// 1. pass `modal` as prop from useModal value. Refer `DiscountsLearnMoreModal` or `AddToQuoteModal` with custom footer
// 2. use as normal as Modal component (`visible`, `onCancel`... props)

const NiceAntModal: React.FC<ModalProps & { modal?: NiceModalHandler | null }> = (props) => {
  const {
    children,
    afterClose,
    onCancel,
    className,
    modal = null,
    title,
    footer,
    destroyOnClose = true,
    bodyStyle,
    style,
    ...restProps
  } = props;

  const handleCancel = useCallback(
    (e) => {
      onCancel?.(e);
      modal?.hide();
    },
    [onCancel, modal],
  );

  const onAfterClose = useCallback(() => {
    afterClose?.();
    if (destroyOnClose) modal?.remove();
  }, [afterClose, modal, destroyOnClose]);

  return (
    <Modal
      visible={modal?.visible}
      onCancel={handleCancel}
      afterClose={onAfterClose}
      closeIcon={<Button className="bg-white" shape="circle" icon={<CloseOutlined />} size="middle" />}
      centered
      footer={null}
      className={classNames('nice-ant-modal', className)}
      style={{ maxHeight: 'calc(100vh - 30px)', ...style }}
      bodyStyle={{ overflowY: 'auto', ...bodyStyle }}
      {...restProps}
    >
      {title && (
        <>
          <h2 className="title">{title}</h2>
          <Divider className="divider" />
        </>
      )}
      {typeof children === 'function' ? children({ modal }) : children}
      {footer && <div className="footer">{footer}</div>}
    </Modal>
  );
};

export default NiceAntModal;

import { FC } from 'react';
import { Form, Input, Button, InputNumber, Select } from 'antd';
import { AuthButtonComponent } from '../../../components/auth-button/auth-button.component';
import { ClinicItem } from './clinic-item';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import type { IAddress } from 'components/address-field/address-field';

const { Option } = Select;

interface IExperienceProps {
  initialValues?: any;
  submittedValues: Function;
  handleNextButton: Function;
  handleBackButton: Function;
  formSubmitting: boolean;
}

export const Experience: FC<IExperienceProps> = ({
  initialValues,
  submittedValues,
  handleNextButton,
  handleBackButton,
  formSubmitting,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    submittedValues('exp', values);
    handleNextButton(values);
  };

  const onFinishFailed = ({ errorFields }: any) => {
    form.scrollToField(errorFields[0].name, { block: 'center' });
  };

  const storeValues = async () => {
    const values = await form.getFieldsValue();
    submittedValues('exp', values);
    handleBackButton();
  };

  const handleFormSetFieldsValueAddress = (address: IAddress, index: number) => {
    const { clinics: currentClinics } = form.getFieldsValue();
    const newClinics = [...currentClinics];
    newClinics[index] = { ...currentClinics[index], address };
    form.setFieldsValue({ clinics: newClinics });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      layout="vertical"
      size="large"
      autoComplete="off"
      className="mt-4"
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="licenseNumber"
            label="Dental Licence Number*"
            rules={[
              {
                required: true,
                message: 'Please enter your dental licence number',
              },
            ]}
          >
            <Input className="custom-input" placeholder="E.g. General Dental Council Number in UK" />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="practicesYear"
            label="Roughly, how many years have you been practicing*"
            rules={[
              {
                required: true,
                message: `Please tell us how long you’ve been practicing`,
              },
            ]}
          >
            <InputNumber className="custom-input w-full" min={0} placeholder="Enter number of years" />
          </FormItemControl>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <FormItemControl
            form={form}
            name="totalSubmissionsExp"
            label="Roughly, how many clear aligner cases have you completed? *"
            rules={[
              {
                required: true,
                message: 'Please select number of cases completed',
              },
            ]}
          >
            <Select className="custom-input" placeholder="Select an option">
              <Option value="0">None completed</Option>
              <Option value="Fewer Than 10">Fewer Than 10</Option>
              <Option value="More Than 10">More Than 10</Option>
              <Option value="More Than 50">More Than 50</Option>
            </Select>
          </FormItemControl>
        </div>
      </div>
      <div className="w-full py-4">
        <span className="text-lg font-bold text-black">What clinic do you work at?</span>
      </div>
      <Form.List name="clinics">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <ClinicItem
                formListName="clinics"
                form={form}
                key={field.name}
                index={index}
                field={field}
                onRemove={() => remove(field.name)}
                onFormSetFieldsValueAddress={(address) => handleFormSetFieldsValueAddress(address, index)}
              />
            ))}
            <div className="flex mt-3 mb-5">
              <Button
                type="default"
                size="middle"
                shape="round"
                onClick={() => add()}
                style={{
                  height: '36px',
                  border: 'solid 1px #2116b7',
                  color: '#2116b7',
                  backgroundColor: '#F5FBFF',
                }}
                id="add-another-clinic"
              >
                + Add another clinic
              </Button>
            </div>
          </>
        )}
      </Form.List>
      <div className="steps-action">
        <Form.Item>
          <AuthButtonComponent outline onClick={storeValues} className="mr-2 md:mr-4">
            Previous
          </AuthButtonComponent>
          <AuthButtonComponent isLoading={formSubmitting}>Submit</AuthButtonComponent>
        </Form.Item>
      </div>
    </Form>
  );
};

import { Popover, Button, Typography } from 'antd';
import { useState } from 'react';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';

const DeleteIcon = ({
  handleDelete,
  item,
  name,
  red,
  size,
  closeIcon,
}: {
  handleDelete: Function;
  item: any;
  name?: string;
  red?: boolean;
  size?: number;
  closeIcon?: boolean;
}) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleHoverChange = (visible: boolean) => {
    setHovered(visible);
    setClicked(false);
  };

  const handleClickChange = (visible: boolean) => {
    setHovered(false);
    setClicked(visible);
  };

  const hide = () => {
    setHovered(false);
    setClicked(false);
  };

  const confirmDelete = () => {
    hide();
    handleDelete(item, name);
  };

  return (
    <Popover
      content={<div className="text-center">Delete</div>}
      trigger="hover"
      visible={hovered}
      onVisibleChange={handleHoverChange}
    >
      <Popover
        content={
          <div>
            <Typography.Text className="text-sm"> Please confirm you want to delete?</Typography.Text>
            <div className="flex  mt-2">
              <Button onClick={hide}>Cancel</Button>
              <Button className="rounded-standard ml-2" type="primary" danger onClick={confirmDelete}>
                Delete
              </Button>
            </div>
          </div>
        }
        title="Delete"
        trigger="click"
        visible={clicked}
        onVisibleChange={handleClickChange}
      >
        {closeIcon && <CloseCircleOutlined style={{ fontSize: 24 }} />}
        {!closeIcon && (
          <DeleteOutlined
            className="cursor-pointer"
            style={{ color: red ? 'red' : 'lightgrey', ...(size ? { fontSize: size } : {}), marginLeft: 10 }}
          />
        )}
      </Popover>
    </Popover>
  );
};

export default DeleteIcon;

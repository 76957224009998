import { FC } from 'react';
import { Form, Input } from 'antd';
import { IBankAccountDetails } from '../constants';
import { AuthButtonComponent } from '../components/auth-button/auth-button.component';
import { FormItemControl } from 'components/form/form-item-control/form-item-control';
import { ProfilePicUpload } from '../components/profile-pic-upload/profile-pic-upload';

interface ISpecialistAccountDetailsComponentProps {
  initialValues?: IBankAccountDetails;
  submittedValues: Function;
  handleNextButton: Function;
  handleBackButton: Function;
  formSubmitting: boolean;
}

const AccountDetailsForm: FC<ISpecialistAccountDetailsComponentProps> = ({
  initialValues,
  submittedValues,
  handleNextButton,
  handleBackButton,
  formSubmitting,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    submittedValues('bankDetails', values);
    handleNextButton(values);
  };

  const onFinishFailed = ({ errorFields }: any) => {
    form.scrollToField(errorFields[0].name, { block: 'center' });
  };

  const storeValues = async () => {
    const values = await form.getFieldsValue();
    submittedValues('bankDetails', values);
    handleBackButton();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      layout="vertical"
      size="large"
      autoComplete="off"
      className="mt-4 animate-fadeIn"
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['bank', 'name']} label="Bank Name">
            <Input className="custom-input" placeholder="Enter bank name" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['bank', 'country']} label="Bank Country">
            <Input className="custom-input" placeholder="Enter bank country" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['bank', 'accountHolderName']} label="Name of account holder">
            <Input className="custom-input" placeholder="Account holder" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['bank', 'accountNumber']} label="Account number">
            <Input className="custom-input" placeholder="Account number" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['bank', 'sortCode']} label="Sort code (if UK bank)">
            <Input className="custom-input" placeholder="Enter sort code" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormItemControl name={['bank', 'iban']} label="IBAN (if international)">
            <Input className="custom-input" placeholder="Enter IBAN" />
          </FormItemControl>
        </div>
        <div className="col-span-2 md:col-span-1">
          <Form.Item name="profilePic" label="Would you like to upload a profile picture?">
            <ProfilePicUpload />
          </Form.Item>
        </div>
      </div>
      <div className="steps-action">
        <Form.Item>
          <AuthButtonComponent outline onClick={storeValues} className="mr-2 md:mr-4">
            Previous
          </AuthButtonComponent>
          <AuthButtonComponent isLoading={formSubmitting}>Submit</AuthButtonComponent>
        </Form.Item>
      </div>
    </Form>
  );
};

export { AccountDetailsForm };

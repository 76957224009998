import { api } from '../api';
import { IRelease } from './types';

export const releaseNotesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLatestReleaseNotes: build.query<IRelease, void>({
      query: () => '/user/v1/releases/latest',
    }),
  }),
});

export const { useGetLatestReleaseNotesQuery } = releaseNotesApi;

import { Button, ButtonProps } from 'antd';
import React from 'react';
import './nice-button.styles.less';
import cn from 'classnames';

type TNiceType = 'orange' | 'blue' | 'darkBlue' | 'default';
export interface INiceButtonProps extends ButtonProps {
  niceType?: TNiceType;
  hidden?: boolean;
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed' | undefined;
}

export const NiceButton: React.FC<INiceButtonProps> = ({
  children,
  className = '',
  niceType = 'default',
  hidden,
  ...antButtonProps
}) => {
  const { onClick, disabled, size } = antButtonProps;

  if (hidden) return null;
  // eslint-disable-next-line react/destructuring-assignment
  const btnCss = antButtonProps.type === undefined ? `nice-button nice-button-${niceType}` : 'p-0';

  return (
    <Button
      {...antButtonProps}
      className={cn(btnCss, { 'nice-button-small': size === 'small', 'nice-button-disable': disabled }, className)}
      htmlType={!onClick ? 'submit' : undefined}
      shape="round"
    >
      {children}
    </Button>
  );
};

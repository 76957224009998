import { useEffect } from 'react';
import { useLazyGetNotificationsQuery, useReadAllNotificationsMutation } from 'services/notification/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  socketNotisCome,
  reloadAllNotifications,
  setNotisList,
  setUnreadNotificationsCount,
} from 'redux/notificationsSlice';
import { TNotificationItem } from 'services/notification/types';
import { useAuth } from 'hooks/useAuth';
import notificationsService from 'services/socket/notifications';

type TUsePrimaryNotification = {
  unreadNotificationsCount: number;
  markAllNotificationsAsRead: () => Promise<void>;
};

export const usePrimaryNotification = (): TUsePrimaryNotification => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { isConnected } = useAppSelector((state) => state.socket);
  const { notisList } = useAppSelector((state) => state.notifications);
  const { unreadNotificationsCount } = useAppSelector((state) => state.notifications);
  const [readAllNotifications] = useReadAllNotificationsMutation();
  const [getNotifications] = useLazyGetNotificationsQuery();

  useEffect(() => {
    const initUnreadLoad = async () => {
      const unViewedRes = await getNotifications({ limit: 10, isViewed: false }).unwrap();
      dispatch(setNotisList({ type: 'unread', items: unViewedRes.docs }));
      dispatch(setUnreadNotificationsCount(unViewedRes.total));
    };

    if (user && user?.id && notisList.unread === undefined) {
      initUnreadLoad();
    }
  }, [user, notisList]);

  useEffect(() => {
    if (user?.id && isConnected) {
      notificationsService.onNotificationCome((data: TNotificationItem) => {
        dispatch(socketNotisCome(data));
      });

      return () => {
        notificationsService.offNotificationCome();
      };
    }

    return () => {};
  }, [isConnected]);

  const markAllNotificationsAsRead = async () => {
    await readAllNotifications().unwrap();
    dispatch(reloadAllNotifications());
  };

  return {
    unreadNotificationsCount: unreadNotificationsCount || 0,
    markAllNotificationsAsRead,
  };
};

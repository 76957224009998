import { createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Checkbox, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { CheckCircleFilled } from '@ant-design/icons';
import { ITreatmentOption, ITreatmentQuoteInfo } from 'services/patient-proposal-api/types';
import './add-to-quote-modal.styles.less';
import NiceAntModal from 'components/commons/nice-ant-modal/nice-ant-modal.component';

export interface IAddToQuoteModalProps extends Record<string, unknown> {
  data: ITreatmentQuoteInfo[];
  onConfirm: (items: ITreatmentQuoteInfo[]) => void;
  treatmentOptions?: ITreatmentQuoteInfo[];
}

interface IAddToQuoteModalContext {
  checkedItems: ITreatmentQuoteInfo[];
  setCheckedItems: Dispatch<SetStateAction<ITreatmentQuoteInfo[]>>;
}

const Context = createContext<IAddToQuoteModalContext | null>(null);

const TableCheckbox: FC<{ value: ITreatmentQuoteInfo }> = ({ value }) => {
  const { checkedItems, setCheckedItems } = useContext(Context)!;

  const isChecked = !!checkedItems.find((item: any) => item.title === value.title);

  const onChange = () => {
    if (isChecked) {
      setCheckedItems(checkedItems.filter((item: any) => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  return <Checkbox checked={isChecked} onChange={onChange} />;
};

const columns: ColumnType<ITreatmentOption>[] = [
  {
    title: '',
    dataIndex: '',
    key: '',
    render: (_, value) => <TableCheckbox value={value} />,
  },
  {
    title: 'Select treatment option',
    dataIndex: 'title',
    key: 'description',
  },
  {
    title: 'Offered by 32Co?',
    dataIndex: 'offered',
    key: 'offered',
    render: (_, value) => {
      if (value.details === 'offeredBy32Co') {
        return (
          <div className="flex justify-center" style={{ width: 150 }}>
            <CheckCircleFilled style={{ color: '#00C878', fontSize: 20 }} />
          </div>
        );
      }
      return null;
    },
  },
  {
    title: '32Co fee',
    dataIndex: 'price',
    key: 'price',
  },
];

export const AddToQuoteModal = NiceModal.create<IAddToQuoteModalProps>(
  ({ data, treatmentOptions, onConfirm }: IAddToQuoteModalProps) => {
    const [checkedItems, setCheckedItems] = useState<ITreatmentQuoteInfo[]>(
      treatmentOptions?.filter((option) => !!data.find((o) => o.title === option.title)) ?? [],
    );
    const modal = useModal();

    const contextValue = useMemo(() => ({ checkedItems, setCheckedItems }), [checkedItems, setCheckedItems]);

    return (
      <NiceAntModal
        className="custom-modal"
        modal={modal}
        footer={
          <Button
            type="primary"
            size="large"
            className="btn-primary-redesigned"
            id="add-to-quote"
            onClick={() => {
              onConfirm(
                checkedItems.map((item) => {
                  // If item was already selected we will just use it
                  const existedItem = data.find((i) => i.title === item.title);
                  if (existedItem) {
                    return existedItem;
                  }

                  return {
                    ...item,
                    price: 0,
                    details: '',
                  };
                }),
              );
              modal.hide();
            }}
          >
            Add to Quote
          </Button>
        }
      >
        <Context.Provider value={contextValue}>
          <Table dataSource={treatmentOptions} columns={columns} pagination={false} />
        </Context.Provider>
      </NiceAntModal>
    );
  },
);

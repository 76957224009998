import { notification, Upload } from 'antd';
import { ReactComponent as UploadSVG } from 'assets/images/Upload.svg';
import { useGetSignedUrlMutation } from 'services/api';
import { s3Upload } from 'services/s3-api/endpoints';
import { beforeImage5mbUpload } from 'utils';
import { DraggerProps } from 'antd/lib/upload';
import { useGetProfileSignedUrlMutation } from 'modules/auth/services/authApi';
import classNames from 'classnames';

const { Dragger } = Upload;

interface IProps extends Omit<DraggerProps, 'onChange'> {
  name: string;
  value?: any[];
  onChange?: (value: any[]) => void;
  publicUpload?: boolean;
  className?: string;
}

export const ImageDragger = (props: IProps) => {
  const { name, publicUpload, className, ...rest } = props;
  const [getSignedUrl] = useGetSignedUrlMutation();
  const [getPublicSignedUrl] = useGetProfileSignedUrlMutation();

  const handleFileChange = (info: any) => {
    const { status, uid } = info.file;
    const { onChange, value } = props;

    if (status === 'done') {
      const newList =
        value?.map((file: any) => {
          if (file.uid === uid) {
            return { ...info.file, url: info.file.response?.imageUrl || file.url };
          }
          return file;
        }) || [];
      onChange?.(newList);
      notification.success({
        message: `${info.file.name} file uploaded successfully.`,
        placement: 'topRight',
      });
    } else if (status === 'error') {
      const newList = value?.filter((file: any) => file.uid !== uid) || [];
      onChange?.(newList);
      notification.error({
        message: `Sorry ${info.file.name} didn't upload, please try again.`,
        placement: 'topRight',
      });
    }
    return true;
  };

  const uploadImageRequest = async ({ file, onSuccess, filename, onError }: any) => {
    const { name: keyName, type, uid } = file;
    const supportedFileType = type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg';
    if (!supportedFileType) {
      return false;
    }
    if (file) {
      const { value, onChange } = props;
      const filePayload = {
        ...file,
        uid,
        name,
        filename,
        url: null,
        status: 'uploading',
      };
      onChange?.([...(value || []), filePayload]);
      try {
        const fileData = {
          fileName: keyName,
          mimeType: type,
          folderName: filename,
        };
        const signedUrl = await (publicUpload ? getPublicSignedUrl : getSignedUrl)(fileData).unwrap();
        await s3Upload(signedUrl.url, file);
        const imageUrl = signedUrl && signedUrl.url.split('?')[0];
        onSuccess({ imageUrl, filename });
      } catch (e) {
        onError(e, filePayload);
      }
    }
    return true;
  };

  return (
    <div className={classNames('w-full max-w-xs', className)}>
      <Dragger
        {...rest}
        name={name}
        multiple
        showUploadList={false}
        customRequest={uploadImageRequest}
        beforeUpload={beforeImage5mbUpload}
        onChange={handleFileChange}
      >
        <div className="flex justify-center items-center w-full">
          <div className="mr-3 mt-1">
            <UploadSVG />
          </div>
          <div className="flex items-center">
            <p className="upload-text text-left">Click or drag file to this area to upload</p>
          </div>
        </div>
      </Dragger>
    </div>
  );
};

import { Image, Popover, Typography, Col } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';
import DeleteIcon from 'components/DeleteIcon';
import { IImage } from 'services/patients-api/types';

export const PreviewImageGroup = ({
  images,
  deleteImage,
  name,
  perRow = 2,
}: {
  images: IImage[];
  deleteImage: Function;
  name: string;
  perRow?: number;
}) => {
  const [currentPreview, setCurrentPreviewImage]: any = useState(0);
  const [visible, setShowPreviewImage] = useState(false);

  const previewImage = (index: number) => {
    setCurrentPreviewImage(index);
    setShowPreviewImage(true);
  };

  return (
    <>
      {images?.map(({ url, status, uid }, index: number) => (
        <Col className="gutter-row" span={24 / perRow} key={url || uid}>
          {status && status !== 'done' ? (
            <div
              className="min-w-full flex justify-center items-center mb-2"
              style={{ minHeight: 100, backgroundColor: '#f5f5f5' }}
            >
              <Typography.Text> Uploading... </Typography.Text>
            </div>
          ) : (
            <div className="small-image-container mb-2 cursor-pointer w-full" style={{ minHeight: 100 }}>
              <img id={url} src={url} className="h-full" alt="" crossOrigin="anonymous" />
              <div className="overlay h-full w-full">
                <div className="image-backdrop" />
                <div className="image-preview-btn flex flex-row justify-center items-center h-full w-full">
                  <Popover content={<div className="text-center">Preview</div>}>
                    <EyeOutlined style={{ color: 'lightgrey', fontSize: 18 }} onClick={() => previewImage(index)} />
                  </Popover>
                  <DeleteIcon handleDelete={deleteImage} name={name ?? ''} item={url} />
                </div>
              </div>
            </div>
          )}
        </Col>
      ))}
      <div style={{ display: 'none' }}>
        {visible && (
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setShowPreviewImage(vis), current: currentPreview }}
          >
            {images.map(({ url }: any) => (
              <Image key={url} src={url} />
            ))}
          </Image.PreviewGroup>
        )}
      </div>
    </>
  );
};

import { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export const RedirectToSignInWithBackUrl: FC = () => {
  const location = useLocation();

  const redirectUrl = location.pathname + location.search;
  const to = `/signin${redirectUrl && redirectUrl !== '/' ? `?redirectUrl=${redirectUrl}` : ''}`;

  return <Redirect to={to} />;
};
